import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Cliente, FirebaseDataService, ItemOrden, Orden, Pedido, DatosPago, MovementUsers, UserAdmin, Usuario, EnviosParciales } from 'src/services/firebase-data.service';
import { ModalVerOrdenComponent } from '../modal-ver-orden/modal-ver-orden.component';
import { ModalController } from '@ionic/angular';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ConfigService } from 'src/services/config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first, map } from 'rxjs/operators';
import { log } from 'console';
import { resolve } from 'dns';
import firebase, { database } from 'firebase';
import { async } from '@angular/core/testing';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import moment from 'moment';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';
import { ModalAgregarAnotacionesComponent } from '../modal-agregar-anotaciones/modal-agregar-anotaciones.component';
import {ModalAgregarEnviosParcialesComponent} from '../modal-agregar-envios-parciales/modal-agregar-envios-parciales.component';

@Component({
  selector: 'app-cuentas',
  templateUrl: './cuentas.component.html',
  styleUrls: ['./cuentas.component.scss'],
})
export class CuentasComponent implements OnInit {
  clienteSelect:boolean;
  customAlertOptions: any = {
    width:'1000 px',
  }
  public clienteForm: FormGroup;
  public busquedaForm: FormGroup;
  public filtroForm= this.formBuilder.group({
    fechaInicio:['', [Validators.required]],
    fechaFinal: ['', [Validators.required]]
  });
  public tipoForm: FormGroup;

  useClienteNombre:boolean;
  cliente_nombre:string;
  type:string;

  token = "R3$taurAnt3";
  cuentas: any [];
  protocol: any;
  pedidos:any;
  totalCuenta: any;
  comensales: any;
  productos: any [];
  comensalesDivididos:any [];
  pagos: any [];
  clienteSeleccionado: any;
  dirSeleccionado:any;
  tipoSeleccionado: any;
  statusOrden: boolean;
  encontrado: boolean;
  public observableCuentas: Observable<Orden[]>;
  public observablePago: Observable<any[]>;
  today:any;
  hayOrdenes:boolean;
  totalParcial:0;
  subtotalParcial:0;

  clientes: any[];

  customSelectOptions: any = {
    header: 'Clientes del sistema',
    cssClass: 'select-custom-clientes',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Aceptar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };
  customSelectOptionsDir: any = {
    header: 'Selecciona Direccion Del Cliente',
    cssClass: 'select-custom-clientes',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Aceptar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };
  customSelectOptions2: any = {
    header: 'Tipo de venta',
    cssClass: 'select-custom-clientes',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Aceptar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };
  userDatos: any;
  user_erp: any;
  conf_propina: any;
  conf_banco: any;
  productosEnvioParcial: any;
  isParcial: boolean;
  permisoCuentasEnviadaVerOrden: boolean;
  permisoCuentasVerOrden: boolean;

  infoConf;

  constructor(
    private authService: AuthService,
    private router: Router,

    public formBuilder: FormBuilder,
    public config: ConfigService,
    public firebaseService: FirebaseDataService,
    private modalController : ModalController,
    private http: HttpClient
  ) {
    this.protocol = config.protocol;
    this.cuentas = [];
    this.productos = [];
    this.pagos = [];
    this.encontrado=false;
    this.clienteSeleccionado="";
    this.hayOrdenes=true;
    this.userDatos=null;
    this.statusOrden=false;

    /*this.observableCuentas = this.firebaseService.getOrdenesCerradas();


    this.observableCuentas.forEach(element => {
      console.log("Cuentas cerradas: " , element);
    })*/
    /*this.filtroForm = formBuilder.group({
      fechaInicio:['', [Validators.required]],
      fechaFinal: ['', [Validators.required]]
    })*/

    this.clienteForm = formBuilder.group({
      cliente: ['', [Validators.required]],
    });
    this.tipoForm = formBuilder.group({
      tipo: ["", [Validators.required]],
    });
    this.busquedaForm = formBuilder.group({
      nombreCliente: ['', [Validators.required]],
    })

    this.today = moment().format('YYYY-MM-DD');
    //this.observableCuentas = this.firebaseService.GetOrdenesCerradasByDate(this.today + " 00:00:00", this.today + " 23:59:59");

  }
  async CheckIsLoggedIn() {
    this.userDatos = await this.authService.GetCurrentUser();
    this.user_erp= await this.GetIdErp();
    this.config.currentUser=await this.GetIdErp_uid(this.userDatos.uid);
    await this.CheckPermiso();
    await this.GetPropina();
    await this.GetBanco();


    if(!this.userDatos){
      this.router.navigate(['/login']);
    }
  }

  GetIdErp_uid(uid): Promise<Usuario> {
    return new Promise((resolve, reject) => {
      this.firebaseService.GetUser_ByUid(uid).subscribe((user) => {
        if (user) {
          resolve(user[0]);
        }
        else {
          reject(false);
        }
      })
    });
  }

  async ngOnInit() {
    await this.CheckIsLoggedIn();
    this.clienteSelect=true;
    this.filtroForm.controls.fechaInicio.setValue(this.today);
    this.filtroForm.controls.fechaFinal.setValue(this.today);
    this.tipoForm.controls.tipo.setValue(1);
    this.tipoSeleccionado = 1;
    //this.observableCuentas = this.firebaseService.GetOrdenesCerradasByDate("01/01/2022 00:00:00", this.today + " 23:59:59");
    this.observableCuentas = this.firebaseService.GetOrdenesCerradasByDate(this.today + " 00:00:00", this.today + " 23:59:59");
    this.type="cliente";
    this.cliente_nombre="";

    //this.observableCuentas.subscribe(result => { this.cuentas = result});
    //console.log("Ordenes: ", this.observableCuentas)

    await new Promise((resolve, reject) => {
      this.firebaseService.GetConfiguracion().subscribe(data => {
      if (data) {
        this.infoConf = data;
        resolve(this.infoConf)
      }
      });
    })
  }

  DisplayData(date){
    return moment(date).format('YYYY-MM-DD')
  }
  async ClienteChange(e){
    this.type = e.detail.value;
    if(this.type=="cliente"){
      this.clienteSelect = true;
      this.encontrado=false;
      this.clienteSeleccionado="";
      this.useClienteNombre=false;
    }
    if(this.type=="general"){
      this.encontrado=true;
      this.clienteSelect = false;
      this.tipoSeleccionado=1;
      this.useClienteNombre=true;
      if(this.infoConf.length > 0){
        this.clienteSeleccionado = this.infoConf[0].publicoGeneral;
        this.dirSeleccionado = this.clienteSeleccionado.direcciones[0];
      }
      else{
        this.clienteSeleccionado = null;
        this.dirSeleccionado = null;
      }
    }
    if(this.type=="extranjero"){
      this.tipoSeleccionado=1;
      this.clienteSelect = false;
      this.encontrado=true;
      this.useClienteNombre=true;
      if(this.infoConf.length > 0){
        this.clienteSeleccionado= this.infoConf[0].publicoExtranjero;
        this.dirSeleccionado = this.clienteSeleccionado.direcciones[0];
      }
      else{
        this.clienteSeleccionado= this.infoConf[0].publicoExtranjero;
        this.dirSeleccionado = this.clienteSeleccionado.direcciones[0];
      }
    }
  }

  async GetClientes(): Promise<any>{
    try{
      const config: AxiosRequestConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data',
        },
    }
    //http://erp.konsultor.mx/get-clientes?token=${this.token}
    //http://erp.konsultor.mx/get-clientes?token=${this.token}
    const url = this.config.Api('api/get-clientes?token='+this.config.tokenApi+`&busqueda=${this.busquedaForm.controls.nombreCliente.value}`)
    //`http://prueba.konsultor.mx/api/get-clientes?token=123456&busqueda=${this.busquedaForm.controls.nombreCliente.value}`;
    const response = await axios.get(url, config);
    this.clientes = response.data.data;

    return response.data.data;

    }
    catch(err)
    {
      console.log(err);
    }
  }

  async changeNombreCliente(event)
  {
    const value = event.detail.value;
    this.busquedaForm.controls.nombreCliente.setValue(value);

  }
  async changeNombre_cliente(event){
    const value = event.detail.value;
    this.cliente_nombre=value;
  }

  async changeFechaInicio(event)
  {
    const value = event.detail.value;
    this.filtroForm.controls.fechaInicio.setValue(value);

  }

  async changeFechaFinal(event)
  {
    const value = event.detail.value;
    this.filtroForm.controls.fechaFinal.setValue(value);

  }

  async changeCliente(event)
  {
    const value = event.detail.value;
    if(typeof value == 'undefined'){
      this.encontrado=false;
      alert("Favor de seleccionar cliente");
      return;
    }
    this.clienteSeleccionado=event.detail.value;

    this.clienteForm.controls.cliente.setValue(value);
    if(value!=undefined || value!='')
    {
      this.encontrado=true;
    }
    else{
      this.encontrado=false;
    }

  }
  async changeDir(event)
  {
    const value = event.detail.value;
    if(typeof value == 'undefined'){
      this.encontrado=false;
      alert("Favor de seleccionar cliente");
      return;
    }
    this.dirSeleccionado=value;
  }
  async changeTipoVenta(event)
  {
    const value = event.detail.value;
    this.tipoSeleccionado=value;
    console.log(value);
  }

  async filtrar()
  {
    if (this.statusOrden) {
      //TODO: Traer Rechazadas por fecha
      this.observableCuentas = await this.firebaseService.GetOrdenesRechadasByDate(this.filtroForm.controls.fechaInicio.value + " 00:00:00", this.filtroForm.controls.fechaFinal.value + " 23:59:59");

    }else{
      //TODO: Traer Canceladas por fecha
      this.observableCuentas = await this.firebaseService.GetOrdenesCerradasByDate(this.filtroForm.controls.fechaInicio.value + " 00:00:00", this.filtroForm.controls.fechaFinal.value + " 23:59:59");
    }
    this.observableCuentas.forEach(element => {
    })

  }



  async presentModal(orden) {
    // if (!this.config.CheckPermiso(this.config.permisoCuentasVerOrden)) {
    //   return;
    // }
    console.log("DATA",orden);

    const modal = await this.modalController.create({
      component: ModalVerOrdenComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"verOrdenClass",
      componentProps:{orden:orden}
    });
    return await modal.present();
  }

  async presentModalAnotaciones(orden) {
    console.log("DATA",orden);

    const modal = await this.modalController.create({
      component: ModalAgregarAnotacionesComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"verOrdenClass",
      componentProps:{orden:orden}
    });
    modal.onDidDismiss().then((data) => {
      this.statusOrden =false;
      this.filtrar();
      this.modalController.dismiss();
    })
    return await modal.present();
  }
  CheckPermiso(){
    this.permisoCuentasEnviadaVerOrden=this.config.CheckPermiso(this.config.permisoCuentasEnviadaVerOrden);
    this.permisoCuentasVerOrden=this.config.CheckPermiso(this.config.permisoCuentasVerOrden);
  }
  async presentModalEnviosParciales(orden) {
    // if (!this.config.CheckPermiso(this.config.permisoCuentasEnviadaVerOrden)) {
    //   return;
    // }
    if(this.clienteSeleccionado == null || this.clienteSeleccionado.id == null){
      alert("No se detecto el cliente seleccionado, favor de recargar y intentar de nuevo.");
      return;
    }

    console.log("DATA",orden);
    const _orden=orden;
    const _cliente=this.clienteSeleccionado;
    const modal = await this.modalController.create({
      component: ModalAgregarEnviosParcialesComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"verOrdenClass",
      componentProps:{
        orden:orden,
        cliente:this.clienteSeleccionado,
        direccion:this.dirSeleccionado,
        cliente_nombre:this.cliente_nombre
      }
    });
    modal.onDidDismiss().then((data:any) => {
      if (data.data) {
        this.productosEnvioParcial=data.data;
        this.subtotalParcial = data.data.subParcial;
        this.totalParcial = data.data.totalPacial;
        this.isParcial=true;
        console.log('Cierro modalr ',this.productosEnvioParcial.productos);
        this.modalController.dismiss();
        this.printData(_orden,_cliente);
      }
      this.modalController.dismiss();
      // this.statusOrden =false;
      // this.filtrar();
    })
    return await modal.present();
  }

  FormatNumber(number){
    if(typeof number === 'undefined')
    {return '00.00'}
    let parts = number.toString().split(".")

    //console.log(parts);
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return num
  }

  async createVenta(item:any)
  {
    try{
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin':'*',
          'Authorization':'authkey',
          'userid':'1'
        })
      };

      const url = `/posts/save-venta?token=${this.token}`;
      const response = await this.http.post(url,
        {
          "cliente":""+this.clienteForm.controls.cliente.value,
          "tipo":""+this.tipoSeleccionado,
          "subtotal": ""+item.subtotal,
          "total":""+item.totalOrden,
          "sucursal":1,
          "usuario_id":this.clienteForm.controls.cliente.value,
          "detalleVenta":[

          ],
          "ventaFormaPago": [

          ]


        },
        httpOptions
        ).subscribe(data =>  { console.log("HERE",data[0].venta.venta[0].ven_id);
      })
      return response;
    }
    catch(err)
    {
      console.log(err);
    }
  }
  async getItems(ped:any,ordenId:any){
    let items =  await getItemsData(ped,ordenId,this.firebaseService)
    async function getItemsData(ped,ordenId,service) : Promise<any> {
      return new Promise(async(resolve,reject)=>{
        console.log("PED",ped);
        let res=[];
        let pic = ped.map(async (ad,index) =>{
          console.log("AD----",ad,index);
          let info = await getInfo(ad,service);
          async function getInfo(info,service) : Promise<any> {
            return new Promise(async(resolve,reject)=>{
              service.getOrderAllItems(ordenId, info.id).subscribe(e=>{resolve(e)});
            });
          }
          console.log("INFO",info,ped.length );
          if(ped.length-1 == index){
            res.push(info)
            resolve(res)
          }else{res.push(info)}
        })
        //console.log("PIC",pic);
        //resolve(pic)
      });
    }
    items = await getProductosData(items)
    async function getProductosData(items) : Promise<any> {
      let returnArray=[]
      return new Promise(async(resolve,reject)=>{
        items.map((it,index)=>{
          let in1 = index;
          console.log("DTA IT",it);
          it.map((i,index)=>{
            let in2 = index
            if(items.length-1 == in1 && it.length-1 == in2){
              returnArray.push(i);
              resolve(returnArray)
            }else{
              returnArray.push(i)
            }
          })
        })
      });
    }
    const UniqueItems = Array.from(new Set(items.map(a => a.id_producto)))
    .map(id_producto => {
      return items.find(a => a.id_producto === id_producto)
    })
    console.log(UniqueItems);

    return UniqueItems
  }
  showNombre(item){
    if(item.tipo_persona=="Física"){
      return item.nombre+' '+item.apellidos
    }else{
      return item.razon_social
    }
  }
  async printData(item:any, cliente:any)
  {
    console.log("orden",item);

    const item_original = item;
    this.encontrado=false;

      const config: AxiosRequestConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data',
        },
      }
    //http://erp.konsultor.mx/get-clientes?token=${this.token}
    //http://erp.konsultor.mx/get-clientes?token=${this.token}
    // const url = `http://prueba.konsultor.mx/get-bancos?token=123456&busqueda=Hotel TEST`;Grand Prix'
    const url = this.config.Api('get-bancos?token='+this.config.tokenApi+'&busqueda=Grand Prix')
    const response = await axios.get(url, config);
    const contactoId=this.clienteSeleccionado.contactos?.length>0?
                     this.clienteSeleccionado.contactos[0].contacto_id:null;

    if(!this.dirSeleccionado.dir_id){
      alert("Verificar que el cliente tenga sus datos completos para poder facturar.");
      this.encontrado=true;
      return;
    }

    /*if(!this.cliente_nombre && this.type!="cliente"){
      alert("Verificar que el cliente tenga sus datos completos para poder facturar.");
      this.encontrado=true;
      return;
    }*/
    let dir_id = this.dirSeleccionado.dir_id
    let direcion = this.dirSeleccionado.direccion

    //console.log("DATA",item);
    let ordenId = item.id;
    let pagos = await this.firebaseService.getPagos(ordenId)
    let pedidos = await this.firebaseService.GetPedidosAll(ordenId)
    //console.log("PAGOS: " ,pagos);
    //console.log("PEDIDOS",pedidos);
    let ped = await getDataPedido(pedidos)
    let pago = await getDataPagos(pagos);

    async function getDataPagos(pagos:any) : Promise<any> {
      return new Promise(async (resolve, reject) => {
        pagos.subscribe(async (data)=> {
          resolve(data)
        })
      });
    }
    //console.log("Array Pago", pago)
    async function getDataPedido(pedidos:any) : Promise<any> {
      return new Promise(async(resolve,reject)=>{
        pedidos.subscribe(async(data)=>{
            resolve(data)
        })
      });
    }

    //console.log("Array pedido",ped,ped[0].id,ordenId);
    /*let items = await this.getItems(ped,ordenId)
    //console.log("DATA-items",items);


    let total = 0;
    let subtotal = 0;
    let productos = item.comensales.map((com) =>{
      let dps =  com.productos.map((prod) =>{
        let prod_data:any = items.filter(e=>{ return e.id_producto === prod.id_producto});
        total += prod.precio*Number(prod.cantidad);
        subtotal += prod.precio*Number(prod.cantidad);
        let impuesto_precio = 0;
        let imp = prod_data.map(dt=>{
          return dt.impuestos.map(data=>{
            //console.log("impuesto",Number(Number(prod.precio*(data.porcentaje_trasladado/100)).toFixed(2)));
            //impuesto_precio = Number(Number(prod.precio*(data.porcentaje_trasladado/100)).toFixed(4));
            impuesto_precio += data.porcentaje_trasladado;
            //console.log("IMp precio",impuesto_precio);
            //subtotal -= Number(Number(prod.precio*(data.porcentaje_trasladado/100)).toFixed(2));

            return {
              imp_id:data.id,
              pd_porc_imp:data.porcentaje_trasladado,
              pd_precio_imp: ((prod.precio*(data.porcentaje_trasladado/100))),
            }
          })
        })
        let precio_original = prod.precio/(1+(impuesto_precio/100));
        //console.log("DATA DE PORCENTAJE",prod.precio,1+(impuesto_precio/100),precio_original);
        //impuesto_precio = prod.precio/(1+(impuesto_precio/100))
        //console.log("DATA DE PORCENTAJE",impuesto_precio);

        let imp_enviar = prod_data.map(dt=>{
          return dt.impuestos.map(data=>{
            //console.log("impuesto",Number(Number(prod.precio*(data.porcentaje_trasladado/100)).toFixed(2)));
            //impuesto_precio = Number(Number(prod.precio*(data.porcentaje_trasladado/100)).toFixed(4));
            //impuesto_precio += data.porcentaje_trasladado;
            //console.log("IMp precio -- env",impuesto_precio,prod.precio);
            subtotal -= Number(prod.cantidad)*(Number(Number((precio_original)*(data.porcentaje_trasladado/100)).toFixed(4)));

            return {
              imp_id:data.id,
              pd_porc_imp:data.porcentaje_trasladado,
              pd_precio_imp: Number(prod.cantidad)*(((precio_original)*(data.porcentaje_trasladado/100))),
            }
          })
        })
        let unidad_medida = prod_data.map(dt=>{
          return dt.unidad_medida
        });
        //console.log("FACTU",prod.facturable,prod.facturable ? 1:0);
        //console.log("UNIDAD DE MEDIDA",unidad_medida);
        return {
          prod_id:prod.id_producto,
          cantidad:prod.cantidad,
          precio:precio_original,
          direccion:direcion,con_id:contactoId,dir_id:dir_id,
          importe: Number(prod.cantidad) * (precio_original),
          unidad_medida:unidad_medida[0] ,
          impuestos:imp_enviar[0],
          facturable:prod.facturable ? 1:0,
          anotacion:prod.anotacion? prod.anotacion:null
        }
      });
      return dps
    });
    let prodAr = [];
    prodAr[0] = [];
    for (let index = 0; index < productos.length; index++) {
      const element = productos[index];
      prodAr[0] = prodAr[0].concat(element)
    }
    productos = prodAr;

    let unis = await getUnidadProds(productos[0]);
    async function getUnidadProds(productos:any) : Promise<any> {
      return new Promise(async (resolve, reject) => {
        let res=[];
        for (let index = 0; index < productos.length; index++) {
          const element = productos[index];
          if(typeof element.unidad_medida === 'undefined'){
            //aqui consultar la undiad de medida cuando no la tenga agregarla
            //this.config.Api('get-bancos?token='+this.config.tokenApi+'&busqueda=Grand Prix')
            //const url_get_u = `http://prueba.konsultor.mx/api/get-productos?token=123456&sucursal=1&sis_id=2&prod_id=${data.prod_id}`;
            const url_get_u = that.config.Api('api/get-productos?token='+that.config.tokenApi+`&sucursal=1&sis_id=2&prod_id=${element.prod_id}`)
            const response_u = await axios.get(url_get_u, config);
            let cat = Object.entries(response_u.data)
            let prods:any = cat[0][1];
            res.push(prods.productos[0].unidad_medida);
            if(productos.length-1 == index){
              resolve(res);
            }
          }else{
            resolve(null);
          }

        }
      });
    }
    if(unis!=null){
      productos[0].map((data,index)=>{
        data.unidad_medida = unis[index];
      })
    }
    productos = productos[0].filter(data=>{return data.precio>0})
    //agregar Propina id_prod:287 - id_prueba:20
    if(item.propina !=0){
      productos.push({
        prod_id:this.conf_propina?this.conf_propina.id:1,//test:20,prod:287
        cantidad:1,
        precio:item.propina,
        direccion:direcion,con_id:contactoId,dir_id:dir_id,
        importe: item.propina,
        unidad_medida:2,//obtenerlo del objeto de propina despues
        impuestos:[],
        facturable:0,
        anotacion:null
      });
    }*/
    let that = this;
    for(var i=0; i<pago.length; i++)
    {
      this.pagos[i]=pago[i];
    }
    //console.log(this.pagos);

    let pagoshechos = this.pagos.map(pag => {
      //console.log("PAG:",pag)
      return {
        fp_id:pag.forma_pago,
        ban_id:this.conf_banco?this.conf_banco.id:1,
        referencia: pag.descripcion,
        monto:pag.cantidad_pagada
      }
    });

    let mesasOrden = "";
    for (let i = 0; i < item.mesas.length; i++) {
      const mesa = item.mesas[i];
      
      if(mesasOrden == ""){
        mesasOrden += mesa.nombre;
      }
      else{
        mesasOrden += ", " + mesa.nombre;
      }
    }

    let clienteNombreUsar = this.SelectNameClient();
    let envio = {
      cliente:this.clienteSeleccionado.id,
      sucursal:1,
      nombre_cliente:clienteNombreUsar,
      tipo:this.tipoSeleccionado,
      subtotal:this.subtotalParcial,//this.isParcial?this.subtotalParcial:subtotal+item.propina,
      total:this.totalParcial,//this.isParcial?this.totalParcial:total+item.propina,
      usuario_id:this.user_erp.erp_data.id,
      detalleVenta:this.productosEnvioParcial.productos[0],//this.isParcial?this.productosEnvioParcial.productos[0]:productos,
      ventasFormaPago:pagoshechos,
      origen: this.config.origen,
      anotacion_general: this.isParcial?this.productosEnvioParcial.anotacion_general:(item.anotacion_general ? item.anotacion_general:null),
      cuenta: item.folio,
      habitacion: mesasOrden,
      huesped: "",
      referencia: "Restaurante"
    }
    console.log("Envio",envio);
    console.log("item",item);
    // console.log("this.productosEnvioParcial",this.productosEnvioParcial);
    // return;
    try{
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin':'*',
          'Authorization':'authkey',
          'userid':'1'
        })
      };
      const fecha = new Date();
      const day =fecha.getDay();
      const month =fecha.getMonth() + 1;
      const year =fecha.getFullYear();
      const hora =fecha.getHours();
      const minutos=fecha.getMinutes();
      //const url =`/posts/api/save-venta?token=${this.token}`;
      //const url = `http://prueba.konsultor.mx/api/save-venta?token=123456`;
      //const url = `https://erp.konsultor.mx/api/save-venta?token=Gr4ndPr1x$`;
      const url = that.config.Api('api/save-venta?token='+that.config.tokenApi);
      let ven_id=1;


      this.http.post(url,envio,httpOptions).subscribe( async (data) => {
        console.log("data",data);

        item.ven_id = Object.values(data)[2].ven_id;
        ven_id= item.ven_id;
        var timeStamp = moment().format('YYYY-MM-DD HH:mm:ss');
        if (this.isParcial) {
          let envioPar:EnviosParciales;
          envioPar={
            id_orden:item.orden_id?item.orden_id:1+'',
            uid_orden:item.id,
            id_usaurio:this.userDatos.uid,
            id_venta:ven_id+'',
            id_productoAgrupado:this.productosEnvioParcial.id_agrupacion,
            id_productoPropina:this.productosEnvioParcial.id_propina,
            cliente:this.productosEnvioParcial.cliente,
            anotacion_general:envio.anotacion_general,
            anotacion_especifica:this.productosEnvioParcial.productos[0][0].anotacion,
            create_date:timeStamp,
            cantidad:this.productosEnvioParcial.cantidad,
            status:this.config.StatusActiva,
            first: this.productosEnvioParcial.parcial_first,
          } as EnviosParciales;
          await this.firebaseService.AddEnviosParciales(envioPar);
          if(this.productosEnvioParcial.liquidar == true){
            item.status=this.config.StatusEnviadaPar;
            await this.firebaseService.updateOrden(item);
          }
           alert("Guardado exitosamente!");
           this.encontrado=true;
            let ordeData: MovementUsers;
            ordeData = {
              tipo: "envio-cuentas",
              id_objeto: item.id,
              id_usuario: this.userDatos.uid,
              accion: "envio",
              movimiento: "Enviar cuenta al erp",
              original:item_original,
              nuevo: item,
            fecha: timeStamp,

            } as MovementUsers;
            let mvuser = await this.firebaseService.AddMovementUsers(ordeData);
        }else{
          // ?agregar datos de cliente(solo al terminar - si se edita despues de considerar estos campos)
          item.client_id=this.clienteSeleccionado.id;
          item.client_no = this.clienteSeleccionado.no_cliente;
          item.client_razon_social=this.clienteSeleccionado.razon_social;
          item.client_nombre=this.clienteSeleccionado.nombre+' '+this.clienteSeleccionado.apellidos;
          item.client_email=this.clienteSeleccionado.email;
          item.client_rfc=this.clienteSeleccionado.rfc;
          item.client_tipo=this.clienteSeleccionado.tipo_persona;
          item.client_sector=this.clienteSeleccionado.sector;
          item.status=this.config.StatusEnviada;
          await this.firebaseService.updateOrden(item);

          console.log("STATUS DE LA VENTA: ", item.status)
          console.log("ID DE VENTA DEL ITEM:",item.ven_id)
          console.log("HERE", data)
          console.log("ID DE VENTA: ", Object.values(data)[2].ven_id)
          this.encontrado=true;
          let ordeData: MovementUsers;
          ordeData = {
            tipo: "envio-cuentas",
            id_objeto: item.id,
            id_usuario: this.userDatos.uid,
            accion: "envio",
            movimiento: "Enviar cuenta al erp",
            original:item_original,
            nuevo: item,
          fecha: timeStamp,

          } as MovementUsers;
          let mvuser = await this.firebaseService.AddMovementUsers(ordeData);
        }

        return data;
      },error =>{
        console.log(error);
        alert("ERROR durante el proceso de envio favor de verificar los datos de la venta o contactar al equipo de desarrollo.")

      });
    }
    catch(err)
    {
      this.encontrado=true;
      alert("ERROR durante el proceso de envio favor de contactar al equipo de desarrollo.")
      console.log(err);
    }
  }
  SelectNameClient() {
    const guion = this.cliente_nombre == "" ? "" : " - ";
    const prefix = this.type != "cliente" ? this.clienteSeleccionado.tipo_persona == "Física"?
    this.clienteSeleccionado.nombre+" "+this.clienteSeleccionado.apellidos+guion : this.clienteSeleccionado.razon_social+guion: "";
    return this.useClienteNombre == true ? prefix+this.cliente_nombre :
    this.clienteSeleccionado.tipo_persona == "Física"? this.clienteSeleccionado.nombre+' '+this.clienteSeleccionado.apellidos:
    this.clienteSeleccionado.razon_social;
  }
  async ChangeOrderStatus(){
    this.statusOrden = !this.statusOrden;
    console.log('Ahora esta ', this.statusOrden);
    await this.filtrar();
  }
  GetIdErp(): Promise<Usuario> {
    return new Promise((resolve, reject) => {
      this.firebaseService.GetUser_ByUid(this.userDatos.uid).subscribe((user) => {
        if (user) {
          resolve(user[0]);
        }
        else {
          reject(false);
        }
      })
    });
  }
  async GetPropina(){
    await this.firebaseService.GetConfiguracion().subscribe(el=>{
      this.conf_propina=el[0]?.productoPropina;
      console.log('alle propina ', this.conf_propina);

    });
  }
  async GetBanco(){
    await this.firebaseService.GetConfiguracion().subscribe(el=>{
      this.conf_banco=el[0]?.banco;
      console.log('alle banco ', this.conf_banco);

    });
  }



}
