import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditarDatosPagadosComponent } from './editar-datos-pagados.component';


@NgModule({
  declarations: [EditarDatosPagadosComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ]
})
export class EditarDatosPagadosModule { }
