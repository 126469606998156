import { AuthService } from 'src/services/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden, Area } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalController} from '@ionic/angular';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-edit-area',
  templateUrl: './modal-edit-area.component.html',
  styleUrls: ['./modal-edit-area.component.scss'],
})
export class ModalEditAreaComponent implements OnInit {

  @Input("titulo") titulo;
  @Input("data") data;

  prods:any;

  areaForm:FormGroup;

  userUID:string;



  constructor(
    private auth: AuthService,
    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController,
    private builder: FormBuilder
  ) {



  }

  async ngOnInit() {
    console.log("DATA PARAMS",this.data);

    this.areaForm = this.builder.group({
      nombre: [this.data.area.nombre,Validators.required],
      descripcion: [this.data.area.descripcion,Validators.required]
    });

    this.areaForm.setValue({
      nombre:this.data.area.nombre,
      descripcion:this.data.area.descripcion
    });


    console.log("area",this.areaForm);

    var data = await this.auth.GetCurrentUser();

    this.userUID = data.uid;
  }


  closeModal(){
    this.modalControl.dismiss();
  }


  async editArea(){

      var area : Area;

      //var newDate = new Date();
      const newDate = moment().format('YYYY-MM-DD HH:mm:ss')
      area = {
        id : this.data.area.id,
        descripcion: this.areaForm.controls.descripcion.value,
        nombre : this.areaForm.controls.nombre.value,
        edited_by: this.userUID,
        created_date: this.data.area.created_date,
        created_by: this.data.area.created_by,
        edit_date: newDate,
        activa : this.data.area.activa,
      } as Area;

      await this.firebaseService.updateArea(area);
      this.modalControl.dismiss();
  }

   //this.modalControl.dismiss();

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }

}
