import { AuthService } from 'src/services/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, ItemOrden, Mesa, Orden } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalController} from '@ionic/angular';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-modal-edit-mesa',
  templateUrl: './modal-edit-mesa.component.html',
  styleUrls: ['./modal-edit-mesa.component.scss'],
})
export class ModalEditMesaComponent implements OnInit {
  @Input("titulo") titulo;
  @Input("data") data;

  prods:any;

  mesaForm:FormGroup;

  userUID: string;

  constructor(
    private auth:AuthService,
    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController,
    private builder: FormBuilder
  ) {



  }

 async ngOnInit() {
    console.log("DATA PARAMS",this.data);

    this.mesaForm = this.builder.group({
      nombre: [this.data.mesa.nombre,Validators.required],
      cantidad: [this.data.mesa.cantidad_personas,Validators.required],
      estado: [this.data.mesa.estado,Validators.required]
    });

    this.mesaForm.setValue({
      nombre:this.data.mesa.nombre,
      cantidad:this.data.mesa.cantidad_personas,
      estado:this.data.mesa.estado
    });


    console.log("MESA",this.mesaForm);

    var data = await this.auth.GetCurrentUser();

    this.userUID = data.uid;


    console.log("USER ID", this.userUID);
  }


  closeModal(){
    this.modalControl.dismiss();
  }

  changeEstado(event){
    const value = event.detail.value;

    this.mesaForm.patchValue({
      estado:value
    })

    console.log(this.mesaForm.controls.estado.value);
  }

  async editMesa(){

      var mesa : Mesa;
      
      var newDate = moment().format('YYYY-MM-DD HH:mm:ss');
      mesa = {
        id : this.data.mesa.id,
        cantidad_personas: this.mesaForm.controls.cantidad.value,
        estado : this.mesaForm.controls.estado.value,
        nombre : this.mesaForm.controls.nombre.value,
        created_by: this.data.mesa.created_by,
        created_date: this.data.mesa.created_date,
        edit_date: newDate,
        edited_by: this.userUID,
        area: this.data.mesa.area,
        activa: this.data.mesa.activa
      } as Mesa;
      console.log("EDIT MESA",mesa);
      
      await this.firebaseService.updateMesa( mesa,this.data.area);
      this.modalControl.dismiss();
  }

   //this.modalControl.dismiss();

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }

}
