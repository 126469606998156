import { categoriasCarta, Pedido, productosArea } from './../../services/firebase-data.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { AlertsService } from 'src/services/alerts.service';
import { AuthService } from 'src/services/auth.service';
import { FirebaseDataService, ItemOrden, Orden } from 'src/services/firebase-data.service';
import { ModalResumenOrdenComponent } from '../modal-resumen-orden/modal-resumen-orden.component';
import { ModalNotaProductoComponent } from '../modal-nota-producto/modal-nota-producto.component';
import { LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { element } from 'protractor';
import { ModalController } from '@ionic/angular';
import { ConfigService } from 'src/services/config.service';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-orden-agregar-productos',
  templateUrl: './orden-agregar-productos.component.html',
  styleUrls: ['./orden-agregar-productos.component.scss'],
})
export class OrdenAgregarProductosComponent implements OnInit {
  sub:any;
  ordenID: string;
  orden:Orden;
  cats:any;
  impuestosSuma: any;

  impuesto: any;
  selectedCat:any;
  categoriaProds:any;
  selectedProd:any;

  listaProds:any[];
  facturable: boolean;
  userDatos:any;

  notaProducto;

  mesasOrden = "";
  public observableCategorias: Observable<categoriasCarta[]>;
  public observableProductoArea: Observable<productosArea[]>;

  constructor(
    private http:HttpClient,
    private route:ActivatedRoute,

    private authService: AuthService,
    private router: Router,

    public alertService: AlertsService,
    private auth: AuthService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControler:ModalController,
    private config:ConfigService
  ) { }

  async CheckIsLoggedIn() {
    this.userDatos = await this.authService.GetCurrentUser();
    if(this.userDatos==false){
      this.router.navigate(['/login']);
    }
  }

  public searchQueryAdvanced = undefined;
  productosArray = [];
  isOnline: boolean;
  isMessageVisible = false;
 async ngOnInit() {
  this.config.createOnline$().subscribe(async isOnline => {
    this.isOnline = isOnline;
    if (this.isOnline == false) {
      if (this.isMessageVisible == false) {
        this.isMessageVisible = true;
        console.log('Sin internet');
      }
    } else {
      // this.alertService.closeLoadingInternet('I');
    }
  });

  this.CheckIsLoggedIn();
  this.facturable=false;
   this.listaProds = [];
   var categorias = null;
   this.selectedProd = '';
    this.ordenID = null;
    this.sub = this.route.queryParams.subscribe(params =>{
      this.ordenID = params['id_orden'];
      console.log("Orden Seleccionada",this.ordenID);
    });

    this.impuestosSuma=0;

    await this.PresentLoading('Cargando', 0);
    try {
      //link de prueba de grandprix
      //http://prueba.konsultor.mx/get-productos?token=Gr4ndPr1x$&sucursal=1&sis_id=2
      //link de restaurant
      //http://erp.konsultor.mx/get-productos?token=R3$taurAnt3&sucursal=1
      //pruebas
      //http://prueba.konsultor.mx/api/get-productos?token='+this.config.tokenApi+'&sucursal=1&sis_id=2
      let lineas = await this.http.get<any> (this.config.Api('api/get-productos?token='+this.config.tokenApi+'&sucursal=1&sis_id=' + this.config.obtenerDatosSis)).toPromise();
      let productos_area = await this.AwaitProductosArea();

      let categorias_carta = await this.CategoriasCarta();
      categorias_carta.sort(function(a, b){return Number(a.posicion)-Number(b.posicion)})
      for (const d in lineas) {
        const categoria = lineas[d];
        for (let a = 0; a < categoria.productos.length; a++) {
          const producto = categoria.productos[a];
          // let info_prod = await this.AwaitProductoCocina(producto.id);
          // producto["posicion"] = info_prod[0]["posicion"];
          let pos = productos_area.findIndex(t => t.id_producto === producto.id);

          if(pos == -1){
            producto["posicion"] = -1;
          }
          else{
            producto["posicion"] = productos_area[pos]["posicion"];
          }
        }

        let posicion = categorias_carta.findIndex(t => t.linea_id === categoria.id);

        if(posicion == -1){
          let ultima_posicion = categorias_carta.length;
          let nueva_posicion = categorias_carta[categorias_carta.length-1]["posicion"] + 1;
          let nueva_categoria = {
            estatus: "Activo",
            linea_id: categoria.id,
            linea_nombre: categoria.name,
            posicion: nueva_posicion
          }

          this.isOnline = await this.AwaitInternet();
          if (this.isOnline) {
            let guardado = await this.firebaseService.addcategoriasCarta(nueva_categoria);
            console.log("guardado: ", guardado)
          }

          categorias_carta.push(nueva_categoria);
          posicion = ultima_posicion
        }

        categoria["posicion"] = categorias_carta[posicion]["posicion"];
        categoria.productos.sort(function(a, b){return a.posicion-b.posicion});

        let mostrarProd = [];
        for (let p = 0; p < categoria.productos.length; p++) {
          const prod = categoria.productos[p];

          if(prod["posicion"] != -1){
            mostrarProd.push(prod);
          }
        }

        categoria.productos = mostrarProd;
      }
      
      categorias = Object.keys(lineas)
                    .sort()
                    .reduce((acc, key) => ({
                        ...acc, [key]: lineas[key]
                  }), {})
      console.log("Resultado de tiendas",categorias);

      await this.firebaseService.getOrden(this.ordenID).subscribe( async data => {

        console.log("DATA: ",data);
        var timeStamp = moment().format('YYYY-MM-DD HH:mm:ss');

          var tempOrden: any;
          tempOrden = await data.data();
          this.orden = {
            folio: tempOrden.folio,
            orden_id: tempOrden.orden_id,
            id: data.id,
            mesas:tempOrden.mesas,
            comensales: tempOrden.comensales,
            totalOrden: tempOrden.totalOrden,
            status : tempOrden.status,
            anotacion_general: tempOrden?.anotacion_general,
            propina: 0,
            created_by: tempOrden?.created_by,
            created_date: tempOrden?.created_date,
            close_by: tempOrden?.close_by? tempOrden.close_by :''  ,
            close_date:tempOrden?.close_date ? tempOrden.close_date: '',
            edit_by: tempOrden?.edit_by ? tempOrden.edit_by: this.userDatos.uid,
            edit_date: timeStamp
          } as Orden;

        console.log("Orden Actual",this.orden);

        this.cats = Object.entries(categorias);

        var counter = 0;

        this.cats.forEach(element => {
          console.log("CATS",element);
           element["posicion"] = element[1].posicion
           element[3] = element[1].id;
           element[2] = false;
           element[1] = Object.entries(element[1].productos);

           element[1].forEach(item => {
               item[1].impuestos = Object.entries(item[1].impuestos);
               item[1].cantidad = 1;
               item[1].comensal = this.orden.comensales[0].nombre;
               item[1].precio_original = JSON.parse(JSON.stringify(item[1].precio));
               item[1].unidad_medida = item[1].unidad_medida;
               var totalImpuestos = 0;
               item[1].impuestos.forEach(it => {
                 it.value = JSON.parse(JSON.stringify(it[1]));
                 console.log("IMPUESTO",it);
                 it[1].total = it[1].porcentaje_trasladado/100 * item[1].precio_original;
                 totalImpuestos += it[1].total;
               });

               item[1].precio = totalImpuestos + item[1].precio;
           });

           if(counter >= this.cats.length-1){
            console.log('Categorias Ahora',this.cats);

            this.cats.forEach(element => {
              element[1].forEach(item => {
                item[1].impuestos.forEach(ele => {

                });
              });
            });
            this.selectedCat = this.cats[0];

           }

           counter++;


        });

        this.cats.sort(function(a, b){return a.posicion-b.posicion});

        let nuevoCat = [];
        for (let v = 0; v < this.cats.length; v++) {
          const cat = this.cats[v];
          if(cat[1].length != 0){
            nuevoCat.push(cat);
          }
        }

        this.cats = nuevoCat;
        console.log('Categorias Ahora',this.cats);

        for (let i = 0; i < this.orden.mesas.length; i++) {
          const mesa = this.orden.mesas[i];
          
          if(this.mesasOrden == ""){
            this.mesasOrden += mesa.nombre;
          }
          else{
            this.mesasOrden += ", " + mesa.nombre;
          }
        }

      });

      this.DismissLoading();
    } catch (error) {
      this.ngOnInit();
      console.log("XD",error);
    }finally{
      this.DismissLoading();

    }

    let elemnet = document.getElementById("productosBusqueda")
    elemnet.style.display = 'none';

    this.guardarSubscribe = this.alertService.actualizarListaAgregarProductosOrden.subscribe(() => {
      this.ngOnInit();
    });
  }

  guardarSubscribe: Subscription;

  filterAdvanced(){
    // this.searchQueryAdvanced = <HTMLElement>document.getElementById("advancedF").value;
    this.searchQueryAdvanced = (document.getElementById("advancedF") as HTMLInputElement).value;
    // this.searchQueryAdvanced = "coca";
    console.log(this.searchQueryAdvanced);
    let elemnet = document.getElementById("productosBusqueda")

    if(this.searchQueryAdvanced == ''){
      elemnet.style.display = 'none';
    }
    else {
      this.productosArray = [];
      for (let a = 0; a < this.cats.length; a++) {
        const categoria = this.cats[a];

        for (let b = 0; b < categoria[1].length; b++) {
          const dato = categoria[1][b];
          
          if(dato[1].descripcion.toLowerCase().includes(this.searchQueryAdvanced.toLowerCase())){
          // if(dato[1].descripcion.toLowerCase().search(this.searchQueryAdvanced.toLowerCase()) != -1){//probar este
            this.productosArray.push(dato[1]);
          }
        }
      }

      elemnet.style.display = 'block';

      console.log("PRODUCTOS BUSCADOR: ", this.productosArray);
    }
  }

  async AwaitProductosArea(): Promise<productosArea[]> {
    return new Promise((resolve, reject) => {
      this.observableProductoArea= this.firebaseService.getProductosArea();
  
      this.observableProductoArea.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async CategoriasCarta(): Promise<categoriasCarta[]> {
    return new Promise((resolve, reject) => {
      this.observableCategorias = this.firebaseService.GetCategoriasCarta();
  
      this.observableCategorias.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async volver(){
    this.alertService.actualizarListaAgregarProductosOrden.next();
    this.router.navigate(['/restaurant' ]);
  }

  async presentModal() {
    const modal = await this.modalControler.create({
      component: ModalResumenOrdenComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      componentProps:{productos: this.listaProds,orden:this.orden},
      cssClass:"aceptarComandaModal"
    });
    return await modal.present();
  }

  async presentModalNotas(prod) {
    this.notaProducto = "";
    const modal = await this.modalControler.create({
      component: ModalNotaProductoComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      componentProps:{producto: prod, proceso: "Agregar"},
      cssClass:"aceptarComandaModal"
    });
    
    await modal.present();

    return await modal.onDidDismiss().then((data) => {
      this.notaProducto = data;
    })
  }

  toggleCat(categoria){
    var x = document.getElementById(categoria);

    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  ngOnDestroy(){
    this.guardarSubscribe.unsubscribe();
    this.sub.unsubscribe();
  }

  DismissLoading() {
    this.loadingController.dismiss();

  }
  cantidadChange(event,prod){
    var value = event.detail.value;
    prod.cantidad = value;
    console.log(value);
  }

  comensalChange(event,prod){
    var value = event.detail.value;
    prod.comensal= value;
    console.log(value);
  }
  FormatNumber(number){
    let parts = number.toString().split(".")
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return num
  }
  Formato(number){
    var num = number.toFixed();
    return num
  }
  changeCat(event){
    const value = event.detail.value;
    this.selectedCat = value;

    this.cats.forEach(element => {
      if(element[0] == this.selectedCat){
        this.categoriaProds = element[1];
      }
    });

    console.log(value);
  }
  changeProd(event){
    const value = event.detail.value;
    this.selectedProd = value;

    console.log(this.selectedProd);
  }


  imprimirProducto(prod)
  {


    if(prod.impuestos.length >1)
    {
      console.log("SON MAS DE UN IMPUESTO")
      for(var i=0; i<prod.impuestos; i++)
    {
      this.impuesto= (prod.impuestos[i][1].porcentaje_trasladadado)/100;
      this.impuestosSuma = this.impuestosSuma + this.impuesto;
    }
    }
    else
    {
      console.log("SOLO ES UN IMPUESTO")
      this.impuestosSuma = (prod.impuestos[0][1].porcentaje_trasladado)/100;
    }


    var precioImpuestos = parseFloat((prod.precio_original * this.impuestosSuma).toFixed(4));
    var precio= parseFloat((prod.precio_original + precioImpuestos).toFixed(2));



  }

  async agregarProducto(prod){
    await this.presentModalNotas(prod);
    
    if(this.notaProducto.role != "Continuar"){
      return
    }

    if(this.notaProducto.data != ""){
      prod.anotacion = "Anotación: " + this.notaProducto.data;
    }
    else{
      prod.anotacion = this.notaProducto.data;
    }

    console.log("PRODUCTO A AGREGAR",prod);
    this.impuestosSuma = 0;
    if(this.facturable==true)
    {
      this.facturable=false;
    }
    console.log(prod.impuestos.length);
    if(prod.impuestos.length>0)
    {
      this.facturable=true;
    }
    if(prod.impuestos.length >1)
    {
      console.log("SON MAS DE UN IMPUESTO",prod.impuestos)
    for(var i=0; i<prod.impuestos.length; i++)
    {
      console.log("Recorrido",prod.impuestos[i][1]);
      this.impuesto= Number(prod.impuestos[i][1].porcentaje_trasladado);
      console.log("imp",this.impuesto,this.impuestosSuma);
      this.impuestosSuma = this.impuestosSuma + this.impuesto;
      console.log("suma",this.impuestosSuma);

    }
    }
    else
    {
      console.log("SOLO ES UN IMPUESTO")
      this.impuestosSuma = prod.impuestos[0][1].porcentaje_trasladado;
    }
    this.impuestosSuma = this.impuestosSuma/100
    console.log(this.impuestosSuma);

    var precioImpuestos = parseFloat((prod.precio_original * this.impuestosSuma).toFixed(4));
    var precioFinal=Math.round(parseFloat((prod.precio_original + precioImpuestos).toFixed(2)));
    console.log("PRECIO FINAL",precioFinal);


    // if(this.selectedProd == ''){
    //   await this.alertService.presentToast('Seleccione Un Producto', 3000);
    // }else{
    //   var prod :any;
    //   this.categoriaProds.forEach(element => {
    //       if(element[1].nombre == this.selectedProd){
    //         prod = element;
    //       }
    //   });

    //   const control = new FormGroup({
    //     cantidad: new FormControl(1),
    //     id_producto: new FormControl(prod[1].id),
    //     nombre_producto: new FormControl(prod[1].descripcion),
    //     imagen_url: new FormControl(prod[1].url[0]),
    //     precio: new FormControl(prod[1].precio),
    //     comensal: new FormControl('')
    //   });

    //   this.productos.push(control);

    //   console.log(this.ordenProdsForm);
    // }


    if(prod.cantidad <=0 ){
      await this.alertService.presentToast('Agregue Productos', 3000);
      return;
    }if(prod.comensal == ''){
      await this.alertService.presentToast('Seleccione un Comensal', 3000);
      return
    }

    await this.PresentLoading('Agregando Producto',1000);
    var foundFlag = false;

    this.listaProds.forEach(element => {
        if(element.nombre_producto == prod.descripcion && element.comensal == prod.comensal){
          foundFlag = true;

          element.cantidad = parseFloat(element.cantidad);

          element.cantidad = parseFloat(element.cantidad) + parseFloat(prod.cantidad);

          if(prod.anotacion != ""){
            if(element.anotacion != ""){
              element.anotacion += ". " +  prod.anotacion;
            }
            else{
              element.anotacion += prod.anotacion;
            }
          }
        }
    });

    if(!foundFlag){

    var producto: ItemOrden;
    producto = {
      cantidad: prod.cantidad,
      comensal: prod.comensal,
      id_producto: prod.id,
      imagen_url :prod.url[0],
      nombre_producto: prod.descripcion,
      precio: precioFinal,
      estado: "Pedido",
      impuestos: prod.impuestos,
      facturable:this.facturable,
      unidad_medida:prod.unidad_medida,
      anotacion: prod.anotacion
    }as ItemOrden

    console.log("Prod",producto);

    this.listaProds.push(producto);

    console.log(this.listaProds);
    }else{
      console.log(this.listaProds);
    }

}

facturableCambio()
  {
    this.facturable=false;
  }


 async guardarPedido(){


   var cont = 0;
   try {
    await this.PresentLoading("Guardando Pedido",6000);

    var pedido : Pedido;

    pedido = {
      estado: "Pedido",

    } as Pedido

    var pedidoID :any;

    await this.firebaseService.guardarPedido(pedido,this.ordenID).then((docRef)=>{
     pedidoID = docRef.id;
    });
    this.listaProds.forEach(async element => {
        var item: ItemOrden;

        if(element.impuestos.length>0)
        {
          this.facturable= true;
        }
        item ={
          id_producto:element.id_producto,
          cantidad:element.cantidad,
          nombre_producto: element.nombre_producto,
          imagen_url: element.imagen_url,
          precio: element.precio,
          comensal: element.comensal,
          estado: element.estado,
          impuestos:element.impuestos,
          facturable:this.facturable,
          anotacion:element.anotacion
        }as ItemOrden

        await this.firebaseService.addItemOrden(item, this.ordenID,pedidoID);

        if(cont >= this.listaProds.length-1){
          this.router.navigate(['/restaurant' ]);
        }

        cont++;
    });
   } catch (error) {
     console.log(error);
      this.DismissLoading();
   }

  }

  async PresentLoading(mensaje, duracion) {
    const loading = await this.loadingController.create({
      message: mensaje,
      duration: duracion,
      spinner: 'bubbles'
    });
    await loading.present();
    return loading;
  }

  AwaitInternet(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      var interval = setInterval(() => {
        if (this.isOnline) {
          resolve(true);
          clearInterval(interval);
        }
      }, 600);
    });
  }
}
