import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdenAgregarProductosComponent } from './orden-agregar-productos.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalResumenOrdenComponent } from '../modal-resumen-orden/modal-resumen-orden.component';
import { ModalNotaProductoComponent } from '../modal-nota-producto/modal-nota-producto.component';
import { ModalDeleteProductoResumenComponent } from '../modal-delete-producto-resumen/modal-delete-producto-resumen.component';



@NgModule({
  declarations: [OrdenAgregarProductosComponent,ModalResumenOrdenComponent,ModalNotaProductoComponent,ModalDeleteProductoResumenComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class OrdenAgregarProductosModule { }
