import { AuthService } from 'src/services/auth.service';
import { ModalEditMesaComponent } from './../modal-edit-mesa/modal-edit-mesa.component';

import { ModalConfirmDeleteComponent } from './../modal-confirm-delete/modal-confirm-delete.component';
import { Component, OnInit } from '@angular/core';
import { Area, FirebaseDataService, Mesa, MesaOrden, Usuario } from 'src/services/firebase-data.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import moment from 'moment';
import { Router } from '@angular/router';
import { ConfigService } from 'src/services/config.service';
import { AlertsService } from 'src/services/alerts.service';

@Component({
  selector: 'app-mesas',
  templateUrl: './mesas.component.html',
  styleUrls: ['./mesas.component.scss'],
})
export class MesasComponent implements OnInit {

  areas: Area[];
  mesaForm:FormGroup;

  newMesa: Mesa;
  selectedArea: string  ;

  areaNow:string;
  userUID:string;
  mesas: Mesa[];
  getMesas : boolean;
  permisoMesasGuardar: boolean;
  permisoMesasBorrar: boolean;
  permisoMesasEditar: boolean;

  constructor(private firebaseService:FirebaseDataService,

    private authService: AuthService,
    private router: Router,
    public config: ConfigService,
    public alert : AlertsService,
    private builder:FormBuilder,
    private loadingController: LoadingController,
    private modalControler:ModalController,
    private auth:AuthService) { }

    async CheckIsLoggedIn() {
      let userDatos = await this.authService.GetCurrentUser();
      this.config.currentUser=await this.GetIdErp_uid(userDatos.uid);
      await this.CheckPermiso();
      if(userDatos==false){
        this.router.navigate(['/login']);
      }
    }
    GetIdErp_uid(uid): Promise<Usuario> {
      return new Promise((resolve, reject) => {
        this.firebaseService.GetUser_ByUid(uid).subscribe((user) => {
          if (user) {
            resolve(user[0]);
          }
          else {
            reject(false);
          }
        })
      });
    }


  async ngOnInit() {

    this.CheckIsLoggedIn();
    this.getMesas=false;
    this.mesaForm = this.builder.group({
      nombre: ['',Validators.required],
      cantidad: ['',Validators.required],
      id_area: ['',Validators.required]
    });
    this.PresentLoading('Buscando Mesas',2000);
    this.firebaseService.getAreas().subscribe(async (data) => {

      console.log(data);
      this.areas = await data.filter(e=>{let eTemp:any; eTemp= e.payload.doc.data(); return eTemp.activa === null || typeof eTemp.activa === 'undefined' ? true: eTemp.activa }).map(e => {
        var tempArea: any;
        tempArea = e.payload.doc.data();
        return {
          id: e.payload.doc.id,
          nombre:tempArea.nombre,
          descripcion:tempArea.descripcion,
          created_by: tempArea.created_by,
          created_date: tempArea.created_date,
          edit_date: tempArea.edit_date,
          edited_by: tempArea.edited_by,
          activa:tempArea.activa
        } as Area;
      });
      console.log("AREAS",this.areas);

      this.mesaForm.setValue({
        id_area:this.areas[0].id,
        nombre:'',
        cantidad:''
      });

      this.selectedArea = this.areas[0].id;
      try{
        await this.firebaseService.getMesas(this.areas[0].id).subscribe(async(data) => {

          console.log("FILTRO A MESAS-------",data);
          let res =await data.filter(e=>{let eTemp:any; eTemp= e.payload.doc.data(); return eTemp.activa === null || typeof eTemp.activa === 'undefined' ? true: eTemp.activa });
          console.log('res',res);
          this.mesas = res.map(e => {
            var tempArea: any;
            tempArea = e.payload.doc.data();
            console.log(tempArea.nombre);
            return {
              cantidad_personas:tempArea.cantidad_personas,
              id: e.payload.doc.id,
              nombre:tempArea.nombre,
              estado:tempArea.estado,
              id_mesa:e.payload.doc.id,
              created_by: tempArea.created_by,
              created_date: tempArea.created_date,
              edit_date: tempArea.edit_date,
              edited_by: tempArea.edited_by,
              area: tempArea.area,
              activa:true
            } as MesaOrden;
          });
          this.mesas = this.mesas.sort((a, b) => (a.nombre < b.nombre) ? 1 : -1)
          console.log("MESAS RES",this.mesas);

        });
        this.getMesas = true;
      }catch(error){
        console.log("ERROR",error)
      }
      finally{
        this.DismissLoading();
      }
    });

    var data = await this.auth.GetCurrentUser();

    this.userUID = data.uid;



  }
 async editModal(item){
  // if (!this.config.CheckPermiso(this.config.permisoMesasEditar)) {
  //   return;
  // }
   console.log("MESA DATA",item,item.id);
   item.activa=true;
    const modal = await this.modalControler.create({
      component: ModalEditMesaComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"editMesaModal",
      componentProps:{
        titulo: "Editar Mesa",
        tipo: "Mesa",
        data: {mesa:item, area: this.selectedArea},
      }
    });
    return await modal.present();
  }

  DismissLoading() {
    this.loadingController.dismiss();
  }
  async PresentLoading(mensaje, duracion) {
    const loading = await this.loadingController.create({
      message: mensaje,
      duration: duracion,
      spinner: 'bubbles'
    });
    await loading.present();
    return loading;
  }


  async changeArea(event){
    const value = event.detail.value;

    this.mesaForm.controls.id_area.setValue(value);

    this.selectedArea = value;
    await this.PresentLoading('Buscando Mesas',4000);
    try{
      this.firebaseService.getMesas(value).subscribe(data => {

        console.log(data);
        this.mesas = data.filter(e=>{let eTemp:any; eTemp= e.payload.doc.data(); return eTemp.activa === null || typeof eTemp.activa === 'undefined' ? true: eTemp.activa })
        .map(e => {
          var tempArea: any;
          tempArea = e.payload.doc.data();
          return {
            cantidad_personas:tempArea.cantidad_personas,
            id: e.payload.doc.id,
            nombre:tempArea.nombre,
            estado:tempArea.estado,
            id_mesa:e.payload.doc.id,
            area:tempArea.area,
            created_by: tempArea.created_by,
            created_date: tempArea.created_date,
            edit_date: tempArea.edit_date,
            edited_by: tempArea.edited_by,
            activa:tempArea.activa
          } as MesaOrden;
        });
        this.mesas = this.mesas.sort((a, b) => (a.nombre < b.nombre) ? 1 : -1)
        console.log("MESAS",this.mesas);
      });
    }catch(error){
      console.log("ERROR",error)
    }
    finally{
      this.DismissLoading();
    }


    console.log(value);

    console.log(value);
  }

  async deleteModal(item){
    // if (!this.config.CheckPermiso(this.config.permisoMesasBorrar)) {
    //   return;
    // }
    console.log("Borrar",item);
    const modal = await this.modalControler.create({
      component: ModalConfirmDeleteComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"my-custom-modal-css",
      componentProps:{
        mensajeConfirmacion:"¿Desea eliminar la mesa "+ item.nombre + "?",
        titulo: "Eliminar Mesa",
        tipo: "Mesa",
        data: {mesa:item, area: this.selectedArea},
      }
    });
    return await modal.present();
  }
  CheckPermiso(){
    this.permisoMesasGuardar=this.config.CheckPermiso(this.config.permisoMesasGuardar);
    this.permisoMesasEditar=this.config.CheckPermiso(this.config.permisoMesasEditar);
    this.permisoMesasBorrar=this.config.CheckPermiso(this.config.permisoMesasBorrar);

  }
  createMesa(){
    // if (!this.config.CheckPermiso(this.config.permisoMesasGuardar)) {
    //   return;
    // }
    if(this.mesaForm.controls.nombre.value == '' || this.mesaForm.controls.cantidad.value=='')
    {
      this.PresentLoading('Revise los datos de la mesa',2000);
      console.log("No se puede agregar la mesa");
    }
    else{
      //var date = new Date();
    const date = moment().format('YYYY-MM-DD HH:mm:ss');
    this.newMesa = {
      nombre:this.mesaForm.get('nombre').value,
      cantidad_personas: this.mesaForm.get('cantidad').value,
      estado:'Libre',
      created_by : this.userUID,
      created_date: date,
      edit_date: date,
      edited_by: this.userUID,
      area:this.selectedArea,
      activa:true
    }
    console.log("MESA",this.newMesa);
    console.log("MESA FORM",this.mesaForm);
    this.firebaseService.createMesa(this.newMesa,this.mesaForm.get('id_area').value);


    this.PresentLoading('Buscando Mesas',2000);
    this.firebaseService.getAreas().subscribe(data => {

      console.log(data);
      this.areas = data.filter(e=>{let eTemp:any; eTemp= e.payload.doc.data(); return eTemp.activa === null || typeof eTemp.activa === 'undefined' ? true: eTemp.activa }).map(e => {
        var tempArea: any;
        tempArea = e.payload.doc.data();
        return {
          id: e.payload.doc.id,
          nombre:tempArea.nombre,
          descripcion:tempArea.descripcion,
          created_by: tempArea.created_by,
          created_date: tempArea.created_date,
          edit_date: tempArea.edit_date,
          edited_by: tempArea.edited_by,
          activa:tempArea.activa
        } as Area;
      });
      console.log("AREAS",this.areas);

      this.mesaForm.setValue({
        id_area:this.areas[0].id,
        nombre:'',
        cantidad:''
      });

      this.selectedArea = this.areas[0].id;
      try{
        this.firebaseService.getMesas(this.areas[0].id).subscribe(data => {

          console.log(data);
          this.mesas = data.filter(e=>{let eTemp:any; eTemp= e.payload.doc.data(); return eTemp.activa === null || typeof eTemp.activa === 'undefined' ? true: eTemp.activa }).map(e => {
            var tempArea: any;
            tempArea = e.payload.doc.data();
            return {
              cantidad_personas:tempArea.cantidad_personas,
              id: e.payload.doc.id,
              nombre:tempArea.nombre,
              estado:tempArea.estado,
              id_mesa:e.payload.doc.id,
              created_by: tempArea.created_by,
              created_date: tempArea.created_date,
              edit_date: tempArea.edit_date,
              edited_by: tempArea.edited_by,
              area: tempArea.area,
              activa:tempArea.activa

            } as MesaOrden;
          });
          this.mesas = this.mesas.sort((a, b) => (a.nombre < b.nombre) ? 1 : -1)
          console.log("MESAS",this.mesas);
        });
      }catch(error){
        console.log("ERROR",error)
      }
      finally{
        this.DismissLoading();
      }
    });
    }

  }

}
