import { ComensalesOrdenModalComponent } from './../comensales-orden-modal/comensales-orden-modal.component';
import { ModalConfirmDeleteComponent } from './../modal-confirm-delete/modal-confirm-delete.component';
import { ModalCobrarCuentaComponent } from './../modal-cobrar-cuenta/modal-cobrar-cuenta.component';
import { ModalAceptarCuentaComponent } from './../modal-aceptar-cuenta/modal-aceptar-cuenta.component';
import { ModalPropinaComponent } from './../modal-propina/modal-propina.component';
import { ModalVerOrdenComponent } from './../modal-ver-orden/modal-ver-orden.component';
import { ModalConfirmCerrarComponent } from './../modal-confirm-cerrar/modal-confirm-cerrar.component';
import { ModalCambiarMesaComponent } from './../modal-cambiar-mesa/modal-cambiar-mesa.component';
import { Component, ElementRef, HostListener, OnInit, OnDestroy} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/services/auth.service';
import { Area, FirebaseDataService, Mesa, Orden, UserAdmin, MesaOrden, MovementUsers, comandasPreparacion, comandasPreparacionBarra} from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { MenuController, LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';

import { ModalController } from '@ionic/angular';
import { ModalTicketComponent } from '../modal-ticket/modal-ticket.component';
import { analytics } from 'firebase';
import moment from 'moment';
import { ConfigService } from 'src/services/config.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.scss'],
})
export class RestaurantComponent implements OnInit {

  selectOptions = {
    title: 'Pizza Toppings',
    subTitle: 'Select your toppings',
    mode: 'md'
  };
  customSelectOptions: any = {
    header: 'Seleccion de mesas para orden',
    cssClass: 'select-custom-clientes',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Aceptar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };

  customSelectOptionsCambiarArea: any = {
    header: 'Seleccion de areas para orden',
    cssClass: 'cambiarMesasClass',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Aceptar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };

  userKey:any;
  user:any;
  userData:any;
  areas: Area[];

  tipoOrden:boolean;
  ordenes:Orden[];

  ordenCount:number;
  dateTime:any;

  userName:string;
  userNombre;

  mesas: MesaOrden[];

  userUID:string;

  userDatos;

  mesaEnabled:any;

  ordenForm = new FormGroup({
    area : new FormControl(''),
    date : new FormControl(''),
    mesas : new FormControl(''),
    orden: new FormControl(''),
    comensales: new FormArray([
      new FormGroup({
        nombre: new FormControl('Comensal 1')
      })
    ])
  });
  permisoUploadOrden: boolean;
  permisoCerrarOrden: boolean;
  permisoBorrarOrden: boolean;
  permisoModificarOrden: boolean;
  permisoCobrarOrden: boolean;
  permisoAddPropina: boolean;
  permisoVerOrden: boolean;
  permisoCambiarMesas: boolean;
  permisoAddComensal: boolean;

  public observableCocina: Observable<comandasPreparacion[]>;
  public observableBarra: Observable<comandasPreparacionBarra[]>;

  get comensales() {
    return this.ordenForm.get('comensales') as FormArray;
  }


  constructor(
      private elementRef: ElementRef,
      private authService: AuthService,
      private router: Router,
      public config: ConfigService,

      public alertService: AlertsService,
      private auth: AuthService,
      private firebaseService:FirebaseDataService,
      private builder:FormBuilder,
      private loadingController: LoadingController,
      private modalController : ModalController
    ) {}
    async CheckIsLoggedIn() {
        this.userDatos = await this.authService.GetCurrentUser();
        if(!this.userDatos){
        this.router.navigate(['/login']);
      }
    }
  getAreaName(uid){
    const ar = this.areas.find(a => a.id == uid)
    return ar.nombre
  }

  async ngOnInit() {
    this.CheckIsLoggedIn();
    this.mesas = [];
    this.mesaEnabled = true;
    this.tipoOrden = false;
    ///get date var timeStamp = moment().format('YYYY-MM-DD HH:mm:ss');
    console.log("date----------",moment().format('YYYY-MM-DD'));
    this.ordenForm.controls.date.setValue(moment().format('YYYY-MM-DD'));

    await this.PresentLoading('Cargando',2000);
    try {
      // this.user = await this.auth.getUser();

      // this.userKey = this.user.uid;

      // console.log("USUARIO",this.user);
      // console.log("USUARIO",this.userKey);

      // this.userData = await this.WaitForUserData(this.userKey);

      // this.userName = this.userData.nombre;

      // console.log(this.userData);

      this.firebaseService.getAreas().subscribe(data => {

        console.log(data);
        this.areas = data.filter(e=>{let eTemp:any; eTemp= e.payload.doc.data(); return eTemp.activa === null || typeof eTemp.activa === 'undefined' ? true: eTemp.activa }).map(e => {
          var tempArea: any;
          tempArea = e.payload.doc.data();
          return {
            id: e.payload.doc.id,
            nombre:tempArea.nombre,
            descripcion:tempArea.descripcion
          } as Area;
        });
        console.log("AREAS",this.areas);
      });

      await this.getOrdenes();


    } catch (error) {
      console.log(error);
    }finally{
      this.DismissLoading();

    }
    this.userDatos = await this.auth.GetCurrentUser();
    this.userUID = this.userDatos.uid;
    this.userNombre = await this.WaitForUserData(this.userUID);
    this.userName = this.userNombre.name;
    this.CheckPermisos();

    console.log('user: ', this.userNombre);



    console.log("USER", this.userDatos.displayName);



  }
  CheckPermisos() {
    this.permisoUploadOrden=this.config.CheckPermiso(this.config.permisoRestAddOrden);
    this.permisoCerrarOrden=this.config.CheckPermiso(this.config.permisoRestCerrarOrden);
    this.permisoBorrarOrden =this.config.CheckPermiso(this.config.permisoRestBorrarOrden);
    this.permisoModificarOrden =this.config.CheckPermiso(this.config.permisoRestModificarOrden);
    this.permisoCobrarOrden =this.config.CheckPermiso(this.config.permisoRestCobrarOrden);
    this.permisoAddPropina= this.config.CheckPermiso(this.config.permisoRestAddPropina);
    this.permisoVerOrden=this.config.CheckPermiso(this.config.permisoRestVerOrden);
    this.permisoCambiarMesas=this.config.CheckPermiso(this.config.permisoRestCambiarMesas);
    this.permisoAddComensal=this.config.CheckPermiso(this.config.permisoRestAddComensal);
  }
  async ChangeOrderType (){
    this.tipoOrden = !this.tipoOrden
    await this.getOrdenes();
  }

  goToComponent(route:any){
      this.router.navigate(['/'+ route]);  // define your component where you want to go
  }



async cerrarOrden(orden:Orden){
  // if (!this.config.CheckPermiso(this.config.permisoRestCerrarOrden)) {
  //   return;
  // }
  const itemOriginal= orden;
  orden.status =this.config.StatusCerrada;
  console.log("ORDEN CERRADA",orden);
  const modal = await this.modalController.create({
    component: ModalConfirmCerrarComponent,
    showBackdrop: true,
    backdropDismiss:true,
    animated: true,
    cssClass:"my-custom-modal-css",
    componentProps:{
      mensajeConfirmacion:"Desea cerrar la orden ?",
      data: { orden: orden},
      itemOriginal:itemOriginal
    }
  });

  modal.onDidDismiss().then(()=>{
    this.getOrdenes();
  })
  return await modal.present().then((res)=>{
    console.log(res)
  });

}

async AwaitPedidosCocinaEstatus(id_orden: string): Promise<comandasPreparacion[]> {
  return new Promise((resolve, reject) => {
    this.observableCocina = this.firebaseService.getAllPedidosByIdOrdenEstatus(id_orden);

    this.observableCocina.subscribe(data => {
      if (data) {
        resolve(data);
      } else {
        resolve(null);
      }
    })
  })
}

async AwaitPedidosBarraEstatus(id_orden: string): Promise<comandasPreparacionBarra[]> {
  return new Promise((resolve, reject) => {
    this.observableBarra = this.firebaseService.getAllPedidosByIdOrdenEstatusBarra(id_orden);

    this.observableBarra.subscribe(data => {
      if (data) {
        resolve(data);
      } else {
        resolve(null);
      }
    })
  })
}

async borrarOrden(orden:Orden){
  // if (!this.config.CheckPermiso(this.config.permisoRestBorrarOrden)) {
  //   return;
  // }
  let ped = await this.AwaitPedidosCocinaEstatus(orden.id);
  let pedbarra = await this.AwaitPedidosBarraEstatus(orden.id);
  if(ped.length != 0 && !this.permisoModificarOrden){
    await this.alertService.errorLoading('¡No se puede borrar la orden porque hay productos en la cocina o ya se entregaron! Revise y intente de nuevo', 5000);
    return;
  }

  if(pedbarra.length != 0 && !this.permisoModificarOrden){
    await this.alertService.errorLoading('¡No se puede borrar la orden porque hay productos en la barra o ya se entregaron! Revise y intente de nuevo', 5000);
    return;
  }

  const itemOriginal= orden;
  orden.status = "Borrada";
  console.log("ORDEN Borrada",orden);
  const modal = await this.modalController.create({
    component: ModalConfirmCerrarComponent,
    showBackdrop: true,
    backdropDismiss:true,
    animated: true,
    cssClass:"my-custom-modal-css",
    componentProps:{
      mensajeConfirmacion:"Desea borrar la cuenta ?",
      data: { orden: orden },
      itemOriginal:itemOriginal
    }
  });

  modal.onDidDismiss().then(()=>{
    this.getOrdenes();
  })
  return await modal.present().then((res)=>{
    console.log(res)
  });

}



async presentModal(orden) {
  // if (!this.config.CheckPermiso(this.config.permisoRestVerOrden)) {
  //   return;
  // }
  const modal = await this.modalController.create({
    component: ModalVerOrdenComponent,
    showBackdrop: true,
    backdropDismiss:true,
    animated: true,
    cssClass:"verOrdenClass",
    componentProps:{orden:orden}
  });
  return await modal.present();
}

async presentModalMesas(orden) {
  const modal = await this.modalController.create({
    component: ModalCambiarMesaComponent,
    showBackdrop: true,
    backdropDismiss:true,
    animated: true,
    cssClass:"verMesasClass",
    componentProps:{orden:orden}
  });

  modal.onDidDismiss().then(()=>{
    this.getOrdenes();
  })
  return await modal.present().then((res)=>{
    console.log(res)
  });
}

async presentModalComensales(orden) {
  // if (!this.config.CheckPermiso(this.config.permisoRestAddComensal)) {
  //   return;
  // }
  const modal = await this.modalController.create({
    component: ComensalesOrdenModalComponent,
    showBackdrop: true,
    backdropDismiss:true,
    animated: true,
    cssClass:"verOrdenClass",
    componentProps:{orden:orden}
  });
  return await modal.present();
}



async presentModalPropina(orden) {
  // if (!this.config.CheckPermiso(this.config.permisoRestAddPropina)) {
  //   return;
  // }
  const modal = await this.modalController.create({
    component: ModalPropinaComponent,
    showBackdrop: true,
    backdropDismiss:true,
    animated: true,
    cssClass:"propinaModal",
    componentProps:{orden:orden}
  });
  return await modal.present();
}

async presentModalTicket(orden) {
  const modal = await this.modalController.create({
    component: ModalTicketComponent,
    showBackdrop:true,
    backdropDismiss:true,
    animated:true,
    cssClass:"ticketModal",
    componentProps: {orden:orden}
  });
  return await modal.present();
}

async presentModalCobrar(orden) {
  // if (!this.config.CheckPermiso(this.config.permisoRestCobrarOrden)) {
  //   return;
  // }
  const modal = await this.modalController.create({
    component: ModalCobrarCuentaComponent,
    showBackdrop: true,
    backdropDismiss:true,
    animated: true,
    cssClass:"verOrdenClass",
    componentProps:{orden:orden}
  });
  return await modal.present();
}


 async getOrdenes(){
  this.ordenCount = 1;
  var timeStamp = moment().format('YYYY-MM-DD HH:mm:ss');

  if(this.tipoOrden === false){
    this.ordenes = (await (await this.firebaseService.getOrdenes()).docChanges()).map((e)=>{

      this.ordenCount ++;
      var data:any;

      data = e.doc.data();
      return {
        id:e.doc.id,
        folio:data.folio,
        orden_id:data.orden_id,
        comensales:data.comensales,
        mesas: data.mesas,
        status :data.status,
        anotacion_general :data.anotacion_general ? data.anotacion_general:'',
        totalOrden : data.totalOrden,
        propina:data.propina,
        created_by:data?.created_by,
        created_date: data?.created_date,
        edit_by:data?.edit_by ? data.edit_by : this.userDatos.uid,
        edit_date:data?.edit_date ? data.edit_date : timeStamp,
        close_by:data?.close_by ? data.close_by : '',
        close_date:data?.close_date ? data.close_date : '',
      }as Orden
    });
    this.ordenes = this.ordenes.sort((a, b) => (a.created_date < b.created_date) ? 1 : -1)

  }
  if(this.tipoOrden === true){
    this.ordenes = (await (await this.firebaseService.getAllOrdenes()).docChanges()).map((e)=>{

      this.ordenCount ++;
      var data:any;

      data = e.doc.data();
      return {
        id:e.doc.id,
        folio:data.folio,
        orden_id:data.orden_id,
        comensales:data.comensales,
        mesas: data.mesas,
        status :data.status,
        anotacion_general :data?.anotacion_general ? data.anotacion_general:'',
        totalOrden : data.totalOrden,
        propina:data.propina,
        created_by:data?.created_by,
        created_date:data?.created_date,
        edit_by:data?.edit_by ? data.edit_by : this.userDatos.uid,
        edit_date:data?.edit_date ? data.edit_date : timeStamp,
        close_by:data?.close_by ? data.close_by : '',
        close_date:data?.close_date ? data.close_date : '',
      }as Orden
    });
    this.ordenes = this.ordenes.sort((a, b) => (a.created_date < b.created_date) ? 1 : -1)

  }


  console.log("Ordenes",this.ordenes);

    // this.firebaseService.getOrdenes().subscribe(data => {
    //   console.log(data);
    //   this.ordenes = data.map(e => {
    //     var tempArea: any;
    //     tempArea = e.payload.doc.data();
    //     return {
    //       id: e.payload.doc.id,
    //       comensales:tempArea.comensales,
    //       area:tempArea.area,
    //       mesas: tempArea.mesas
    //     } as Orden;
    //   });
    //   console.log("Ordenes",this.ordenes);
    // });
  }

  WaitForUserData(uid): Promise<UserAdmin> {
    return new Promise((resolve, reject) => {
      this.firebaseService.GetUserAdmin_ByUid(uid).subscribe((user) => {
        if (user) {
          resolve(user[0]);
        }
        else {
          reject(false);
        }
      })
    });
  }

  FormatNumber(number){
    let parts = number.toString().split(".")
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return num
  }
  DismissLoading() {
    this.loadingController.dismiss();
  }
  async PresentLoading(mensaje, duracion) {
    const loading = await this.loadingController.create({
      message: mensaje,
      duration: duracion,
      spinner: 'bubbles'
    });
    await loading.present();
    return loading;
  }
  async changeArea(event){
    const value = event.detail.value;

    console.log("VALOR: ",value);

    if (document.getElementById("areaSelectorRestaurant").classList.contains('selectButton') && value.length > 0){
      document.getElementById("areaSelectorRestaurant").classList.add('selectButtonSelected');
      document.getElementById("areaSelectorRestaurant").classList.remove('selectButton');
    }else
    if (document.getElementById("areaSelectorRestaurant").classList.contains('selectButtonSelected') && value.length == 0){
      document.getElementById("areaSelectorRestaurant").classList.add('selectButton');
      document.getElementById("areaSelectorRestaurant").classList.remove('selectButtonSelected');
    }
    this.ordenForm.controls.area.setValue(value);

    await this.PresentLoading('Buscando Mesas',4000);

    this.mesas = [];

    try{

      value.forEach(async element_a => {
        await this.firebaseService.getMesasLibres(element_a).then((e)=>{
          e.forEach(async element => {
          var data = element.data() as Mesa;
          var newMesa: MesaOrden;

            newMesa = {
              id: element.id,
              nombre : data.nombre,
              estado : data.estado,
              id_mesa: element.id,
              cantidad_personas: data.cantidad_personas,
              area: data.area,
              created_date: data.created_date,

            } as MesaOrden
          if(data.activa === null || typeof data.activa === 'undefined' || data.activa === true){
            this.mesas.push(newMesa);
          }
          });
        });
        this.mesas = this.mesas.sort((a, b) => (a.nombre < b.nombre) ? 1 : -1)
        console.log(this.mesas);

        //this.mesas = await this.mesas.filter(e=>{let eTemp:any; eTemp= e; return eTemp.activa === null || typeof eTemp.activa === 'undefined' ? true: eTemp.activa })

        /*this.mesas = await  this.mesas.sort(function(a:any,b:any) {
          return a.created_date - b.created.date
        });*/
      });








    //     var mesas2 : Mesa[];

    //     mesas2 = info.doc.data() as Mesa[];
    //     console.log("MEsas",mesas2);
    //     this.mesas = mesas2.map(e => {
    //       var tempArea: any;
    //       tempArea = e;
    //       return {

    //         cantidad_personas:tempArea.cantidad_personas,
    //         id: e.id,
    //         nombre:tempArea.nombre,
    //         estado:tempArea.estado,
    //         id_mesa:e.id

    //       } as MesaOrden;
    //     });
    //     console.log("MESAS",this.mesas);
    //   });
    }catch(error){
      console.log("ERROR",error)
    }
    finally{
      this.DismissLoading();
    }


    // console.log(value);
  }

  changeMesa(event){
    const value = event.detail.value;

    this.ordenForm.controls.mesas.setValue(value);

    console.log(value);

    if(value == null){
      if (document.getElementById("mesaSelectorRestaurant").classList.contains('selectButtonSelected')){
        document.getElementById("mesaSelectorRestaurant").classList.add('selectButton');
        document.getElementById("mesaSelectorRestaurant").classList.remove('selectButtonSelected');
      }
    }else{
      if (document.getElementById("mesaSelectorRestaurant").classList.contains('selectButton') && value.length > 0){
        document.getElementById("mesaSelectorRestaurant").classList.add('selectButtonSelected');
        document.getElementById("mesaSelectorRestaurant").classList.remove('selectButton');
      }else
      if(document.getElementById("mesaSelectorRestaurant").classList.contains('selectButtonSelected') && value.length ==0){
        document.getElementById("mesaSelectorRestaurant").classList.add('selectButton');
        document.getElementById("mesaSelectorRestaurant").classList.remove('selectButtonSelected');
      }
    }

  }

  changeOrden(event){
    const value = event.detail.value;
    this.ordenForm.controls.orden.setValue(value);

    console.log(value);
  }



  agregarComensal(){

    var size = this.comensales.length+1;

    const control = new FormGroup({
      nombre: new FormControl('Comensal '+size)
    });

    this.comensales.push(control);

    console.log(this.ordenForm);

  }


  ngOnDestroy() {
    this.elementRef.nativeElement.remove();
      console.log('Cleared');
  }
  async eliminarComensal(comensal,index){
    console.log(comensal.value.nombre);
    const modal = await this.modalController.create({
      component: ModalConfirmDeleteComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"my-custom-modal-css",
      componentProps:{
        mensajeConfirmacion:"¿Desea eliminar el comensal "+ comensal.value.nombre +"?",
        titulo: "Eliminar Comensal",
        tipo: "Comensal",
        data: { index:index, comensales:this.comensales },
      }
    });
    return await modal.present();
  }



 async uploadOrden(){
  // if (!this.config.CheckPermiso(this.config.permisoRestAddOrden)) {
  //   return;
  // }

  let info_comensales = this.ordenForm.get('comensales').value;
  for (let i = 0; i < info_comensales.length; i++) {
    const nombre = info_comensales[i]["nombre"];
    if(nombre == ""){
      this.alertService.AlertConfirm("Orden","Favor de revisar los nombres de los comensales");
      return;
    }
  }

   if(this.ordenForm.controls.area.value == '' ||this.ordenForm.controls.mesas.value == '')
   {
     this.alertService.AlertConfirm("Orden","No se puede agregar la orden");
   }
   else{
    var tempMesas = this.ordenForm.get('mesas').value;
  var inputMesas = [];
  tempMesas.forEach(element => {
    var tempVal = element.toString() as string;
    var mesaTemp = tempVal.split(',');

    var mesaData = {
      id: mesaTemp[0],
      area: mesaTemp[1]
    }

   inputMesas.push(mesaData);
  });

  console.log("MESA DATA", inputMesas);



  var selectedMesas : MesaOrden[];

  selectedMesas = [];

   try {
    await this.PresentLoading('Inicializando Pedido',20000);

    console.log('COMENSALES', this.ordenForm.get('comensales').value);

    var selectedArea: Area;
    try {

        var index  = 0;
        inputMesas.forEach(async element => {


        await this.firebaseService.getMesa(element.area,element.id).subscribe(async data => {

              console.log("MESAS",data.data());

                var info : any;
                info = data.data();
                var mesa:MesaOrden;

                mesa = {
                  id:data.id,
                  id_mesa:data.id,
                  nombre: info.nombre,
                  cantidad_personas: info.cantidad_personas,
                  estado: 'Ocupada',
                  created_by:info.created_by,
                  created_date:info.created_date,
                  edit_date:info.edit_date,
                  edited_by:info.edited_by,
                  area:info.area
                } as MesaOrden

                selectedMesas.push(mesa);

                console.log('MESAS Seleccionadas',selectedMesas);

                await this.firebaseService.updateMesa(mesa,mesa.area);
                var timeStamp = moment().format('YYYY-MM-DD HH:mm:ss');

                 if(index >= inputMesas.length-1){
                    console.log("AREASEL", mesa.area);
                    //var nowDate = new Date();
                    const nowDate = moment().format('YYYY-MM-DD HH:mm:ss');
                    const aleatorio =Math.floor(Math.random()*10001);
                    const Folio = moment().format('DDMMYYYY')+aleatorio;
                    console.log("Folio: ",Folio);
                    this.dateTime = moment(this.ordenForm.controls.date.value).format('YYYY-MM-DD HH:mm:ss');
                    let otherDate = moment(this.ordenForm.controls.date.value).format('YYYY-MM-DD') === moment(timeStamp).format('YYYY-MM-DD') ? false:true
                    var ordenData = {
                      folio:Folio,
                      orden_id: this.ordenCount,
                      mesas: selectedMesas,
                      comensales: this.ordenForm.get('comensales').value,
                      status: 'Abierta',
                      anotacion_general: null,
                      totalOrden:0,
                      propina:0,
                      created_by: this.userUID,
                      created_date: otherDate === false ? timeStamp : this.dateTime,
                      edit_date: nowDate
                    } as Orden

                    console.log("DATA",ordenData);

                    var orden = await this.firebaseService.createOrden(ordenData);

                    var idOrden =  (await orden.get()).id;


                    // var ordenDataoriginal = {
                    //   folio:Folio,
                    //   orden_id: this.ordenCount,
                    //   mesas: selectedMesas,
                    //   comensales: this.ordenForm.get('comensales').value,
                    //   status: 'Abierta',
                    //   totalOrden:0,
                    //   propina:0,
                    //   created_by: this.userUID,
                    //   created_date: otherDate === false ? timeStamp : this.dateTime,
                    //   edit_date: nowDate
                    // } as Orden

                    const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

                    let ordeData: MovementUsers;
                    ordeData = {
                      tipo: "ordenes",
                      id_objeto: idOrden,
                      id_usuario: this.userUID,
                      accion: "crear",
                      movimiento: "Crear de orden",
                      original:null,
                      nuevo: ordenData,
                     fecha:timeStamp_cer

                    }
                    await this.firebaseService.AddMovementUsers(ordeData);

                    console.log("ORDEN TERMINADA",idOrden);

                    this.getOrdenes();

                    setTimeout(()=>{
                      this.router.navigate(['/ordenAgregarProductos'],{queryParams:{id_orden:idOrden}});
                      this.DismissLoading();
                      this.elementRef.nativeElement.remove();
                    },500);


                 }

                 index++;
            })
        });



       // window.location.reload();
        this.ordenForm.controls.area.setValue('');
        this.ordenForm.controls.mesas.setValue('');
        this.ordenForm.controls.nombre.setValue('');
        this.ordenForm.controls.date.setValue(moment().format('YYYY-MM-DD'));




    } catch (error) {
      console.log(error);
    }finally{


  }


   } catch (error) {
     console.log(error);
   }finally{



   }
   }


  }

}
