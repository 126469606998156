import { comandasPreparacionBarra, Mesa, MovementUsers } from './../../services/firebase-data.service';
import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden, comandasPreparacion } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';

import { ModalController} from '@ionic/angular';
import moment from 'moment';
import { AuthService } from 'src/services/auth.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-modal-confirm-cerrar',
  templateUrl: './modal-confirm-cerrar.component.html',
  styleUrls: ['./modal-confirm-cerrar.component.scss'],
})
export class ModalConfirmCerrarComponent implements OnInit {

  @Input("mensajeConfirmacion") mensajeConfirmacion;
  @Input("data") data;
  @Input("itemOriginal") itemOriginal;

  prods:any;
  userUID: any;
  public observableCocina: Observable<comandasPreparacion[]>;
  public observableBarra: Observable<comandasPreparacionBarra[]>;

  constructor(
    private router: Router,
    private auth:AuthService,

    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController
  ) { }

  async ngOnInit() {
    console.log("DATA PARAMS",this.data);
    var data = await this.auth.GetCurrentUser();

    this.userUID = data.uid;
  }


  closeModal(){
    this.modalControl.dismiss();
  }

  async AwaitCocinaByOrden(id_orden: string): Promise<comandasPreparacion[]> {
    return new Promise((resolve, reject) => {
      this.observableCocina = this.firebaseService.getAllPedidosCocinaByOrden(id_orden);
  
      this.observableCocina.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async AwaitBarraByOrden(id_orden: string): Promise<comandasPreparacionBarra[]> {
    return new Promise((resolve, reject) => {
      this.observableBarra = this.firebaseService.getAllPedidosBarraByOrden(id_orden);
  
      this.observableBarra.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async cerrarPedido(){
    const ord: Orden = this.data.orden;
    let ped = await this.AwaitCocinaByOrden(ord.id);

    for (let a = 0; a < ped.length; a++) {
      const cocina = ped[a];
      cocina.estado = "Borrado";
      await this.firebaseService.UpdateComandasPreparacion(cocina);
    }

    let barra = await this.AwaitBarraByOrden(ord.id);

    for (let a = 0; a < barra.length; a++) {
      const b = barra[a];
      b.estado = "Borrado";
      await this.firebaseService.UpdateComandasPreparacionBarra(b);
    }

    const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

    ord.edit_by = this.userUID
    ord.edit_date = timeStamp_cer;
    ord.close_by = this.userUID;
    ord.close_date = timeStamp_cer;
    await this.firebaseService.updateOrden(ord);

    const fecha = new Date();
    const hora =fecha.getHours();
    const minutos=fecha.getMinutes();
    let ordeData: MovementUsers;
    ordeData = {
      tipo: "mesas",
      id_objeto: this.itemOriginal.id,
      id_usuario:  this.userUID,
      accion: "actualizar",
      movimiento: "Actualizar orden al "+this.data.orden.status+" pedido",
      original:this.itemOriginal,
      nuevo: this.data.orden,
      fecha: timeStamp_cer

    }
    await this.firebaseService.AddMovementUsers(ordeData);



    var counter = 0;

    this.data.orden.mesas.forEach(async element => {

      const itemOriginal=element;
      var mesa = {
        estado: "Libre",
        id: element.id_mesa,
        nombre: element.nombre,
        cantidad_personas:element.cantidad_personas,
        area:element.area,  
        created_by:element?.created_by,
        created_date: element?.created_date,
        edit_date: timeStamp_cer,
        edited_by: this.userUID
      } as Mesa;

      const fecha = new Date();
      const hora =fecha.getHours();
      const minutos=fecha.getMinutes();
      let ordeData: MovementUsers;
      ordeData = {  
        tipo: "mesas",
        id_objeto: element.id_mesa,
        id_usuario:  this.userUID,
        accion: "actualizar",
        movimiento: "Actualizar mesa al cerrar pedido",
        original:itemOriginal,
        nuevo: mesa,
        fecha: timeStamp_cer

      }
      await this.firebaseService.AddMovementUsers(ordeData);

      console.log("MESA", mesa);

      await this.firebaseService.updateMesa(mesa,mesa.area);

      if(counter >= this.data.orden.mesas.length-1){
        this.modalControl.dismiss();
      }

      counter ++;

    });


  }

   //this.modalControl.dismiss();

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
  }

  DismissLoading() {
    this.loadingController.dismiss();
  }

}
