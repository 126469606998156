import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Cliente, FirebaseDataService, ItemOrden, Orden, Pedido, DatosPago, MovementUsers, UserAdmin, Usuario } from 'src/services/firebase-data.service';
import { ModalVerOrdenComponent } from '../modal-ver-orden/modal-ver-orden.component';
import { ModalController } from '@ionic/angular';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ConfigService } from 'src/services/config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first, map } from 'rxjs/operators';
import { log } from 'console';
import { resolve } from 'dns';
import firebase, { database } from 'firebase';
import { async } from '@angular/core/testing';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import moment from 'moment';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class LogsComponent implements OnInit {

  public observableMovimientos: Observable<MovementUsers[]>;
  listMovements:any[];


  statusUsers: Boolean;
   

  public clienteForm: FormGroup;
  public busquedaForm: FormGroup;
  public filtroForm= this.formBuilder.group({
  fechaInicio:['', [Validators.required]],
  fechaFinal: ['', [Validators.required]]
})
public tipoForm: FormGroup;
  today: string;


  constructor(
    public formBuilder: FormBuilder,
    public config: ConfigService,
   
    public firebaseService: FirebaseDataService,

  ) {
   
    // this.observableMovimientos = this.firebaseService.GetMovementsUsers();
    this.statusUsers=false;
    this.today = moment().format('YYYY-MM-DD');

   }

  async ngOnInit() {
    // setTimeout(() => {
    //   this.observableMovimientos.subscribe(async element => {
    //     this.listMovements= element;
    //   });
    // },2000)
   
    this.filtroForm.controls.fechaInicio.setValue(this.today);
    this.filtroForm.controls.fechaFinal.setValue(this.today);

    this.filtrar();
    this.listMovements= await this.WaitForData(this.observableMovimientos);
    this.GetUsuario();
    console.log("listMovements: " , this.listMovements);
  }

  async GetUsuario() {

    this.listMovements.forEach(async movement => {
      const observable = this.firebaseService.GetUser_ByUid(movement.id_usuario);
      const u = await this.WaitForData(observable);
      console.log('encontre use',u);
      
      movement.usuario=u[0]?.name;
    });
    // const us=await this.SearchUser(id);  
    // console.log('use',us);
    //  return us.name;
   
  }



  async filtrar()
  {
    console.log("FECHA INICIO", this.filtroForm.controls.fechaInicio.value, "FECHA FINAL", this.filtroForm.controls.fechaFinal.value)
    // if (this.statusUsers) {
    //   //TODO: Traer Rechazadas por fecha
    //  //this.observableMovimientos = await this.firebaseService.GetMovementsUsersByDate(this.filtroForm.controls.fechaInicio.value + " 00:00:00", this.filtroForm.controls.fechaFinal.value + " 23:59:59");

    // }else{
    //   //TODO: Traer Canceladas por fecha
    // //  this.observableMovimientos = await this.firebaseService.GetMovementsUsersByDate(this.filtroForm.controls.fechaInicio.value + " 00:00:00", this.filtroForm.controls.fechaFinal.value + " 23:59:59");
    // }
    this.observableMovimientos = await this.firebaseService.GetMovementsUsersByDate(this.filtroForm.controls.fechaInicio.value + " 00:00:00", this.filtroForm.controls.fechaFinal.value + " 23:59:59");

    // this.observableMovimientos.forEach(element => {
    //   console.log("Cuentas cerradas: " , element);
    // })
    this.listMovements= await this.WaitForData(this.observableMovimientos);
    this.GetUsuario();
    console.log("Cuentas cerradas: " , this.listMovements);


  }
  async changeFechaInicio(event)
  {
    const value = event.detail.value;
    console.log(event.detail.value)
    this.filtroForm.controls.fechaInicio.setValue(value);

  }

  async changeFechaFinal(event)
  {
    const value = event.detail.value;
    this.filtroForm.controls.fechaFinal.setValue(value);

  }






  WaitForData(observable: Observable<any []>): Promise<any> {
    return new Promise((resolve, reject) => {
      observable.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          console.log('no data');
          resolve(null);
        }
      });
    });
  }
}


