import { element } from 'protractor';

import { firebase } from '@firebase/app';

import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask, createUploadTask } from '@angular/fire/storage';

import { Observable } from 'rxjs';
import { map, take, observeOn, switchMap, single, filter } from 'rxjs/operators';

import * as moment from 'moment';
import { Permisos } from 'src/shared/permissions.interface';
import { ParseOptions } from 'querystring';
import { stringify } from '@angular/compiler/src/util';
import { ConfigService } from './config.service';
import { AreasComponent } from 'src/app/areas/areas.component';
import { faChartArea } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from './auth.service';

export interface UserAdmin {
  uid?: string; // Note this is the user uid is the auth uid and in doc uid NOT the document id
  name: string;
  email: string;
  erp_data:any;
  active: boolean;
}
export interface Usuario {
  uid?:string,
  id?:string;
  name:string;
  email:string;
  tipoUsuario:string;
  erp_data:any;
  active: boolean;
  permiso?:any;
}
export interface PermisosTipoUsuario {
  id?:string;
  admin:any;
  mesero:any;
  cajero:any;
}

export interface Ticket {
  id?: string;
  id_usuario:string;
  status_orden:string;
  id_orden:string;
  fecha:string;
  hora:string;

}

export interface EnviosParciales{
  id?: string;
  id_orden:string;
  uid_orden:string;
  id_usaurio:string;
  id_venta:string;
  id_productoAgrupado:string;
  id_productoPropina?:string;
  cliente: any[];
  anotacion_general?:string;
  cantidad:number;
  anotacion_especifica?:string;
  create_date: string;
  status:string;
  first:boolean;
}

export interface Orden {
  folio:string;
  id?: string;
  orden_id: number;
  comensales: any[];
  mesas: MesaOrden[];
  status:string;
  anotacion_general:string;
  totalOrden : number;
  propina: number;
  ven_id?: number;
  client_id?:number;
  client_no?:string;
  client_razon_social?:string;
  client_nombre?:string;
  client_email?:string;
  client_rfc?:string;
  client_tipo?:string;
  client_sector?:string;
  created_by: string;
  created_date:string;
  close_by?:string;
  close_date?:string;
  edit_by?:string;
  edit_date?:string;
}

export interface DatosPago{
  id?: string;
  nombre_pago?:string,
  forma_pago: number;
  cantidad_pagada: number;
  comensal: string;
  cambio:number;
  descripcion: string;
  created_by: string;
  created_date:string;
  edit_by?:string;
  edit_date?:string;
}

export interface MesaOrden{
  id?:string;
  id_mesa:string;
  nombre:string;
  cantidad_personas:number;
  estado:string;
  area: string;
  created_by: string;
  created_date:string;
  edit_date:string;
  edited_by: string;
}

export interface Pedido {
  id?: string;
  estado:string;
  total_pedido: number;
  comensal_total: any[];
  created_by: string;
  created_date:string;
  edit_by?:string;
  edit_date:string;
}
export interface ItemOrden{
  id?: string;
  id_producto: number;
  cantidad:number;
  nombre_producto:string;
  imagen_url:string;
  precio: number;
  comensal: string;
  estado: string;
  impuestos: any;
  facturable: boolean;
  unidad_medida?:number;
  anotacion: string;
}

export interface comandasPreparacion{
  id?: string;
  id_producto: number;
  cantidad: number;
  nombre_producto: string;
  comensal: string;
  precio: number;
  estado: string;
  anotacion: string;
  fecha_inicio_preparacion: string;
  fecha_final_preparacion: string;
  fecha_agregado: string;
  folio_orden: string;
  orden_id: string;
  pedido_id: string;
  created_by: string;
  edited_by: string;
  mesero_name: string;
  mesas: string;
}

export interface comandasPreparacionBarra{
  id?: string;
  id_producto: number;
  cantidad: number;
  nombre_producto: string;
  comensal: string;
  precio: number;
  estado: string;
  anotacion: string;
  fecha_inicio_preparacion: string;
  fecha_final_preparacion: string;
  fecha_agregado: string;
  folio_orden: string;
  orden_id: string;
  pedido_id: string;
  created_by: string;
  edited_by: string;
  mesero_name: string;
  mesas: string;
}

export interface categoriasCarta{
  id?: string;
  linea_id: number;
  linea_nombre: number;
  posicion: number;
}

export interface productosArea{
  id?: string;
  id_producto: number;
  nombre_producto: string;
  estatus: string;
  fecha_agregado: string;
  fecha_editado: string;
  fecha_baja: string;
  created_by: string;
  edited_by: string;
  deleted_by: string;
  area: string;
  area_id: string;
}

export interface Area{
  id?: string;
  nombre:string;
  descripcion:string;
  created_by: string;
  created_date:string;
  edited_by:string;
  edit_date:string;
  activa:boolean;
}

export interface Mesa{
  id?:string;
  nombre:string;
  area: string;
  cantidad_personas:number;
  estado:string;
  created_by: string;
  created_date:string;
  edit_date:string;
  edited_by:string;
  activa?:boolean;
}






export interface DatosFormulario {
  id?: string;
  area: string;
  name: string;
  idComplejo: string;
  idCuarto: string;
  questions: any[];
}

export interface TipoServicio {
  id?: string;
  name: string;
}
export interface TipoSubServicio {
  id?: string;
  name: string;
  idTipoServicio: string;
}


export interface RespuestaFormDetallado {
  id?: string;
  idFormulario: string;
  idUsuario: string;
  idComplejo: string;
  nombre: string; // con apellido
  numHabitacion: number;
  comentarios: string;
  answers: any[];
}


export interface DatosTarjeta {
  id?: string;
  nombreTarjeta: string;
  numeroTarjeta: string;
  tarjetaMes: string;
  tarjetaAnio: string;
  tarjetaCodigo: string;
  idReservacion: string;
  idUsuario: string;
}


export interface Configuracion {
  bancoSeleccionado: any;
  id?: string;
  banco:any[];
  publicoGeneral:any[];
  publicoExtranjero:any[];
  productoPropina:any[];
  productoAgrupado:any[];

}



export enum estatusReservacion {
  Activo = 'Activo',
  // Inactivo = 'Inactivo',
  Completado = 'Completado',
  Cancelado = 'Cancelado',
  CheckIn = 'Check-In',
  Checked_Out = 'Checked_Out',
}

// ? NEW
export interface Reservacion {
  id?: string;
  idUsuario: string;
  idsComplejos: string[];
  ERPTipoPago: any;
  fechaInicio: string;
  fechaFinal: string;
  dias: number;
  adultos: number;
  ninos: number;
  total: number;
  totalConImpuestos: number;
  datosCuartos: any[];

  estatusSistema: estatusSistema;
  idCreatedBy: string; // Id de usuario
  idUpdatedBy: string; // Id de usuario
  createdAt: string; // tiempo
  updatedAt: string; // tiempo

  // total: number;
  puntosRecompensa: number;
  nombresAdultos: any[];
  selectedTipo: any[]; // TIPO POLITICAS
  policies: any[];
  nombresMenores: any[];
  nombreContacto: string;
  alertas: boolean;
  celular: string;
  codigoPais: string;
  solicitudEspecial: string;
  numeroFolio: string;
  estatus: estatusReservacion;
  correo: string;
  check_in: boolean;
  segmento: string;
  personasExtra: any[];
  // ? 1.01
  localizador: string;
  estatusAnterior: estatusReservacion;
  id_erp: number;
  rfc: string;
  turnoId: string;
  // & agregar nuevos campos del google docs
  // codigoPromocional: string;
}

export interface Pago {
  id?: string;
  nombre: string;
  idsComplejos: string[];
  typeRoom: string;
  param100: number;
  fechaInicio: string;
  fechaFinal: string;
  dias: number[];
  independientes: boolean;
}

export interface PrecioCuartoFecha {
  id?: string;
  fechaInicio: string;
  fechaFinal: string;
  costoBase: number;
  tipoCuartoCalcular: string;

}

export interface FechasPagoGeneral {
  id?: string;
  lunesgral: number;
  martesgral: number;
  miercolesgral: number;
  juevesgral: number;
  viernesgral: number;
  sabadogral: number;
  domingogral: number;
}


// * OLD
// export interface Reservacion {
//   id?: string;
//   idNumeroCuarto: string; // ids []
//   numeroCuarto: string; // numeros[]
//   idCuarto: string; // id cuartos[]
//   idUsuario: string;
//   idComplejo: string;
//   fechaInicio: string;
//   fechaFinal: string;
//   dias: number;
//   cuartos: number;
//   /** el ID tipo cuarto */
//   tipoCuarto: string; // id tipo cuartos []
//   adultos: number;
//   ninos: number;
//   costoFinal: number;

//   estatusSistema: estatusSistema;
//   idCreatedBy: string; // Id de usuario
//   idUpdatedBy: string; // Id de usuario
//   createdAt: string; // tiempo
//   updatedAt: string; // tiempo

//   // Extra de cambios
//   totalConImpuestos: number;
//   puntosRecompensa: number;
//   nombresAdultos: any[];
//   nombresMenores: any[];
//   nombreContacto: string;
//   alertas: boolean;
//   celular: string;
//   codigoPais: string;
//   solicitudEspecial: string;
//   numeroFolio: string;
//   estatus: estatusReservacion;

//   // ? 1.1
//   correo: string;
//   check_in: boolean;

//   //? 1.2
//   tarifaPorNoche: number;
//   segmento: string;
//   // codigoPromocional: string;
//   // idCreatedBy: string;
// }


export interface Cuarto {
  id?: string;
  complexId: string;
  typeRoomId: string;
  servicesIds: string[];
  name: string;
  description: string;
  // roomNumber: number;
  bedQuantity: number;
  bedTypes: any[];
  roomQuantity: number;
  cost: number;
  costPerPerson: number;
  personCapacity: number;
  urls: string[]; // photos urls
}

export interface Sys_log {
  //ID(autoincrementable), nombre o ID del reporte, Fecha y hora de la consulta y quien lo consultó
  id?: string;
  nombre: string;
  fecha: string;
  movimiento: string;
  datos_nuevos: any[];
  datos_viejos: any[];
  usu_id: string;
}

export interface BloqueoHabitacionesPeriodos {
  //ID(autoincrementable), nombre o ID del reporte, Fecha y hora de la consulta y quien lo consultó
  id?: string;
  descripcion: string;
  idCreatedBy: string;
  idUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
  estatus: estatusSistema;
  fechaInicio: string;
  fechaFinal: string;
  dropAt: string,
  idDropBy: string
}

export interface BloqueoHabitacionesPeriodosDetalle {
  //ID(autoincrementable), nombre o ID del reporte, Fecha y hora de la consulta y quien lo consultó
  id?: string;
  idConfiguracion: string;
  idCreatedBy: string;
  idUpdatedBy: string;
  createdAt: string;
  updatedAt: string;
  estatus: estatusSistema;
  numero_cuarto: string;
  numero_cuarto_id: string;
  tipo_cuarto_id: string;
  cuarto_id: string;
  complejo_id: string;
  dropAt: string,
  idDropBy: string,
}

export interface Complejo {
  id?: string;
  name: string;
  description: string;
  servicesIds: string[];
  longitude: string;
  latitude: string;
  url: string; // photos urls
}


export interface TipoCuarto {
  id?: string;
  nombre: string;
  descripcion: string;
  complejoId: string;
}

export interface Formulariobm {
  id?: string;
  name: string;
  description: string;
}
export interface RespuestaForm {
  id?: string;
  id_cliente: string;
  id_form: string;
  aprovacion: number;
  fecha: string;
}

export interface Servicio {
  id?: string;
  nombre: string;
  descripcion: string;
  iconoEscrito: boolean; // Si es verdadero utiliza icono de los iconos de ionic, si no, url de imagen para icono
  url: string;
  icono: string;
}

export interface Cliente {
  id?: string;
  nombre: string;
  descripcion: string;
  correo: string;
  rfc: string;
  tipo_cliente: string;
  cp: string;
  colonia: string;
  calle_num: string;
  curp: string;

}


export interface CheckIn {
  id?: string;
  llegada: Date;
  grupo: string;
  idUsuario: string;
  idReservacion: string;
  folio: string;
  salida: Date;
  clave: string;
  segmento: string;
  nombresAdultos: any[];
  // numerosCuartos: any[];
  // huespedAdulto2: string;
  menores: string;
  reqFactura: boolean;
  razonSocial: string;
  rfc: string;
  calle: string;
  nunExt: string;
  numInt: string;
  colonia: string;
  ciudad: string;
  alcaldia: string;
  estado: string;
  pais: string;
  codigoPostal: string;
  correoElectronico: string;
  telefono: string;
  procedencia: string;
  nacionalidad: string;
  ocupacion: string;
  serviciosIncluidos: string;
  numNoches: number;
  habitacion: string;
  tarifa: string;
  numAdultos: number;
  numMenores: number;
  formaPago: string;
  cambioHabitacion1: string;
  cambioHabitacion2: string;
  recepcionista: string;
  folioAeropuerto: string;
  vendedorAereopuerto: string;
  urlIne1: string;
  urlIne2: string;

  // ? Datos para auditoria y ID's:
  idERP?: string;
  estatus: estatusSistema;
  idCreatedBy: string; // Id de usuario
  idUpdatedBy: string; // Id de usuario
  createdAt: string; // tiempo
  updatedAt: string; // tiempo
  turnoId: string;
}

export interface NumeroCuarto {
  id?: string;
  numeros: string[];
  edificios: string[];
  pisos: string[];
  activo: boolean[];
  estatus: string[];
  // metaData: [
  //   numero: string,
  //   edificio: string,
  //   piso: string,
  //   disponible: boolean
  // ];
  idCuarto: string;
}

export enum estatusTransporte {
  EnProgreso = 'EnProgreso',
  Completado = 'Completado',
  Cancelado = 'Cancelado'
}

export interface SolicitudTransporte {
  id?: string;
  idConductores: string[];
  idReservacion: string;
  estatus: estatusTransporte;
  direccion: string;
  fechaHora: string;
  nombrePersona: string;
  numeroPersonas: string;
  solicitadoPor: string;
  fechaSolicitud: string;
}

export interface Conductor {
  id?: string;
  idComplejo: string;
  nombre: string;
  matricula: string;
  disponible: boolean;
  vehiculo: string;
}

export enum estatusSistema {
  Activo = 'Activo',
  Inactivo = 'Inactivo',
}

export interface UserAdmin_back {
  id?: string;
  uid?: string;
  idERP?: number;
  idSucursalERP: number;
  idSectorERP: number;

  estatus: estatusSistema;
  idCreatedBy: string; // Id de usuario
  idUpdatedBy: string; // Id de usuario
  createdAt: string; // tiempo
  updatedAt: string; // tiempo

  permisos: Permisos;
  usuario: string;
  // password: string;
  nombre: string;
  puesto: string;
  telefono: string;
  passwordReseted: boolean;
  // password: string;
  // role: string;
}

export interface AdminPassword {
  id?: string;
  uid: string;
  userId: string;
  password: string; // encrypted
  updatedAt: string; // tiempo
}

// ? Datos para auditoria y ID's:
// id?: string;
// idERP?: string;
// estatus: estatusSistema;
// idCreatedBy: string; // Id de usuario
// idUpdatedBy: string; // Id de usuario
// createdAt: string; // tiempo
// updatedAt: string; // tiempo

export interface MovementUsers {
  id?: string;
  tipo: string;
  id_usuario: string;
  id_objeto: string;
  accion: string;
  movimiento: string;
  original: any;
  nuevo: any;
  fecha: string;
}

export interface CheckOut {
  id?: string;
  idERP?: string;
  idReservacion: string;
  estatus: estatusSistema;
  idCreatedBy: string; // Id de usuario
  idUpdatedBy: string; // Id de usuario
  createdAt: string; // tiempo
  updatedAt: string; // tiempo
  createdAbonoAt: string; // tiempo

  extras: any[];
  // tipoPago: string;
  /**Total con impuesto + extras */
  total: number;

  registros: any[];
  turnoId: string;
  // [
  //   {
  //     cantidadPagada: number,
  //     fecha: string,
  //     realizadoPor: string // id usuario administrador
  //   }
  // ]
}

export interface Politica {
  id?: string;
  nombre: string;
  descripcion: string;
  politica: string;
  idTipoPolitica: string;
  order: number;

  estatus: estatusSistema;
  idCreatedBy: string; // Id de usuario
  idUpdatedBy: string; // Id de usuario
  createdAt: string; // tiempo
  updatedAt: string; // tiempo
}

export interface TipoPolitica {
  id?: string;
  nombre: string;
  descripcion: string;

  estatus: estatusSistema;
  idCreatedBy: string; // Id de usuario
  idUpdatedBy: string; // Id de usuario
  createdAt: string; // tiempo
  updatedAt: string; // tiempo
}


export interface Turno {
  id?: string;
  nombre: string;
  inicio: string;
  salida: string;
  madrugada: boolean;
  createdBy: string;
  createdAt: string;
  estatus: estatusSistema;
}

export interface UserPassword {
  id?: string;
  uid: string;
  password: string; // encrypted
  updatedAt: string; // tiempo
}

@Injectable({
  providedIn: 'root'
})

export class FirebaseDataService {

  private datosTarjetaCollection: AngularFirestoreCollection<DatosTarjeta>;
  private datosTarjeta: Observable<DatosTarjeta[]>;

  private ticketCollection: AngularFirestoreCollection<Ticket>;
  private ticket: Observable<Ticket[]>;

  private permisosTipoUsuarioCollection: AngularFirestoreCollection<PermisosTipoUsuario>;
  private permisosTipoUsuario: Observable<PermisosTipoUsuario[]>;

  private enviosParcialesCollection: AngularFirestoreCollection<EnviosParciales>;
  private enviosParciales: Observable<EnviosParciales[]>;

  private ordenCollection: AngularFirestoreCollection<Orden>;
  private orden: Observable<Orden[]>;

  private complejo: Observable<Complejo[]>;
  private complejoCollection: AngularFirestoreCollection<Complejo>;

  private formulariobm: Observable<Formulariobm[]>;
  private formulariobmCollection: AngularFirestoreCollection<Formulariobm>;
  private respuestaForm: Observable<RespuestaForm[]>;
  private respuestaFormCollection: AngularFirestoreCollection<RespuestaForm>;

  private servicio: Observable<Servicio[]>;
  private servicioCollection: AngularFirestoreCollection<Servicio>;
  private checkIn: Observable<CheckIn[]>;
  private checkInCollection: AngularFirestoreCollection<CheckIn>;

  private cliente: Observable<Cliente[]>;
  private clienteCollection: AngularFirestoreCollection<Cliente>;

  private configuracion: Observable<Configuracion[]>;
  private configuracionCollection: AngularFirestoreCollection<Configuracion>;

  private cuarto: Observable<Cuarto[]>;
  private cuartoCollection: AngularFirestoreCollection<Cuarto>;

  private sys_log: Observable<Sys_log[]>;
  private sys_logCollection: AngularFirestoreCollection<Sys_log>;

  private bloqueoHabitacionesPeriodos: Observable<BloqueoHabitacionesPeriodos[]>;
  private bloqueoHabitacionesPeriodosCollection: AngularFirestoreCollection<BloqueoHabitacionesPeriodos>;

  private bloqueoHabitacionesPeriodosDetalle: Observable<BloqueoHabitacionesPeriodosDetalle[]>;
  private bloqueoHabitacionesPeriodosDetalleCollection: AngularFirestoreCollection<BloqueoHabitacionesPeriodosDetalle>;

  private tipoCuarto: Observable<TipoCuarto[]>;
  private tipoCuartoCollection: AngularFirestoreCollection<TipoCuarto>;

  private reservacion: Observable<Reservacion[]>;
  private reservacionCollection: AngularFirestoreCollection<Reservacion>;
  
  private comandaspreparacion: Observable<comandasPreparacion[]>;
  private comandasPreparacionCollection: AngularFirestoreCollection<comandasPreparacion>;

  private comandaspreparacionbarra: Observable<comandasPreparacionBarra[]>;
  private comandasPreparacionBarraCollection: AngularFirestoreCollection<comandasPreparacionBarra>;

  private categoriascarta: Observable<categoriasCarta[]>;
  private categoriasCartaCollection: AngularFirestoreCollection<categoriasCarta>;
  
  private productosarea: Observable<productosArea[]>;
  private productosAreaCollection: AngularFirestoreCollection<productosArea>;

  private pago: Observable<Pago[]>;
  private pagoCollection: AngularFirestoreCollection<Pago>;

  private fechaspagogeneral: Observable<FechasPagoGeneral[]>;
  private fechaspagogeneralCollection: AngularFirestoreCollection<FechasPagoGeneral>;

  private preciocuartofecha: Observable<PrecioCuartoFecha[]>;
  private preciocuartofechaCollection: AngularFirestoreCollection<PrecioCuartoFecha>;

  private formulario: Observable<DatosFormulario[]>;
  private formularioCollection: AngularFirestoreCollection<DatosFormulario>;


  private respuestaFormD: Observable<RespuestaFormDetallado[]>;
  private respuestaFormDCollection: AngularFirestoreCollection<RespuestaFormDetallado>;

  private numeroCuarto: Observable<NumeroCuarto[]>;
  private numeroCuartoCollection: AngularFirestoreCollection<NumeroCuarto>;

  private solicitudTransporte: Observable<SolicitudTransporte[]>;
  private solicitudTransporteCollection: AngularFirestoreCollection<SolicitudTransporte>;

  private conductor: Observable<Conductor[]>;
  private conductorCollection: AngularFirestoreCollection<Conductor>;

  private tipoServicio: Observable<TipoServicio[]>;
  private tipoServicioCollection: AngularFirestoreCollection<TipoServicio>;

  private tipoSubServicio: Observable<TipoSubServicio[]>;
  private tipoSubServicioCollection: AngularFirestoreCollection<TipoSubServicio>;

  private userAdmin: Observable<UserAdmin[]>;
  private userAdminCollection: AngularFirestoreCollection<UserAdmin>;

  private checkOut: Observable<CheckOut[]>;
  private checkOutCollection: AngularFirestoreCollection<CheckOut>;

  private movementUsers: Observable<MovementUsers[]>;
  private movementUsersCollection: AngularFirestoreCollection<MovementUsers>;

  private politicas: Observable<Politica[]>;
  private politicasCollection: AngularFirestoreCollection<Politica>;

  private tipoPoliticas: Observable<TipoPolitica[]>;
  private tipoPoliticasCollection: AngularFirestoreCollection<TipoPolitica>;

  private adminPasswordCollection: AngularFirestoreCollection<AdminPassword>;


  private turnos: Observable<Turno[]>;
  private turnosCollection: AngularFirestoreCollection<Turno>;

  private usuario: Observable<Usuario[]>;
  private usuarioCollection: AngularFirestoreCollection<Usuario>;

  private ordenes: Observable<Orden[]>;
  private ordenesCollection: AngularFirestoreCollection<Orden>;

  private userPasswordCollection: AngularFirestoreCollection<UserPassword>;

  constructor(private afs: AngularFirestore, private storage: AngularFireStorage, private config: ConfigService, private auth: AuthService) {

    this.userPasswordCollection = this.afs.collection<AdminPassword>('password_user');

    this.turnosCollection = this.afs.collection<Turno>('turno', ref => ref.where('estatus', '==', estatusSistema.Activo));
    this.turnos = this.turnosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.usuarioCollection = this.afs.collection<Usuario>('usuario');
    this.usuario = this.usuarioCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const uid = a.payload.doc.id;
          return { uid, ...data};
        });

      })
    );

    this.ticketCollection = this.afs.collection<Ticket>('ticket');
    this.ticket = this.ticketCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data};
        });

      })
    );

    this.permisosTipoUsuarioCollection = this.afs.collection<PermisosTipoUsuario>('permisos_tipo_usuario');
    this.permisosTipoUsuario = this.permisosTipoUsuarioCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data};
        });

      })
    );
    this.enviosParcialesCollection = this.afs.collection<EnviosParciales>('envios_parciales');
    this.enviosParciales = this.enviosParcialesCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data};
        });

      })
    );

    this.ordenCollection = this.afs.collection<Orden>('ordenes');
    this.orden = this.ordenCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data};
        });

      })
    );

    this.adminPasswordCollection = this.afs.collection<AdminPassword>('password_admin');

    this.tipoPoliticasCollection = this.afs.collection<TipoPolitica>('tipo_politicas', ref => ref.where('estatus', '==', estatusSistema.Activo));
    this.tipoPoliticas = this.tipoPoliticasCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.politicasCollection = this.afs.collection<Politica>('politicas', ref => ref.where('estatus', '==', estatusSistema.Activo).orderBy('order', 'asc'));
    this.politicas = this.politicasCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.checkOutCollection = this.afs.collection<CheckOut>('check_out', ref => ref.where('estatus', '==', estatusSistema.Activo));
    this.checkOut = this.checkOutCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.movementUsersCollection = this.afs.collection<MovementUsers>('movement_users');
    this.movementUsers = this.movementUsersCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.userAdminCollection = this.afs.collection<UserAdmin>('user_admin', ref => ref.where('active', '==', true));
    this.userAdmin = this.userAdminCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.tipoServicioCollection = this.afs.collection<TipoServicio>('TiposServicios');
    this.tipoServicio = this.tipoServicioCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    this.tipoSubServicioCollection = this.afs.collection<TipoSubServicio>('TiposSubServicios');
    this.tipoSubServicio = this.tipoSubServicioCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.conductorCollection = this.afs.collection<Conductor>('conductor');
    this.conductor = this.conductorCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.solicitudTransporteCollection = this.afs.collection<SolicitudTransporte>('solicitud_transporte', ref => ref.orderBy('fechaSolicitud', 'desc').limit(40));
    this.solicitudTransporte = this.solicitudTransporteCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.numeroCuartoCollection = this.afs.collection<NumeroCuarto>('numero_cuarto');
    this.numeroCuarto = this.numeroCuartoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.datosTarjetaCollection = this.afs.collection<DatosTarjeta>('datos_tarjeta');
    this.datosTarjeta = this.datosTarjetaCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.formularioCollection = this.afs.collection<DatosFormulario>('formulario_dinamico');
    this.formulario = this.formularioCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.respuestaFormDCollection = this.afs.collection<RespuestaFormDetallado>('respuesta_form_detallado');
    this.respuestaFormD = this.respuestaFormDCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.checkInCollection = this.afs.collection<CheckIn>('check_in');
    this.checkIn = this.checkInCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    this.configuracionCollection = this.afs.collection<Configuracion>('configuracion');
    this.configuracion = this.configuracionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    this.formulariobmCollection = this.afs.collection<Formulariobm>('formularios_bm');
    this.formulariobm = this.formulariobmCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    this.configuracionCollection = this.afs.collection<Configuracion>('configuracion');
    this.configuracion = this.configuracionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.respuestaFormCollection = this.afs.collection<RespuestaForm>('respuestaFormulario');
    this.respuestaForm = this.respuestaFormCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    // this.reservacionCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('fechaInicio', 'desc').limit(100));
    this.reservacionCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('createdAt', 'desc').limit(5000));
    this.reservacion = this.reservacionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
  
    this.comandasPreparacionCollection = this.afs.collection<comandasPreparacion>('comandas_preparacion');
    this.comandaspreparacion = this.comandasPreparacionCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.comandasPreparacionBarraCollection = this.afs.collection<comandasPreparacionBarra>('comandas_preparacion_barra');
    this.comandaspreparacionbarra = this.comandasPreparacionBarraCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    
    this.categoriasCartaCollection = this.afs.collection<categoriasCarta>('categorias_carta');
    this.categoriascarta = this.categoriasCartaCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    
    this.productosAreaCollection = this.afs.collection<productosArea>('productos_area');
    this.productosarea = this.productosAreaCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.pagoCollection = this.afs.collection<Pago>('pago');
    this.pago = this.pagoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );

    this.fechaspagogeneralCollection = this.afs.collection<FechasPagoGeneral>('fechaspagogeneral');
    this.fechaspagogeneral = this.fechaspagogeneralCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    )

    this.preciocuartofechaCollection = this.afs.collection<PrecioCuartoFecha>('preciocuartofecha');
    this.preciocuartofecha = this.preciocuartofechaCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    )


    this.cuartoCollection = this.afs.collection<Cuarto>('cuarto');
    this.cuarto = this.cuartoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.sys_logCollection = this.afs.collection<Sys_log>('sys_log');
    this.sys_log = this.sys_logCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );


    this.bloqueoHabitacionesPeriodosCollection = this.afs.collection<BloqueoHabitacionesPeriodos>('bloqueo_habitaciones_periodos');
    this.bloqueoHabitacionesPeriodos = this.bloqueoHabitacionesPeriodosCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.bloqueoHabitacionesPeriodosDetalleCollection = this.afs.collection<BloqueoHabitacionesPeriodosDetalle>('bloqueo_habitaciones_periodos_detalle');
    this.bloqueoHabitacionesPeriodosDetalle = this.bloqueoHabitacionesPeriodosDetalleCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.tipoCuartoCollection = this.afs.collection<TipoCuarto>('tipo_cuarto');
    this.tipoCuarto = this.tipoCuartoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.complejoCollection = this.afs.collection<Complejo>('complejo');
    this.complejo = this.complejoCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );

    this.servicioCollection = this.afs.collection<Servicio>('servicio');
    this.servicio = this.servicioCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    this.clienteCollection = this.afs.collection<Cliente>('cliente');
    this.cliente = this.clienteCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data }
        });
      })
    );

    this.ordenesCollection = this.afs.collection<Orden>('ordenes', ref => ref.orderBy('created_date', 'desc'));
    this.ordenes = this.ordenesCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
  }



  createOrden(orden:Orden){
    return this.afs.collection('ordenes').add(orden);
  }

  // addItemOrden(item:ItemOrden,ordenId){
  //   this.afs.collection('ordenes/'+ordenId+'/items').add(item).then(function(docRef) {
  //     console.log("Document written with ID: ", docRef.id);
  //     return docRef.id;
  // })
  // .catch(function(error) {
  //     console.error("Error adding document: ", error);
  // });;
  // }
  updateDatosPago(datos:DatosPago,ordenId){

    console.log("datos DIREBASE", datos);
    console.log("orden",ordenId);

    return this.afs.doc('ordenes/'+ordenId+'/datosPago/'+datos.id).set(datos);



  }

  guardarDatosPago(orden:Orden,datos:DatosPago){
    return this.afs.collection('ordenes/'+orden.id+'/datosPago').add(datos);
  }

  addcomandasPreparacion(productosPreparacion:comandasPreparacion){
    return this.afs.collection('comandas_preparacion').add(productosPreparacion);
  }

  addcomandasPreparacionBarra(productosPreparacion:comandasPreparacionBarra){
    return this.afs.collection('comandas_preparacion_barra').add(productosPreparacion);
  }

  addcategoriasCarta(categoria:categoriasCarta){
    return this.afs.collection('categorias_carta').add(categoria);
  }

  addproductosArea(productosArea:productosArea){
    return this.afs.collection('productos_area').add(productosArea);
  }

  async addItemOrden(item:ItemOrden,ordenId,pedidoId){
    await this.afs.collection('ordenes/'+ordenId+'/pedidos/'+pedidoId+"/items").add(item);
  }

  updateItemOrden(item:ItemOrden,ordenId,pedidoId){

    console.log("ITEM DIREBASE", item);
    console.log("orden",ordenId);
    console.log("pedido", pedidoId);

    this.afs.doc('ordenes/'+ordenId+'/pedidos/'+pedidoId+"/items/"+item.id).set(item);
  }

  deleteItemOrden(item:ItemOrden,ordenId,pedidoId){
    this.afs.doc('ordenes/'+ordenId+'/pedidos/'+pedidoId+"/items/"+item.id).delete().catch((res)=>{
      console.log(res);
    }).then((res)=>{
      console.log("THEN",res);
    });
  }

  guardarPedido(pedido:Pedido,ordenId){
    return this.afs.collection('ordenes/'+ordenId+'/pedidos').add(pedido);
  }

  getPedidos(ordenID){
    return this.afs.collection("ordenes/"+ordenID+"/pedidos").snapshotChanges();
  }
  getDatosPago(ordenID){
    return this.afs.collection("ordenes/"+ordenID+"/datosPago").snapshotChanges();
  }
  getPagos(ordenId): Observable<DatosPago[]>
  {
    let tempData:Observable<DatosPago[]>;
    const collection = this.afs.collection<DatosPago>("ordenes/"+ordenId+"/datosPago");
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data};
        });
      })
    );
    return tempData;
  }

  GetPedidosAll(ordenID): Observable<Pedido[]> {
    let tempData: Observable<Pedido[]>;
    const collection = this.afs.collection<Pedido>("ordenes/"+ordenID+"/pedidos");
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }
  getOrderAllItems(orden,pedido){
    const col = this.afs.collection<ItemOrden>('ordenes/'+orden+"/pedidos/"+pedido+"/items", ref => ref.orderBy("folio"));
    let temp: Observable<ItemOrden[]>;
    temp = col.snapshotChanges().pipe(map(actions =>{
        return actions.map(a => {
          const data = a.payload.doc.data();
          return {...data}
        })
    }));
    return temp;
  }

  GetProductosAll(ordenID, pedidoID): Observable<ItemOrden[]> {
    let tempData: Observable<ItemOrden[]>;
    const collection = this.afs.collection<ItemOrden>("ordenes/" + ordenID +"/pedidos/" + pedidoID + "/items/");
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }

  async GetPedidoByID(ordenID: string, pedidoID: string): Promise<Pedido> {
    const col = this.afs.collection('ordenes/' + ordenID +'/pedidos/').doc(pedidoID);
    const data = (await col.ref.get()).data() as Pedido;
    return data;
  }

  //REPORTES COBRANZA CAJA Y CONSUMO
  GetOrdenesTerminadasReportes(startDate, endDate): Observable<Orden[]> {
    let tempData: Observable<Orden[]>;
    const collection = this.afs.collection<Orden>("ordenes", ref => ref.where("status", "in", ["Cerrada", 'Enviada', 'Enviada_Parcial', 'Rechazada']) //.where("status", "in", ["Cerrada", 'Enviada'])
    .where("created_date", ">=", startDate).where("created_date", "<=", endDate)
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }

  GetOrdenesCerradasByDate(startDate, endDate): Observable<Orden[]> {
    let tempData: Observable<Orden[]>;
    const collection = this.afs.collection<Orden>("ordenes", ref => ref.where("status", "in", ["Cerrada"]) //.where("status", "in", ["Cerrada", 'Enviada'])
    .where("created_date", ">=", startDate).where("created_date", "<=", endDate)
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }

  GetOrdenesRechadasByDate(startDate, endDate): Observable<Orden[]> {
    let tempData: Observable<Orden[]>;
    const collection = this.afs.collection<Orden>("ordenes", ref => ref.where("status", "==", "Rechazada")
    .where("created_date", ">=", startDate).where("created_date", "<=", endDate)
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }

  GetAreasAll(): Observable<Area[]> {
    const tempCollection = this.afs.collection<Area>('areas');
    const temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }

  GetMesasByAreaID(areaID): Observable<Mesa[]> {
    let tempData: Observable<Mesa[]>;
    const collection = this.afs.collection<Mesa>("areas/" + areaID +"/mesas/", ref => ref.orderBy("nombre"));
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }

  async getOrdenes(){
    var user = await this.auth.GetCurrentUser();
    var query =this.afs.collection('ordenes').ref.where('status', 'in', ["Abierta","Aceptada","Pagada"]).orderBy("folio");
    query = query.where('created_by','==',user.uid);
    return query.get();
  }
  async getAllOrdenes(){
    var user = await this.auth.GetCurrentUser();
    var query =this.afs.collection('ordenes').ref.where('status', 'in', ["Abierta","Aceptada","Pagada"]).orderBy("folio");
    return query.get();
  }

  async GetOrdenByID(id: string): Promise<Orden> {
    const col = this.afs.collection('ordenes').doc(id);
    const data = (await col.ref.get()).data() as Orden;
    return data;
  }

  getProductosArea(){
    let tempData: Observable<productosArea[]>;
    const collection = this.afs.collection<productosArea>("productos_area", ref => ref.where("estatus", "==", "Activo")
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

  getProductoArea(id_producto: string){
    let tempData: Observable<productosArea[]>;
    const collection = this.afs.collection<productosArea>("productos_area", ref => ref.where("id_producto", "==", id_producto)
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   GetProductosAreaBarra(){
    let tempData: Observable<productosArea[]>;
    const collection = this.afs.collection<productosArea>("productos_area", ref => ref.where("area_id", "==", "2")
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   GetPosicionLinea(id_linea){
    let tempData: Observable<productosArea[]>;
    const collection = this.afs.collection<productosArea>("productos_area", ref => ref.where("linea_id", "==", id_linea).where("estatus", "==", "Activo").orderBy('posicion', 'desc')
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   GetProductosAreaCocina(){
    let tempData: Observable<productosArea[]>;
    const collection = this.afs.collection<productosArea>("productos_area", ref => ref.where("area_id", "==", "1")
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

  getAllPedidosCocinaByOrden(id_orden: string){
    let tempData: Observable<comandasPreparacion[]>;
    const collection = this.afs.collection<comandasPreparacion>("comandas_preparacion", ref => ref.where("orden_id", "==", id_orden)
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getAllPedidosBarraByOrden(id_orden: string){
    let tempData: Observable<comandasPreparacionBarra[]>;
    const collection = this.afs.collection<comandasPreparacionBarra>("comandas_preparacion_barra", ref => ref.where("orden_id", "==", id_orden)
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

  getAllPedidosCocina(){
    let tempData: Observable<comandasPreparacion[]>;
    const collection = this.afs.collection<comandasPreparacion>("comandas_preparacion", ref => ref.where("estado", 'in', ["Espera","En Preparación", "Pausada"]).orderBy("fecha_agregado")
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getAllPedidosBarra(){
    let tempData: Observable<comandasPreparacionBarra[]>;
    const collection = this.afs.collection<comandasPreparacionBarra>("comandas_preparacion_barra", ref => ref.where("estado", 'in', ["Espera","En Preparación", "Pausada"]).orderBy("fecha_agregado")
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getAllPedidosByIdOrden(id_orden: string){
    let tempData: Observable<comandasPreparacion[]>;
    const collection = this.afs.collection<comandasPreparacion>("comandas_preparacion", ref => ref.where("orden_id", "==", id_orden).where("estado", "in", ["Espera","En Preparación", "Pausada"])
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getAllPedidosByIdOrdenBarra(id_orden: string){
    let tempData: Observable<comandasPreparacionBarra[]>;
    const collection = this.afs.collection<comandasPreparacionBarra>("comandas_preparacion_barra", ref => ref.where("orden_id", "==", id_orden).where("estado", "in", ["Espera","En Preparación", "Pausada"])
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getAllPedidosByIdOrdenEstatus(id_orden: string){
    let tempData: Observable<comandasPreparacion[]>;
    const collection = this.afs.collection<comandasPreparacion>("comandas_preparacion", ref => ref.where("orden_id", "==", id_orden).where("estado", "in", ["En Preparación", "Pausada", "Finalizado"])
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getAllPedidosByIdOrdenEstatusBarra(id_orden: string){
    let tempData: Observable<comandasPreparacionBarra[]>;
    const collection = this.afs.collection<comandasPreparacionBarra>("comandas_preparacion_barra", ref => ref.where("orden_id", "==", id_orden).where("estado", "in", ["En Preparación", "Pausada", "Finalizado"])
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getAllPedidosByIdPedidoEstatus(id_pedido: string){
    let tempData: Observable<comandasPreparacion[]>;
    const collection = this.afs.collection<comandasPreparacion>("comandas_preparacion", ref => ref.where("pedido_id", "==", id_pedido).where("estado", "in", ["En Preparación", "Pausada", "Finalizado"])
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getAllPedidosByIdPedidoEstatusBarra(id_pedido: string){
    let tempData: Observable<comandasPreparacionBarra[]>;
    const collection = this.afs.collection<comandasPreparacionBarra>("comandas_preparacion_barra", ref => ref.where("pedido_id", "==", id_pedido).where("estado", "in", ["En Preparación", "Pausada", "Finalizado"])
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getAllPedidosByIdPedido(id_pedido: string){
    let tempData: Observable<comandasPreparacion[]>;
    const collection = this.afs.collection<comandasPreparacion>("comandas_preparacion", ref => ref.where("pedido_id", "==", id_pedido).where("estado", "!=", "Finalizado")
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getAllPedidosByIdPedidoBarra(id_pedido: string){
    let tempData: Observable<comandasPreparacionBarra[]>;
    const collection = this.afs.collection<comandasPreparacionBarra>("comandas_preparacion_barra", ref => ref.where("pedido_id", "==", id_pedido).where("estado", "!=", "Finalizado")
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getAllCocinaByIdPedido(id_pedido: string){
    let tempData: Observable<comandasPreparacion[]>;
    const collection = this.afs.collection<comandasPreparacion>("comandas_preparacion", ref => ref.where("pedido_id", "==", id_pedido)
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getAllBarraByIdPedido(id_pedido: string){
    let tempData: Observable<comandasPreparacionBarra[]>;
    const collection = this.afs.collection<comandasPreparacionBarra>("comandas_preparacion_barra", ref => ref.where("pedido_id", "==", id_pedido)
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getPedidosByIdPedidoAndProducto(id_pedido: string, id_producto){
    let tempData: Observable<comandasPreparacion[]>;
    const collection = this.afs.collection<comandasPreparacion>("comandas_preparacion", ref => ref.where("pedido_id", "==", id_pedido).where("id_producto", "==", id_producto)
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   getPedidosByIdPedidoAndProductoBarra(id_pedido: string, id_producto){
    let tempData: Observable<comandasPreparacionBarra[]>;
    const collection = this.afs.collection<comandasPreparacionBarra>("comandas_preparacion_barra", ref => ref.where("pedido_id", "==", id_pedido).where("id_producto", "==", id_producto)
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

   UpdateComandasPreparacion(n: comandasPreparacion): Promise<void> {
    return this.comandasPreparacionCollection.doc(n.id).update(n);
  }

  UpdateComandasPreparacionBarra(n: comandasPreparacionBarra): Promise<void> {
    return this.comandasPreparacionBarraCollection.doc(n.id).update(n);
  }

   UpdateCategoriasCarta(n: categoriasCarta): Promise<void> {
    return this.categoriasCartaCollection.doc(n.id).update(n);
  }
   
  UpdateProductosArea(n: productosArea): Promise<void> {
    return this.productosAreaCollection.doc(n.id).update(n);
  }

   getOrdenesSub(){
    return this.afs.collection('ordenes').ref.where('status', '!=', "Cerrada");
   }

   getOrdenesCerradas(){
    let tempData: Observable<Orden[]>;
    const collection = this.afs.collection<Orden>("ordenes", ref => ref.where("status", "==", "Cerrada")
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }
   getOrdenesAbiertas(){
    let tempData: Observable<Orden[]>;
    const collection = this.afs.collection<Orden>("ordenes", ref => ref.where("status", "==", "Abierta")
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }
   getOrdenesEnviadas(){
    let tempData: Observable<Orden[]>;
    const collection = this.afs.collection<Orden>("ordenes", ref => ref.where("status", "==", "Enviada")
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }


   GetOrdenesEnviadasByDate(status, startDate, endDate): Observable<Orden[]> {
    let tempData: Observable<Orden[]>;
    //status  ?'Enviada_Parcial':'Enviada';
    const collection = this.afs.collection<Orden>("ordenes", ref => ref.where("status", "==", status)
    .where("created_date", ">=", startDate).where("created_date", "<=", endDate)
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }
  GetMovementsUsersByDate(startDate, endDate): Observable<MovementUsers[]> {
    let tempData: Observable<MovementUsers[]>;
    const collection = this.afs.collection<MovementUsers>("movement_users", ref => ref.where("fecha", ">=", startDate).where("fecha", "<=", endDate)
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }

  async updateOrden(orden:Orden){
    try {
      const id=orden.id;
      await this.afs.doc('ordenes/'+id).set(orden);
    } catch (error) {
      console.log(error)
    }
    // const id=orden.id;
    // await this.afs.doc('ordenes/'+id).set(orden);
    // return this.ordenCollection.doc(id).update(orden);

  }

  getOrdenItems(orden,pedido){
    return this.afs.collection('ordenes/'+orden+"/pedidos/"+pedido+"/items").snapshotChanges();
  }


  getOrden(orden){
    return this.afs.collection('ordenes').doc(orden).get();
  }
  /*
  getOrdenByCerrada(): Observable<Orden[]>
  {
    return this.orden.pipe(
      map( items =>
        items.filter(item => item.status = "Cerrada"))
    );

  }*/


  updatePedido(orden,pedido){
    console.log("ORDEN",orden);
    var id = pedido.id;
    delete pedido.id;
    delete pedido.productos;
    console.log("PeDIDO",pedido);
    this.afs.doc('ordenes/'+orden.id+'/pedidos/'+id).set(pedido);
  }

  createArea(area:Area){
    return this.afs.collection('areas').add(area);
  }

  createMesa(mesa:Mesa, area){
    return this.afs.collection('areas/'+area+'/mesas').add(mesa);
  }

  getAreas(){
    return this.afs.collection('areas', ref => ref.orderBy("nombre")).snapshotChanges();
  }

  getArea(area){
    return this.afs.collection('areas').doc(area).get();
  }

  getMesa(area,mesa){
    return this.afs.collection('areas/'+area+'/mesas').doc(mesa).get();
  }

  getMesas(area){
    return this.afs.collection('areas/'+area+'/mesas', ref => ref.orderBy("nombre").orderBy("created_date")).snapshotChanges();
  }

  getMesasLibres(area){
    return this.afs.collection('areas/'+area+'/mesas').ref.where('estado', '==', 'Libre').orderBy("nombre").orderBy("created_date").get();
  }

  updateArea(area:Area){
    this.afs.doc('areas/' + area.id).set(area);
  }

  updateMesa(mesa:Mesa, area){
    console.log("MEsa",mesa.id,area);
    var id = mesa.id;
    //delete mesa.id;
    this.afs.doc('areas/'+area+'/mesas/'+id).set(mesa);
  }

  deleteArea(area){
    console.log(area);
    this.afs.doc('areas/' + area.id).set(area);
    /*this.afs.doc('areas/' + area).delete().catch((res)=>{
      console.log(res);
    }).then((res)=>{
      console.log("THEN",res);
    });;*/
  }

  deleteMesa(mesa:Mesa, area){
    var id = mesa.id;
    delete mesa.id;
    this.afs.doc('areas/'+area+'/mesas/'+id).set(mesa);
    /*this.afs.doc('areas/'+area+'/mesas/'+mesa).delete().catch((res)=>{
      console.log(res);
    }).then((res)=>{
      console.log("THEN",res);
    });*/
  }














  // * ------------------------------ TURNO --------------------------------------

  GetTurnos(): Observable<Turno[]> {
    return this.turnos;
  }

  AddTurno(t: Turno): Promise<DocumentReference> {
    return this.turnosCollection.add(t);
  }

  DeleteTurno(c: Turno) {
    // return this.checkOutCollection.doc(id).delete();
    // return this.adminPasswordCollection.doc(c.id).update(c);
  }

  UpdateTurno(t: Turno): Promise<void> {
    return this.turnosCollection.doc(t.id).update(t);
  }

  // * -------------------------- PermisosTipo ------------------------------
  AddPermisosTipoUsuario(u: PermisosTipoUsuario):Promise<DocumentReference> {
    return this.permisosTipoUsuarioCollection.add(u);
  }

  GetPermisosTipoUsuarios(): Observable<PermisosTipoUsuario[]> {
    return this.permisosTipoUsuario;
  }
  UpdatePermisosTipoUsuario(PermisosTipoUsuario: PermisosTipoUsuario): Promise<void> {
    return this.permisosTipoUsuarioCollection.doc(PermisosTipoUsuario.id).update(PermisosTipoUsuario);
  }

  // * -------------------------- USUARIOS ------------------------------
  AddUusario(u: Usuario):Promise<DocumentReference> {
    return this.usuarioCollection.add(u);
  }

  GetUsuarios(): Observable<Usuario[]> {
    return this.usuario;
  }
  UpdatedatosUsuario(usuario: Usuario): Promise<void> {
    return this.usuarioCollection.doc(usuario.uid).update(usuario);
  }

  // * ------------------------- TICKET --------------------------------
  AddTicket(t: Ticket): Promise<DocumentReference> {
    return this.ticketCollection.add(t);
  }

  // * ------------------------- EnviosParciales --------------------------------
  AddEnviosParciales(t: EnviosParciales): Promise<DocumentReference> {
    return this.enviosParcialesCollection.add(t);
  }

  GetEnviosParcialesByOrden(id: string): Observable<EnviosParciales[]> {
    // return this.enviosParciales;
    let temp: Observable<EnviosParciales[]>;

    const collection = this.afs.collection<EnviosParciales>('envios_parciales', ref => ref.where('uid_orden', '==', id).where('status', '==', "Activa"));
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }

  UpdateEnviosParciales(EnviosParciales: EnviosParciales): Promise<void> {
    return this.enviosParcialesCollection.doc(EnviosParciales.id).update(EnviosParciales);
  }



  // * ------------------------------ ADMIN PASSWORDS --------------------------------------
  AddAdminPassword(a: AdminPassword): Promise<DocumentReference> {
    return this.adminPasswordCollection.add(a);
  }

  DeleteAdminPassword(c: AdminPassword) {
    // return this.checkOutCollection.doc(id).delete();
    // return this.adminPasswordCollection.doc(c.id).update(c);
  }

  UpdateAdminPassword(c: AdminPassword): Promise<void> {
    return this.adminPasswordCollection.doc(c.id).update(c);
  }

  GetAdminPasswordBy_UserId(userId: string): Observable<AdminPassword[]> {
    let temp: Observable<AdminPassword[]>;

    const collection = this.afs.collection<AdminPassword>('password_admin', ref => ref.where('userId', '==', userId));
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }


  // * ------------------------------ TIPO POLITICAS ---------------------------------------
  GetTipoPoliticas(): Observable<TipoPolitica[]> {
    return this.tipoPoliticas;
  }

  AddTipoPoliticas(p: TipoPolitica): Promise<DocumentReference> {
    return this.tipoPoliticasCollection.add(p);
  }

  DeleteTipoPoliticas(p: TipoPolitica): Promise<void> {
    // return this.checkOutCollection.doc(id).delete();
    p.estatus = estatusSistema.Inactivo;
    return this.tipoPoliticasCollection.doc(p.id).update(p);
  }

  UpdateTipoPoliticas(c: TipoPolitica): Promise<void> {
    return this.tipoPoliticasCollection.doc(c.id).update(c);
  }


  // * ------------------------------ POLITICAS ---------------------------------------
  GetPoliticas(): Observable<Politica[]> {
    return this.politicas;
  }

  GetPolitica(id: string): Observable<Politica[]> {
    let tempPolitica: Observable<Politica[]>;
    const collection = this.afs.collection<Politica>("politicas", ref => ref.where("id", "==", id));
    // console.log(collection);
    tempPolitica = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempPolitica;



  }

  AddPoliticas(p: Politica): Promise<DocumentReference> {
    return this.politicasCollection.add(p);
  }

  DeletePoliticas(p: Politica): Promise<void> {
    // return this.checkOutCollection.doc(id).delete();
    p.estatus = estatusSistema.Inactivo;
    return this.politicasCollection.doc(p.id).update(p);
  }

  UpdatePoliticas(c: Politica): Promise<void> {
    return this.politicasCollection.doc(c.id).update(c);
  }



  // * ------------------------------ CHECK-OUT ---------------------------------------
  GetCheckOut(): Observable<CheckOut[]> {
    return this.checkOut;
  }

  GetCheckOut_ByIdReservacion(idReservacion: string): Observable<CheckOut[]> {
    let tempData: Observable<CheckOut[]>;
    const collection = this.afs.collection<CheckOut>("check_out", ref => ref.where("idReservacion", "==", idReservacion));
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
  }



  AddMovementUsers(a: MovementUsers): Promise<DocumentReference> {
    return this.movementUsersCollection.add(a);
  }
  GetMovementsUsers(){
    return this.movementUsers;
  }


  AddCheckOut(a: CheckOut): Promise<DocumentReference> {
    return this.checkOutCollection.add(a);
  }


  DeleteCheckOut(c: CheckOut): Promise<void> {
    // return this.checkOutCollection.doc(id).delete();
    c.estatus = estatusSistema.Inactivo;
    return this.checkOutCollection.doc(c.id).update(c);
  }

  UpdateCheckOut(c: CheckOut): Promise<void> {
    return this.checkOutCollection.doc(c.id).update(c);
  }

  GetCheckOutBy_idReservation(idReserv: string): Observable<CheckOut[]> {
    let temp: Observable<CheckOut[]>;

    const collection = this.afs.collection<CheckOut>('check_out', ref => ref.where('idReservacion', '==', idReserv));
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }

  GetCheckOuts_ByDate(startDate: string, endDate: string): Observable<CheckOut[]> {
    let tempData: Observable<CheckOut[]>;
    const collection = this.afs.collection<CheckOut>('check_out', ref => ref.where('createdAt', '>=', startDate).where('createdAt', '<=', endDate));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as CheckOut;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempData;
  }

  // * ------------------------------ USERS ADMIN ---------------------------------------
  GetUsersAdmin(): Observable<UserAdmin[]> {
    return this.userAdmin;
  }

  AddUserAdmin(a: UserAdmin): Promise<DocumentReference> {
    return this.userAdminCollection.add(a);
  }

  DeleteUserAdmin(id: string): Promise<void> {
    return this.userAdminCollection.doc(id).delete();
  }

  UpdateUserAdmin(a: UserAdmin): Promise<void> {
    return this.userAdminCollection.doc(a.uid).update(a);
  }

  /**
  * get user data by username
  * @param username username used to login
  */
  GetUserAdmin_ByUsername(username: string): Observable<UserAdmin[]> {
    let tempUsers: Observable<UserAdmin[]>;
    const collection = this.afs.collection<UserAdmin>('user_admin', ref => ref.where('usuario', '==', username));
    // console.log(collection);
    tempUsers = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempUsers;
  }

  /**
* get user data by username
* @param uid username used to login
*/

  GetCategoriasCarta(){
    let tempData: Observable<categoriasCarta[]>;
    const collection = this.afs.collection<categoriasCarta>("categorias_carta", ref => ref.where("estatus", '==', 'Activo')
    );
    // console.log(collection);
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id

          return { id, ...data };
        });
      })
    );
    return tempData;
   }

  GetUserAdmin_ByUid(uid: string): Observable<UserAdmin[]> {
    let tempUsers: Observable<UserAdmin[]>;
    const collection = this.afs.collection<UserAdmin>('user_admin', ref => ref.where('uid', '==', uid));
    // console.log(collection);
    tempUsers = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempUsers;
  }

  GetUser_ByUid(uid: string): Observable<Usuario[]> {
    let tempUsers: Observable<Usuario[]>;
    const collection = this.afs.collection<Usuario>('usuario', ref => ref.where('id', '==', uid));
    // console.log(collection);
    tempUsers = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const uid = a.payload.doc.id; // the document itself does not contain its Id
          return { uid, ...data };
        });
      })
    );
    return tempUsers;
  }

  async GetUserAdminBy_ID(id: string): Promise<UserAdmin> {
    const col = this.afs.collection('user_admin').doc(id);
    const data = (await col.ref.get()).data() as UserAdmin;
    return data;
  }
  async GetUserBy_ID(id: string): Promise<Usuario> {
    const col = this.afs.collection('usaurio').doc(id);
    const data = (await col.ref.get()).data() as Usuario;
    return data;
  }


  /**
* @param id id
*/
  // GetSolicitudTransporte_By_X(idCuarto: string): Observable<SolicitudTransporte[]> {
  // let tempData: Observable<NumeroCuarto[]>;
  // const collection = this.afs.collection<NumeroCuarto>('numero_cuarto', ref => ref.where('idCuarto', '==', idCuarto));
  // tempData = collection.snapshotChanges().pipe(
  //   map(actions => {
  //     return actions.map(a => {
  //       const data = a.payload.doc.data() as NumeroCuarto;
  //       const id = a.payload.doc.id; // the document itself does not contain its Id
  //       return { id: id, ...data };
  //     });
  //   })
  // );
  // return tempData;
  // }



  // * ------------------------------ tipo servicio ---------------------------------------
  GetTipoServicios(): Observable<TipoServicio[]> {
    return this.tipoServicio;
  }
  GetTipoSubServiciosByTipoServicio(id: string): Observable<TipoSubServicio[]> {
    let tempData: Observable<TipoSubServicio[]>;
    const collection = this.afs.collection<TipoSubServicio>('TiposSubServicios', ref => ref.where('idTipoServicio', '==', id));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as TipoSubServicio;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    return tempData;
  }
  AddTipoServicio(c: TipoServicio): Promise<DocumentReference> {
    return this.tipoServicioCollection.add(c);
  }
  AddTipoSubServicio(c: TipoSubServicio): Promise<DocumentReference> {
    return this.tipoSubServicioCollection.add(c);
  }
  DeleteTipoServicio(id: string): Promise<void> {
    return this.tipoServicioCollection.doc(id).delete();
  }

  UpdateTipoServicio(c: TipoServicio): Promise<void> {
    return this.tipoServicioCollection.doc(c.id).update(c);
  }
  DeleteTipoSubServicio(id: string): Promise<void> {
    return this.tipoSubServicioCollection.doc(id).delete();
  }

  UpdateTipoSubServicio(c: TipoSubServicio): Promise<void> {
    return this.tipoSubServicioCollection.doc(c.id).update(c);
  }


  // * ------------------------------ CONDUCTOR ---------------------------------------
  GetConductores(): Observable<Conductor[]> {
    return this.conductor;
  }

  AddConductor(c: Conductor): Promise<DocumentReference> {
    return this.conductorCollection.add(c);
  }

  DeleteConductor(id: string): Promise<void> {
    return this.conductorCollection.doc(id).delete();
  }

  UpdateConductor(c: Conductor): Promise<void> {
    return this.conductorCollection.doc(c.id).update(c);
  }

  /**
* @param id id
*/
  // GetSolicitudTransporte_By_X(idCuarto: string): Observable<SolicitudTransporte[]> {
  // let tempData: Observable<NumeroCuarto[]>;
  // const collection = this.afs.collection<NumeroCuarto>('numero_cuarto', ref => ref.where('idCuarto', '==', idCuarto));
  // tempData = collection.snapshotChanges().pipe(
  //   map(actions => {
  //     return actions.map(a => {
  //       const data = a.payload.doc.data() as NumeroCuarto;
  //       const id = a.payload.doc.id; // the document itself does not contain its Id
  //       return { id: id, ...data };
  //     });
  //   })
  // );
  // return tempData;
  // }






  // * ------------------------------ SOLICITUD TRANSPORTE ---------------------------------------
  GetSolicitudTransporte(): Observable<SolicitudTransporte[]> {
    return this.solicitudTransporte;
  }

  AddSolicitudTransporte(s: SolicitudTransporte): Promise<DocumentReference> {
    return this.solicitudTransporteCollection.add(s);
  }

  DeleteSolicitudTransporte(id: string): Promise<void> {
    return this.solicitudTransporteCollection.doc(id).delete();
  }

  UpdateSolicitudTransporte(s: SolicitudTransporte): Promise<void> {
    return this.solicitudTransporteCollection.doc(s.id).update(s);
  }

  /**
* @param idCuarto id reservacion
*/
  // GetSolicitudTransporte_By_X(idCuarto: string): Observable<SolicitudTransporte[]> {
  // let tempData: Observable<NumeroCuarto[]>;
  // const collection = this.afs.collection<NumeroCuarto>('numero_cuarto', ref => ref.where('idCuarto', '==', idCuarto));
  // tempData = collection.snapshotChanges().pipe(
  //   map(actions => {
  //     return actions.map(a => {
  //       const data = a.payload.doc.data() as NumeroCuarto;
  //       const id = a.payload.doc.id; // the document itself does not contain its Id
  //       return { id: id, ...data };
  //     });
  //   })
  // );
  // return tempData;
  // }



  // id numero cuarto // KeBQbpOC75bOh60ofPv6
  // * ------------------------------ NUMERO CUARTO ---------------------------------------
  GetNumeroCuarto(): Observable<NumeroCuarto[]> {
    return this.numeroCuarto;
  }

  AddNumeroCuarto(n: NumeroCuarto): Promise<DocumentReference> {
    return this.numeroCuartoCollection.add(n);
  }

  DeleteNumeroCuarto(id: string): Promise<void> {
    return this.numeroCuartoCollection.doc(id).delete();
  }


  UpdateNumeroCuarto(n: NumeroCuarto): Promise<void> {
    return this.numeroCuartoCollection.doc(n.id).update(n);
  }

  /**
* @param idCuarto id reservacion
*/
  GetNumeroCuarto_ByIdCuarto(idCuarto: string): Observable<NumeroCuarto[]> {
    let tempData: Observable<NumeroCuarto[]>;
    const collection = this.afs.collection<NumeroCuarto>('numero_cuarto', ref => ref.where('idCuarto', '==', idCuarto));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as NumeroCuarto;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    return tempData;
  }





  // * ------------------------------ RESPUESTAS FORMULARIOS DINAMICOS ---------------------------------------
  GetRespuestaFormulariosDinamicos(): Observable<RespuestaFormDetallado[]> {
    return this.respuestaFormD;
  }

  AddRespuestaFormularioDinamico(r: RespuestaFormDetallado): Promise<DocumentReference> {
    return this.respuestaFormDCollection.add(r);
  }

  DeleteRespuestaFormularioDinamico(id: string): Promise<void> {
    return this.respuestaFormDCollection.doc(id).delete();
  }

  UpdateRespuestaFormularioDinamico(r: RespuestaFormDetallado): Promise<void> {
    return this.respuestaFormDCollection.doc(r.id).update(r);
  }

  async GetRespuestaFormularioDinamicoBy_ID(idRespuestaD: string): Promise<RespuestaFormDetallado> {
    const col = this.afs.collection('respuesta_form_detallado').doc(idRespuestaD);
    const data = (await col.ref.get()).data() as RespuestaFormDetallado;
    return data;
  }



  // * ------------------------------ FORMULARIOS DINAMICOS ---------------------------------------
  GetFormulariosDinamicos(): Observable<DatosFormulario[]> {
    return this.formulario;
  }

  AddFormularioDinamico(r: DatosFormulario): Promise<DocumentReference> {
    return this.formularioCollection.add(r);
  }

  DeleteFormularioDinamico(id: string): Promise<void> {
    return this.formularioCollection.doc(id).delete();
  }

  UpdateFormularioDinamico(r: DatosFormulario): Promise<void> {
    return this.formularioCollection.doc(r.id).update(r);
  }


  async GetFormularioDinamicoBy_ID(idFormulario: string): Promise<DatosFormulario> {
    const col = this.afs.collection('formulario_dinamico').doc(idFormulario);
    const data = (await col.ref.get()).data() as DatosFormulario;
    data.id = idFormulario;
    return data;
  }


  // * ------------------------------ Formulariobm---------------------------------------
  GetFormulariosbm(): Observable<Formulariobm[]> {
    return this.formulariobm;
  }


  AddDatosFormulariobm(d: Formulariobm): Promise<DocumentReference> {
    return this.formulariobmCollection.add(d);
  }

  DeleteFormulariobm(id: string): Promise<void> {
    return this.formulariobmCollection.doc(id).delete();
  }

  UpdateFormulariobm(d: Formulariobm): Promise<void> {
    return this.formulariobmCollection.doc(d.id).update(d);
  }

  GetAllFormulariobm(): Observable<Formulariobm[]> {
    let tempData: Observable<Formulariobm[]>;
    const collection = this.afs.collection<Formulariobm>('formularios_bm');
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Formulariobm;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    return tempData;
  }

  GetFormulariobmByName(name: string): Observable<Formulariobm[]> {
    let tempData: Observable<Formulariobm[]>;
    const collection = this.afs.collection<Formulariobm>('formularios_bm', ref => ref.where('name', '==', name));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Formulariobm;

          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    // tempData.forEach(element => {

    //   console.log("Haz pedido por "+ ( element));
    // });
    // console.log("TE ESTOY REGRESANDOO DATA "+JSON.stringify(tempData));
    return tempData;
  }

  GetFormulariobmById(idForm: string): Observable<Formulariobm[]> {
    let tempData: Observable<Formulariobm[]>;
    const collection = this.afs.collection<Formulariobm>('formularios_bm', ref => ref.where('id', '==', idForm));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Formulariobm;

          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    tempData.forEach(element => {

      console.log("Haz pedido por " + (element));
    });
    console.log("TE ESTOY REGRESANDOO DATA " + (tempData));
    return tempData;
  }
  // async CreateUploadTask_Formulariobm(file: any, fullPathFile: string) {
  //   const filePathn = '/imagenes/checkIn/' + fullPathFile; // * firebase storage path here (folder{s})
  //   const ref = this.storage.ref(filePathn);
  //   const task = await ref.put(file);
  //   const surl = await task.ref.getDownloadURL();
  //   return surl;

  // }


  // * ------------------------------ CHECK IN---------------------------------------
  GetCheckIns(): Observable<CheckIn[]> {
    return this.checkIn;
  }



  AddDatosCheckIn(d: CheckIn): Promise<DocumentReference> {
    return this.checkInCollection.add(d);
  }

  DeleteCheckIn(id: string): Promise<void> {
    return this.checkInCollection.doc(id).delete();
  }

  UpdateCheckIn(d: CheckIn): Promise<void> {
    return this.checkInCollection.doc(d.id).update(d);
  }

  GetCheckIn_IdReservacion(idReservacion: string): Observable<CheckIn[]> {
    let tempData: Observable<CheckIn[]>;
    const collection = this.afs.collection<CheckIn>('check_in', ref => ref.where('idReservacion', '==', idReservacion));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as CheckIn;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempData;
  }
  async CreateUploadTask_checkIn(file: any, fullPathFile: string) {
    const filePathn = '/imagenes/checkIn/' + fullPathFile; // * firebase storage path here (folder{s})
    const ref = this.storage.ref(filePathn);
    const task = await ref.put(file);
    const surl = await task.ref.getDownloadURL();
    return surl;
    // const file = event.target.files[0];
    // const filePath = '/categories_img/' + fullPathFile; // * firebase storage path here (folder{s})
    // const ref = this.storage.ref(filePath);
    // const task = ref.put(file); // * also exist putString() and upload()
    // this.progress = task.percentageChanges();
  }

  GetCheckIns_ByDate(startDate: string, endDate: string): Observable<CheckIn[]> {
    let tempData: Observable<CheckIn[]>;
    const collection = this.afs.collection<CheckIn>('check_in', ref => ref.where('createdAt', '>=', startDate).where('createdAt', '<=', endDate));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as CheckIn;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempData;
  }

  // * ------------------------------respuestaForm---------------------------------------
  GetRespuestaForm(): Observable<RespuestaForm[]> {
    return this.respuestaForm;
  }


  AddDatosRespuestaForm(d: RespuestaForm): Promise<DocumentReference> {
    return this.respuestaFormCollection.add(d);
  }

  DeleteRespuestaForm(id: string): Promise<void> {
    return this.respuestaFormCollection.doc(id).delete();
  }

  UpdateRespuestaForm(d: RespuestaForm): Promise<void> {
    return this.respuestaFormCollection.doc(d.id).update(d);
  }

  // * ------------------------------Configuracion---------------------------------------
  GetConfiguracion(): Observable<Configuracion[]> {
    return this.configuracion;
  }

  AddDatosConfiguracion(d: Configuracion): Promise<DocumentReference> {
    return this.configuracionCollection.add(d);
  }

  DeleteConfiguracion(id: string): Promise<void> {
    return this.configuracionCollection.doc(id).delete();
  }

  UpdateConfiguracion(d: Configuracion): Promise<void> {
    return this.configuracionCollection.doc(d.id).update(d);
  }

  // GetConfiguracion_IdReservacion(idReservacion: string): Observable<Configuracion[]> {
  //   let tempData: Observable<Configuracion[]>;
  //   const collection = this.afs.collection<Configuracion>('configuracion', ref => ref.where('idReservacion', '==', idReservacion));
  //   tempData = collection.snapshotChanges().pipe(
  //     map(actions => {
  //       return actions.map(a => {
  //         const data = a.payload.doc.data() as Configuracion;
  //         const id = a.payload.doc.id; // the document itself does not contain its Id
  //         return { uid: id, ...data };
  //       });
  //     })
  //   );
  //   return tempData;
  // }
  // async CreateUploadTask_Configuracion(file: any, fullPathFile: string) {
  //   const filePathn = '/imagenes/Configuracion/' + fullPathFile; // * firebase storage path here (folder{s})
  //   const ref = this.storage.ref(filePathn);
  //   const task = await ref.put(file);
  //   const surl = await task.ref.getDownloadURL();
  //   return surl;
  //   // const file = event.target.files[0];
  //   // const filePath = '/categories_img/' + fullPathFile; // * firebase storage path here (folder{s})
  //   // const ref = this.storage.ref(filePath);
  //   // const task = ref.put(file); // * also exist putString() and upload()
  //   // this.progress = task.percentageChanges();
  // }


  // *---------------------------------------------------------- DATOS TARJETA ---------------------------------------------------------


  GetDatosTarjeta(): Observable<DatosTarjeta[]> {
    return this.datosTarjeta;
  }


  /**
  * @param idReservacion id reservacion
  */
  GetDatosTarjeta_IdReservacion(idReservacion: string): Observable<DatosTarjeta[]> {
    let tempData: Observable<DatosTarjeta[]>;
    const collection = this.afs.collection<DatosTarjeta>('datos_tarjeta', ref => ref.where('idReservacion', '==', idReservacion));

    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as DatosTarjeta;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    return tempData;
  }

  /**
* @param idUsuario Id tipo de cuarto
*/
  GetDatosTarjeta_IdUsuario(idUsuario: string): Observable<DatosTarjeta[]> {
    let tempData: Observable<DatosTarjeta[]>;
    const collection = this.afs.collection<DatosTarjeta>('datos_tarjeta', ref => ref.where('idUsuario', '==', idUsuario));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as DatosTarjeta;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempData;
  }


  /**
* @param nombreTarjeta nombre que aparece en la tarjeta
*/
  GetDatosTarjeta_NombreTarjeta(nombreTarjeta: string): Observable<DatosTarjeta[]> {
    let tempData: Observable<DatosTarjeta[]>;
    const collection = this.afs.collection<DatosTarjeta>('datos_tarjeta', ref => ref.where('nombreTarjeta', '==', nombreTarjeta));
    tempData = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as DatosTarjeta;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempData;
  }





  AddDatosTarjeta(d: DatosTarjeta): Promise<DocumentReference> {
    return this.datosTarjetaCollection.add(d);
  }

  DeleteDatosTarjeta(id: string): Promise<void> {
    return this.datosTarjetaCollection.doc(id).delete();
  }

  UpdateDatosTarjeta(d: DatosTarjeta): Promise<void> {
    return this.datosTarjetaCollection.doc(d.id).update(d);
  }




  // * ------------------------------ RESERVACION ---------------------------------------
  /**
   * Return reservations of all times (no limit)
   * @returns All reservations Observable
   */
  GetAllReservations(): Observable<Reservacion[]> {
    const tempCollection = this.afs.collection<Reservacion>('reservacion', ref => ref.orderBy('createdAt', 'desc'));
    const temp = tempCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }

  GetReservations(): Observable<Reservacion[]> {
    return this.reservacion;
  }

  AddReservation(r: Reservacion): Promise<DocumentReference> {
    return this.reservacionCollection.add(r);
  }

  DeleteReservation(id: string): Promise<void> {
    return this.reservacionCollection.doc(id).delete();
  }

  UpdateReservation(r: Reservacion): Promise<void> {
    return this.reservacionCollection.doc(r.id).update(r);
  }


  GetReservationBy_TipoCuarto(idTipoCuarto: string): Observable<Reservacion[]> {
    let tempReservation: Observable<Reservacion[]>;
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('tipoCuarto', '==', idTipoCuarto));
    // console.log(collection);
    tempReservation = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Reservacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );
    return tempReservation;
  }



  GetReservationBy_IdCuarto_FechFinal(idCuarto: string, currentReservation_fechaInicio: string = ''): Observable<Reservacion[]> {
    // Conditions
    // I >= start && I <= end
    let tempReservation: Observable<Reservacion[]>;
    // * NEW
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('fechaFinal', '>=', currentReservation_fechaInicio)
      .where('estatus', 'in', [estatusReservacion.Activo, estatusReservacion.Checked_Out, estatusReservacion.Completado, estatusReservacion.CheckIn])
      .limit(200)
    );

    // * OLD
    // const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('idCuarto', '==', idCuarto)
    //   .where('fechaFinal', '>=', currentReservation_fechaInicio)
    //   // .where('estatus', '!=', estatusReservacion.Cancelado)
    //   .limit(200)
    // );
    // console.log(collection);
    tempReservation = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Reservacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id: id, ...data };
        });
      })
    );

    return tempReservation;
    // const tmp = await this.AwaitForData(tempReservation);
    // reservations = reservations.concat(tmp);
    // console.log(reservations);

    // return reservations;
  }


  GetReservacionBy_Date(startDate: string, endDate: string): Observable<Reservacion[]> {
    // //console.log('id_' + categoryId);
    let temp: Observable<Reservacion[]>;

    // const collection = this.afs.collection<Pedido>('pedidos', ref => ref
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('fechaInicio', '>=', startDate).where('fechaInicio', '<=', endDate)
      // .orderBy('date', 'desc').startAt(startDate).endAt(endDate));
      .orderBy('fechaInicio', 'desc'));
    // //console.log(collection);
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    // //console.log(2);

    return temp;
  }

  GetReservationBy_userUID(userUid: string): Observable<Reservacion[]> {
    let tempReservation: Observable<Reservacion[]>;
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('idUsuario', '==', userUid).orderBy('fechaReservacion', 'desc'));
    // console.log(collection);
    tempReservation = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Reservacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempReservation;
  }

  GetReservationBy_uid(uid: string): Observable<Reservacion[]> {
    let tempReservation: Observable<Reservacion[]>;
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('id', '==', uid));
    // console.log(collection);
    tempReservation = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Reservacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempReservation;
  }

  async GetReservationsById(id: string): Promise<Reservacion> {
    const col = this.afs.collection('reservacion').doc(id);
    const data = (await col.ref.get()).data() as Reservacion;
    return data;
  }

  GetReservationsCheckIn(): Observable<Reservacion[]> {
    let tempReservation: Observable<Reservacion[]>;
    const collection = this.afs.collection<Reservacion>('reservacion', ref => ref.where('estatus', '==', estatusReservacion.CheckIn));
    tempReservation = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Reservacion;
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { uid: id, ...data };
        });
      })
    );
    return tempReservation;
  }

  // * ------------------------------ CUARTO ---------------------------------------
  GetCuartos(): Observable<Cuarto[]> {
    return this.cuarto;
  }

  AddCuarto(c: Cuarto): Promise<DocumentReference> {
    return this.cuartoCollection.add(c);
  }

  DeleteCuarto(id: string): Promise<void> {
    return this.cuartoCollection.doc(id).delete();
  }

  UpdateCuarto(c: Cuarto): Promise<void> {
    return this.cuartoCollection.doc(c.id).update(c);
  }

  // * ------------------------------ SYS_LOG ---------------------------------------
  GetSys_log(): Observable<Sys_log[]> {
    return this.sys_log;
  }

  AddSys_log(c: Sys_log): Promise<DocumentReference> {
    return this.sys_logCollection.add(c);
  }

  DeleteSys_log(id: string): Promise<void> {
    return this.sys_logCollection.doc(id).delete();
  }

  UpdateSys_log(c: Sys_log): Promise<void> {
    return this.sys_logCollection.doc(c.id).update(c);
  }


  // * ------------------------------ BloqueoHabitacionesPeriodos ---------------------------------------
  GetBloqueoHabitacionesPeriodos(): Observable<BloqueoHabitacionesPeriodos[]> {
    return this.bloqueoHabitacionesPeriodos;
  }

  AddBloqueoHabitacionesPeriodos(c: BloqueoHabitacionesPeriodos): Promise<DocumentReference> {
    return this.bloqueoHabitacionesPeriodosCollection.add(c);
  }

  DeleteBloqueoHabitacionesPeriodos(id: string): Promise<void> {
    return this.bloqueoHabitacionesPeriodosCollection.doc(id).delete();
  }

  UpdateBloqueoHabitacionesPeriodos(c: BloqueoHabitacionesPeriodos): Promise<void> {
    return this.bloqueoHabitacionesPeriodosCollection.doc(c.id).update(c);
  }


  // * ------------------------------ BloqueoHabitacionesPeriodos ---------------------------------------
  GetBloqueoHabitacionesPeriodosDetalle(): Observable<BloqueoHabitacionesPeriodosDetalle[]> {
    return this.bloqueoHabitacionesPeriodosDetalle;
  }

  AddBloqueoHabitacionesPeriodosDetalle(c: BloqueoHabitacionesPeriodosDetalle): Promise<DocumentReference> {
    return this.bloqueoHabitacionesPeriodosDetalleCollection.add(c);
  }

  DeleteBloqueoHabitacionesPeriodosDetalle(id: string): Promise<void> {
    return this.bloqueoHabitacionesPeriodosDetalleCollection.doc(id).delete();
  }

  UpdateBloqueoHabitacionesPeriodosDetalle(c: BloqueoHabitacionesPeriodosDetalle): Promise<void> {
    return this.bloqueoHabitacionesPeriodosDetalleCollection.doc(c.id).update(c);
  }



  // * ------------------------------ PAGO --------------------------------------------

  GetPago(): Observable<Pago[]> {
    return this.pago;
  }
  AddPago(t: Pago): Promise<DocumentReference> {
    return this.pagoCollection.add(t);
  }
  DeletePago(id: string): Promise<void> {
    return this.pagoCollection.doc(id).delete();
  }
  UpdatePago(p: Pago): Promise<void> {
    return this.pagoCollection.doc(p.id).update(p);
  }

  // *-------------------------CALCULARPRECIOCUARTOFECHA---------------------------------

  GetPrecioCuartoFecha(): Observable<PrecioCuartoFecha[]> {
    return this.preciocuartofecha;
  }
  AddPrecioCuartoFecha(t: PrecioCuartoFecha): Promise<DocumentReference> {
    return this.preciocuartofechaCollection.add(t);
  }
  DeletePrecioCuartoFecha(id: string): Promise<void> {
    return this.preciocuartofechaCollection.doc(id).delete();
  }
  UpdatePrecioCuartoFecha(p: PrecioCuartoFecha): Promise<void> {
    return this.preciocuartofechaCollection.doc(p.id).update(p);
  }



  // * ------------------------------ TIPO CUARTO ---------------------------------------
  AddPagoGral(t: FechasPagoGeneral): Promise<DocumentReference> {
    return this.fechaspagogeneralCollection.add(t);
  }
  GetPagoGral(): Observable<FechasPagoGeneral[]> {
    return this.fechaspagogeneral;
  }
  UpdatePagoGral(d: FechasPagoGeneral): Promise<void> {
    return this.fechaspagogeneralCollection.doc(d.id).update(d);
  }

  // * ------------------------------ TIPO CUARTO ---------------------------------------
  GetTipoCuartos(): Observable<TipoCuarto[]> {
    return this.tipoCuarto;
  }

  AddTipoCuarto(t: TipoCuarto): Promise<DocumentReference> {
    return this.tipoCuartoCollection.add(t);
  }

  DeleteTipoCuarto(id: string): Promise<void> {
    return this.tipoCuartoCollection.doc(id).delete();
  }

  UpdateTipoCuarto(t: TipoCuarto): Promise<void> {
    return this.tipoCuartoCollection.doc(t.id).update(t);
  }




  // * ------------------------------ COMPLEJOS ---------------------------------------

  GetComplejos(): Observable<Complejo[]> {
    return this.complejo;
  }

  AddComplejo(c: Complejo): Promise<DocumentReference> {
    return this.complejoCollection.add(c);
  }

  GetComplejoByName(nom: string): Observable<Complejo[]> {
    let tempVar: Observable<Complejo[]>;
    const collection = this.afs.collection<Complejo>('complejo', ref => ref.where('nombre', '==', nom));
    tempVar = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempVar;
  }

  DeleteComplejo(id: string): Promise<void> {
    return this.complejoCollection.doc(id).delete();
  }

  UpdateComplejo(c: Complejo): Promise<void> {
    return this.complejoCollection.doc(c.id).update(c);
  }



  // * ------------------------------ SERVICIOS ---------------------------------------
  GetServicio(): Observable<Servicio[]> {
    return this.servicio;
  }

  AddServicio(s: Servicio): Promise<DocumentReference> {
    return this.servicioCollection.add(s);
  }

  DeleteServicio(id: string): Promise<void> {
    return this.servicioCollection.doc(id).delete();
  }

  UpdateServicio(s: Servicio): Promise<void> {
    return this.servicioCollection.doc(s.id).update(s);
  }

  // * --------------------------- CLIENTE -------------------------------------------
  GetCliente(): Observable<Cliente[]> {
    return this.cliente;
  }
  AddCliente(cl: Cliente): Promise<DocumentReference> {
    return this.clienteCollection.add(cl);
  }
  DeleteCliente(id: string): Promise<void> {
    return this.clienteCollection.doc(id).delete();
  }

  UpdateCliente(cl: Cliente): Promise<void> {
    return this.clienteCollection.doc(cl.id).update(cl);
  }

  // ? ------------------- FIREBASE STORAGE UPLOAD/GET IMAGE -------------------------------
  async CreateUploadTask(file: any, fullPathFile: string) {
    const filePathn = '/imagenes/recamaras/' + fullPathFile; // * firebase storage path here (folder{s})
    const ref = this.storage.ref(filePathn);
    const task = await ref.put(file);
    const surl = await task.ref.getDownloadURL();
    return surl;
    // const file = event.target.files[0];
    // const filePath = '/categories_img/' + fullPathFile; // * firebase storage path here (folder{s})
    // const ref = this.storage.ref(filePath);
    // const task = ref.put(file); // * also exist putString() and upload()
    // this.progress = task.percentageChanges();
  }
  async CreateUploadTask_Complejos(file: any, fullPathFile: string) {
    const filePathn = '/imagenes/complejos/' + fullPathFile; // * firebase storage path here (folder{s})
    const ref = this.storage.ref(filePathn);
    const task = await ref.put(file);
    const surl = await task.ref.getDownloadURL();
    return surl;
    // const file = event.target.files[0];
    // const filePath = '/categories_img/' + fullPathFile; // * firebase storage path here (folder{s})
    // const ref = this.storage.ref(filePath);
    // const task = ref.put(file); // * also exist putString() and upload()
    // this.progress = task.percentageChanges();
  }

  async DeleteFromStorage(url: string) {
    console.log('REF URL: ', url);
    try {
      const ref = this.storage.storage.refFromURL(url);
      await ref.delete();
    } catch (error) {
      console.log(error);
    }
    // console.log(ref);

    // const task = await ref.put(file);
    // const surl = await task.ref.getDownloadURL();
  }

  GetImage(fullPathFile: string): Observable<any> {
    // //console.log('name_' + fullPathFile);
    // //console.log(this.storage.ref('/appforms-img/').getDownloadURL());

    const fileRef = this.storage.ref('/categories_img/' + fullPathFile);
    // //console.log(fileRef.getDownloadURL());
    // //console.log(fileRef);

    return fileRef.getDownloadURL(); // return observable

    // ? then use this
    //  const img = this.fbs.GetImage('');
    //  img.subscribe(data => this.image = data);
  }


  // ?
  // async GetPedido_TuTiendita(pedido: PedidoRestaurant): Promise<Pedido> {
  //   const collection = this.afs.collection<Pedido>('pedidos').doc(pedido.pedidoId);
  //   const data = (await collection.ref.get()).data() as Pedido;
  //   return data;
  // }

  GetUser_ByEmail(email: string): Observable<UserAdmin[]> {
    let tempUsers: Observable<UserAdmin[]>;
    const collection = this.afs.collection<UserAdmin>('users', ref => ref.where('email', '==', email).where('active', '==', true));
    // console.log(collection);
    tempUsers = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return tempUsers;
  }

  GetUserPasswordBy_UserId(userId: string): Observable<UserPassword[]> {
    let temp: Observable<UserPassword[]>;

    const collection = this.afs.collection<UserPassword>('password_user', ref => ref.where('userId', '==', userId));
    temp = collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id; // the document itself does not contain its Id
          return { id, ...data };
        });
      })
    );
    return temp;
  }

  AddUserPassword(a: UserPassword): Promise<DocumentReference> {
    return this.userPasswordCollection.add(a);
  }

  UpdateUserPassword(c: UserPassword): Promise<void> {
    return this.userPasswordCollection.doc(c.id).update(c);
  }

}
