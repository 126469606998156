import { AuthService } from 'src/services/auth.service';
import { Subscription } from 'rxjs';
import { ModalDeleteProductoResumenComponent } from './../modal-delete-producto-resumen/modal-delete-producto-resumen.component';
import { MovementUsers, Pedido } from './../../services/firebase-data.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden, comandasPreparacion, comandasPreparacionBarra, UserAdmin } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalController} from '@ionic/angular';
import moment from 'moment';
import { ModalNotaProductoComponent } from '../modal-nota-producto/modal-nota-producto.component';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/services/config.service';

@Component({
  selector: 'app-modal-edit-comanda',
  templateUrl: './modal-edit-comanda.component.html',
  styleUrls: ['./modal-edit-comanda.component.scss'],
})
export class ModalEditComandaComponent implements OnInit {

  @Input("pedido") pedido;
  @Input("orden") orden;

  ordenID: any;

  productos : any;

  totalPedido: any;

  comensales: any;

  prodSub: Subscription;

  userUID:string;

  prods:any;

  preTotal:any;
  userDatos: any;
  today: string;
  public observableCocina: Observable<comandasPreparacion[]>;
  public observableBarra: Observable<comandasPreparacionBarra[]>;
  permisoModificarOrden: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController,
    private modalControl2: ModalController,
    public config: ConfigService,
  ) {
    this.today = moment().format('YYYY-MM-DD');

  }

  bloquear = [];
  userNombre: any;
  async ngOnInit() {

    // console.log('pedido',this.pedido);
    this.userDatos=await this.authService.GetCurrentUser();

    this.productos = this.pedido.productos[0];

    let cocina = await this.AwaitPedidosCocinaByPedido(this.pedido.id);
    for (let a = 0; a < this.productos.length; a++) {
      const producto = this.productos[a];
      if(cocina.length > 0){
        let posicion = cocina.findIndex(t => t.id_producto === producto.id_producto);
  
        if(posicion != -1){
          this.bloquear[a] = cocina[posicion]["estado"];
        }
      }
      else{
        this.bloquear[a] = "Finalizado";
      }
    }

    let barra = await this.AwaitPedidosBarraByPedido(this.pedido.id);
    for (let a = 0; a < this.productos.length; a++) {
      const producto = this.productos[a];
      if(barra.length > 0){
        let posicion = barra.findIndex(t => t.id_producto === producto.id_producto);
  
        if(posicion != -1)
        this.bloquear[a] = barra[posicion]["estado"];
      }
    }
    console.log("PRODUCTOS",this.productos);
    console.log("Estatus",this.bloquear);

    this.ordenID = this.orden.id
    this.prods = this.productos;
    this.comensales =this.orden.comensales;

    this.totalPedido = 0;
    this.comensales.forEach(element => {
      element.total = 0;
    });
    this.productos.forEach(element => {
        var total = (element.precio * element.cantidad);
        this.totalPedido += total;
        element.total = total;

        this.comensales.forEach(item => {

          if(element.comensal == item.nombre){

            item.total += total;
            // console.log(item.total);
          }
        });
    });

    this.preTotal = this.totalPedido;

    // console.log("TOTAL", this.preTotal);

    var data = await this.authService.GetCurrentUser();

    this.userUID = data.uid;
    this.userNombre = await this.WaitForUserData(this.userUID);

    // console.log("PRODS",this.productos);
    // console.log("COMENSALES",this.comensales);

    // this.prodSub = this.productos.subscribe((prods)=>{
    //   if(prods.length == 0){
    //     this.closeModal();
    //   }
    // })

    this.permisoModificarOrden =this.config.CheckPermiso(this.config.permisoRestModificarOrden);
  }

  WaitForUserData(uid): Promise<UserAdmin> {
    return new Promise((resolve, reject) => {
      this.firebaseService.GetUserAdmin_ByUid(uid).subscribe((user) => {
        if (user) {
          resolve(user[0]);
        }
        else {
          reject(false);
        }
      })
    });
  }

  async AwaitPedidosCocinaByPedido(id_pedido: string): Promise<comandasPreparacion[]> {
    return new Promise((resolve, reject) => {
      this.observableCocina = this.firebaseService.getAllCocinaByIdPedido(id_pedido);
  
      this.observableCocina.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async AwaitPedidosBarraByPedido(id_pedido: string): Promise<comandasPreparacion[]> {
    return new Promise((resolve, reject) => {
      this.observableCocina = this.firebaseService.getAllBarraByIdPedido(id_pedido);
  
      this.observableCocina.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }

  FormatNumber(number){
    let parts = number.toString().split(".")
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return num
  }

  async guardarPedido(){
    const pedidoOrg=this.pedido;
    const ordenOrg=this.orden;

    this.totalPedido = 0;
    this.comensales.forEach(element => {
      element.total = 0;
    });
    this.productos.forEach(element => {
        var total = (element.precio * element.cantidad);
        this.totalPedido += total;
        element.total = total;

        this.comensales.forEach(item => {

          if(element.comensal == item.nombre){

            item.total += total;
            // console.log(item.total);
          }
        });
    });


    if(this.productos.length > 0){
      var cont = 0;
      try {
       await this.PresentLoading("Guardando Pedido",2000);

       var pedidoID :any;
       var totalProds = 0;
       this.productos.forEach(element => {
          totalProds += element.precio * element.cantidad;
       });

       this.orden.totalOrden = parseFloat(this.orden.totalOrden);
       this.orden.totalOrden = parseFloat(this.orden.totalOrden) - parseFloat(this.preTotal);

       this.orden.totalOrden += totalProds;

       pedidoID = this.pedido.id;

       await this.firebaseService.updateOrden(this.orden);
       const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

       let ordeData: MovementUsers;
      //  console.log("orden---",ordenOrg);

       ordeData = {
         tipo: "ordenes",
         id_objeto: ordenOrg.id,
         id_usuario: this.userDatos.uid,
         accion: "actualizar",
         movimiento: "Actualizar orden",
         original:ordenOrg,
         nuevo: this.orden,
         fecha: timeStamp_cer

       }
       await this.firebaseService.AddMovementUsers(ordeData);


       this.pedido.total_pedido = totalProds;

       await this.firebaseService.updatePedido(this.orden,this.pedido);
       let ordeData2: MovementUsers;

      //  console.log("pedido---",pedidoOrg);

       ordeData2 = {
         tipo: "ordenes-pedidos",
         id_objeto:pedidoID,
         id_usuario: this.userDatos.uid,
         accion: "actualizar",
         movimiento: "Actualizar pedido",
         original:pedidoOrg,
         nuevo: this.pedido,
         fecha: timeStamp_cer


       }
       await this.firebaseService.AddMovementUsers(ordeData2);


      //  console.log("FINAL",this.orden);

      //  console.log("PEDIDO ID", pedidoID);
       this.productos.forEach(async (element, ind) => {
           var item: ItemOrden;

           item ={
             id: element.id,
             id_producto:element.id_producto,
             cantidad: element.cantidad,
             nombre_producto: element.nombre_producto,
             imagen_url: element.imagen_url,
             precio: element.precio,
             comensal: element.comensal,
             estado: element.estado,
             facturable:element.facturable,
             impuestos:element.impuestos,
             unidad_medida:element.unidad_medida,
             anotacion:element.anotacion
           }as ItemOrden

           item.cantidad = parseFloat(element.cantidad);

          //  console.log("PRODUCTO",item);

           await this.firebaseService.updateItemOrden(item, this.ordenID,pedidoID);

           let cocina = await this.AwaitPedidosCocinaByPedidoByProducto(pedidoID, item.id_producto);
           if(cocina.length > 0){
             cocina[0]["anotacion"] = item.anotacion;
             cocina[0]["cantidad"] = item.cantidad;
             cocina[0]["comensal"] = item.comensal;
             cocina[0]["fecha_agregado"] = moment().format('YYYY-MM-DD HH:mm:ss');
             cocina[0]["edited_by"] = this.userNombre.uid;
             cocina[0]["mesero_name"] = this.userNombre.name;
             await this.firebaseService.UpdateComandasPreparacion(cocina[0]);
           }
           else{
            let barra = await this.AwaitPedidosBarraByPedidoByProducto(pedidoID, item.id_producto);
            barra[0]["anotacion"] = item.anotacion;
            barra[0]["cantidad"] = item.cantidad;
            barra[0]["comensal"] = item.comensal;
            barra[0]["fecha_agregado"] = moment().format('YYYY-MM-DD HH:mm:ss');
            barra[0]["edited_by"] = this.userNombre.uid;
            barra[0]["mesero_name"] = this.userNombre.name;
            await this.firebaseService.UpdateComandasPreparacionBarra(barra[0]);
           }

           let ordeData: MovementUsers;
           ordeData = {
             tipo: "ordenes-pedidos-items",
             id_objeto: item.id,
             id_usuario: this.userDatos.uid,
             accion: "actualizar",
             movimiento: "Agregar item a orden",
             original:null,
             nuevo: item,
            fecha: timeStamp_cer


           }
           await this.firebaseService.AddMovementUsers(ordeData);


           if(cont >= this.productos.length-1){
            setTimeout(()=>{
              this.modalControl.dismiss();
              // console.log("SUBMIT",this.productos);
            },500)
           }
           cont++;
       });
      } catch (error) {
        // console.log(error);
         this.DismissLoading();
      }

    }else{
      this.closeModal();
    }

   }

   async AwaitPedidosCocinaByPedidoByProducto(id_pedido: string, id_producto): Promise<comandasPreparacion[]> {
    return new Promise((resolve, reject) => {
      this.observableCocina = this.firebaseService.getPedidosByIdPedidoAndProducto(id_pedido, id_producto);
  
      this.observableCocina.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async AwaitPedidosBarraByPedidoByProducto(id_pedido: string, id_producto): Promise<comandasPreparacionBarra[]> {
    return new Promise((resolve, reject) => {
      this.observableBarra = this.firebaseService.getPedidosByIdPedidoAndProductoBarra(id_pedido, id_producto);
  
      this.observableBarra.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }

   async deleteItem(prod,i, estatus){
    if(!this.permisoModificarOrden){
      if(estatus == 'Pausada' || estatus == 'En Preparación' || estatus == 'Finalizado'){
        return;
      }
    }
    const modal = await this.modalControl2.create({
      component: ModalDeleteProductoResumenComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"my-custom-modal-css",
      componentProps:{productos:this.productos, indexProd:i, mensajeConfirmacion:"¿Desea eliminar "+prod.nombre_producto+"?", data:{
        pedidoID: this.pedido.id,
        ordenID:this.ordenID,
        producto:prod,
        tipo: "edit"
      }}
    });

      modal.onDidDismiss().then((data) => {
        this.guardarPedido();
        if(this.productos.length == 0 ){
          // console.log("DATA",this.productos);
          this.closeModal();
        }
      })
    return await modal.present();
   }

   closeModal(){
    this.modalControl.dismiss();
  }

  comensalChange(event,prod){
    var value = event.detail.value;
    prod.comensal= value;
    // console.log(value);
  }

  cantidadChange(event,prod){
    var value = event.detail.value;
    prod.cantidad = value;

    prod.total = prod.cantidad * prod.precio;
    // console.log(value);
  }

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }

  async showNota(prod, i, estatus){
    if(!this.permisoModificarOrden){
      if(estatus == 'Pausada' || estatus == 'En Preparación'){
        return;
      }
    }
    else{
      estatus = "Modificar";
    }

    const modal = await this.modalControl2.create({
      component: ModalNotaProductoComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      componentProps:{producto: prod, proceso: "Confirmar", estatus: "Continuar", estado: estatus},
      cssClass:"aceptarComandaModal"
    });
    
    await modal.present();

    let proceso;
    await modal.onDidDismiss().then((data) => {
      proceso = data;
    })

    if(proceso.role != "Cerrar" && proceso.role != "backdrop"){
      prod.anotacion = proceso.data;
      await this.firebaseService.updateItemOrden(prod, this.ordenID, this.pedido.id);
    }
   }

   bloquearBoton(estado){
    if(!this.permisoModificarOrden){
      if(estado == "Finalizado"){
        return true;
      }
      else if(estado == "Espera"){
        return false;
      }
      else{
        return true;
      }
    }
   }

   bloquearBotonBorrar(estado){
    if(!this.permisoModificarOrden){
      if(estado == "Finalizado"){
        return true;
      }
      else if(estado == "Espera"){
        return false;
      }
      else if(estado == "En Preparación"){
        return true;
      }
    }
  }

}
