import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';
import { ConfigService } from 'src/services/config.service';
import { FirebaseDataService, Usuario } from 'src/services/firebase-data.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  area:boolean;
  restaurante:boolean;
  clientes:boolean;
  mesas:boolean;
  restaurant:boolean;
  constructor(
    private authService: AuthService,
    private router: Router,
    public config: ConfigService,
    public firebaseService: FirebaseDataService,

  ) { }

  goToComponent(route:any){

      this.router.navigate(['/'+ route]);  // define your component where you want to go

}
  GetKeyRoute(route: any): any {
    let res='';
    switch (route) {
      case 'mesas':
        res=this.config.permisoInMesas;
        break;
      case 'clientes':
        res=this.config.permisoInClientes;
        break;
      case 'areas':
        res=this.config.permisoInAreas;
        break;
      case 'restaurant':
        res=this.config.permisoInRestaurante;
        break;

      default:
        res='menu';
        break;
    }
    return res;
  }

  ngOnInit() {
    this.CheckIsLoggedIn();

  }
  async CheckIsLoggedIn() {
    let userDatos = await this.authService.GetCurrentUser();
    this.config.currentUser=await this.GetIdErp_uid(userDatos.uid);
    this.area = await this.config.CheckPermiso(this.GetKeyRoute('areas'));
    console.log("--------",this.area);

    this.mesas = await this.config.CheckPermiso(this.GetKeyRoute('mesas'));
    this.clientes = await this.config.CheckPermiso(this.GetKeyRoute('clientes'));
    this.restaurant =await this.config.CheckPermiso(this.GetKeyRoute('restaurant'));

    if(userDatos==false){
      this.router.navigate(['/login']);
    }
  }
  GetIdErp_uid(uid): Promise<Usuario> {
    return new Promise((resolve, reject) => {
      this.firebaseService.GetUser_ByUid(uid).subscribe((user) => {
        if (user) {
          resolve(user[0]);
        }
        else {
          reject(false);
        }
      })
    });
  }



}
