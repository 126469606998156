import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ModalAgregarEnviosParcialesComponent } from './modal-agregar-envios-parciales.component';

@NgModule({
  declarations: [ModalAgregarEnviosParcialesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class ModalAgregarEnviosParcialesModule { }
