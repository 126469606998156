import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.scss'],
})
export class PedidosComponent implements OnInit {

  constructor(
    
    private authService: AuthService,
    private router: Router,

  ) { }

  async CheckIsLoggedIn() {
    let userDatos = await this.authService.GetCurrentUser();
    if(userDatos==false){
      this.router.navigate(['/login']);
    }
  }


  ngOnInit() {
    
    this.CheckIsLoggedIn();
  }

}
