import { Component, Input, OnInit, NgModule } from '@angular/core';
import { ModalController, IonicModule } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-nota-producto',
  templateUrl: './modal-nota-producto.component.html',
  styleUrls: ['./modal-nota-producto.component.scss'],
})

export class ModalNotaProductoComponent implements OnInit {
  @Input("producto") producto;
  @Input("proceso") proceso;
  @Input("estatus") estatus;
  @Input("estatus") estado;

  public anotacionForm: FormGroup;
  btnGuardar = false;
  btnEditar = false;
  btnVer = false;

  constructor(
    private modalControl: ModalController,
    public formBuilder: FormBuilder,
  ) {
    this.anotacionForm = formBuilder.group({
      anotacionProducto: ['',Validators.required],
    });
   }

  ngOnInit() {
    if(this.proceso === "Agregar"){
      this.btnGuardar = true;
      this.btnEditar = false;
      this.btnVer = false;
    }
    else if(this.proceso === "Confirmar"){

      if(this.estatus == "Continuar"){
        if(this.estado != "Finalizado"){
          this.btnGuardar = false;
          this.btnEditar = true;
          this.btnVer = false;
          
        }
        else{
          this.btnGuardar = false;
          this.btnEditar = false;
          this.btnVer = true;
        }
        this.anotacionForm = this.formBuilder.group({
          anotacionProducto: [this.producto.anotacion, Validators.required],
        });
      }
      else{
        this.btnGuardar = false;
        this.btnEditar = false;
        this.btnVer = true;

        this.anotacionForm = this.formBuilder.group({
          anotacionProducto: [this.producto.anotacion, Validators.required],
        });
      }
    }
  }

  closeModal(){
    this.modalControl.dismiss("", "Cerrar");
  }

  guardarNota(){
    this.modalControl.dismiss(this.anotacionForm.controls.anotacionProducto.value, "Continuar");
  }

  eliminarNota(){
    this.modalControl.dismiss("", "Eliminar");
  }
}
