import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Cliente, FirebaseDataService, Configuracion, Orden, Pedido, DatosPago, MovementUsers, PermisosTipoUsuario } from 'src/services/firebase-data.service';
import { ModalVerOrdenComponent } from '../modal-ver-orden/modal-ver-orden.component';
import { ModalController } from '@ionic/angular';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ConfigService } from 'src/services/config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first, map } from 'rxjs/operators';
import { log } from 'console';
import { resolve } from 'dns';
import firebase, { database } from 'firebase';
import { async } from '@angular/core/testing';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import moment from 'moment';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';
import { AlertsService } from 'src/services/alerts.service';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.scss'],
})
export class ConfiguracionComponent implements OnInit {
  clienteSelect:boolean;
  customAlertOptions: any = {
    width:'1000 px',
  }

  listPermisos :any;

  // { key: 'VerOrden',val:'Ver Orden', isChecked: true },
  // { key: 'AgregarOrden',val:'Agregar Orden', isChecked: true },
  // { key: 'Envio',val:'Enviar Orden', isChecked: true },

  public clienteForm: FormGroup;
  public bancoForm: FormGroup;
  public busquedaFormBanco: FormGroup;
  permisosSelected:any;
  public productoForm: FormGroup;
  public productoAgrupadoForm: FormGroup;

  public busquedaForm: FormGroup;
  // public busquedaForm: FormGroup;

  public filtroForm= this.formBuilder.group({
    fechaInicio:['', [Validators.required]],
    fechaFinal: ['', [Validators.required]]
  });
  public tipoForm: FormGroup;

  useClienteNombre:boolean;
  cliente_nombre:string;




  token = "R3$taurAnt3";
  cuentas: any [];
  protocol: any;
  pedidos:any;
  totalCuenta: any;
  comensales: any;
  productos: any;
  comensalesDivididos:any [];
  pagos: any [];
  clienteSeleccionado: any;
  bancoSeleccionado: any;
  dirSeleccionado:any;
  tipoSeleccionado: any;
  encontrado: boolean;
  public observableCuentas: Observable<Orden[]>;
  public observablePago: Observable<any[]>;
  today:any;
  publicoGeneral:any;
  banco:any;

  publicoExtranjero:any;
  productoPropina:any;
  productoAgrupado:any;
  idConfig:any;
  idPermisos:any;



  hayOrdenes:boolean;

  clientes: any[];
  bancos: any[];


  bancoID: any;

  customSelectOptions: any = {
    header: 'Clientes del sistema',
    cssClass: 'select-custom-clientes',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar2',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Guardar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };
  customSelectOptionsProd: any = {
    header: 'Selecciona producto como propina',
    cssClass: 'select-custom-clientes',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Guardar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };
  customSelectOptionsProdAgrupado: any = {
    header: 'Selecciona producto Agrupado',
    cssClass: 'select-custom-clientes',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Guardar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };

  customSelectOptionsBancos: any = {
    header: 'Selecciona el Banco',
    cssClass: 'select-custom-clientes',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Guardar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };

  customSelectOptions2: any = {
    header: 'Tipo de venta',
    cssClass: 'select-custom-clientes',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Aceptar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };
  userDatos: any;
  response: any;
  listToShow: any;
  listPermisosAdmin: any;
  listPermisosMesero: any;
  listPermisosCajero: any;
  permisosConfigPermisos: boolean;
  permisosConfigClientes: boolean;
  permisosConfigBancos: boolean;
  permisosConfigPropina: boolean;
  permisosConfigAgrupador: boolean;
  // listPermisos: { val: string; isChecked: boolean; }[];
  constructor(

    private authService: AuthService,
    private router: Router,

    public formBuilder: FormBuilder,
    public config: ConfigService,
    public firebaseService: FirebaseDataService,
    private modalController : ModalController,
    private http: HttpClient,
    private alert: AlertsService,

  ) {
    this.protocol = config.protocol;
    this.cuentas = [];
    this.productos = null;
    this.pagos = [];
    this.bancoID= "";
    this.encontrado=false;
    this.clienteSeleccionado="";
    this.bancoSeleccionado="";
    this.hayOrdenes=true;
    this.publicoGeneral=null;
    this.publicoExtranjero=null;
    this.productoPropina=null;
    this.productoAgrupado =null;
    this.permisosSelected='admin';
    this.listPermisos={admin:[],mesero:[],cajero:[]}
    this.idConfig=null;




    this.observableCuentas = this.firebaseService.getOrdenesCerradas();


    this.observableCuentas.forEach(element => {
      console.log("Cuentas cerradas: " , element);
    })
    /*this.filtroForm = formBuilder.group({
      fechaInicio:['', [Validators.required]],
      fechaFinal: ['', [Validators.required]]
    })*/

    this.clienteForm = formBuilder.group({
      cliente: ['', [Validators.required]],
    });

    this.bancoForm = formBuilder.group({
      banco: ['', [Validators.required]],
    });
    this.productoForm = formBuilder.group({
      producto: ['', [Validators.required]],
    });
    this.productoAgrupadoForm = formBuilder.group({
      producto: ['', [Validators.required]],
    });
    this.tipoForm = formBuilder.group({
      tipo: ["", [Validators.required]],
    });
    this.busquedaForm = formBuilder.group({
      nombreCliente: ['', [Validators.required]],
      nombreBanco: ['', [Validators.required]],
      nombreProducto: ['', [Validators.required]],
      productoAgrupado: ['', [Validators.required]],
    });

    this.today = moment().format('YYYY-MM-DD');
    //this.observableCuentas = this.firebaseService.GetOrdenesCerradasByDate(this.today + " 00:00:00", this.today + " 23:59:59");

  }
  async CheckIsLoggedIn() {
    if(!this.userDatos){
      this.router.navigate(['/login']);
    }
  }
  ChangeStatusPermiso(index){
    const key=this.permisosSelected;
    this.listPermisos[key][index].isChecked=!this.listPermisos[key][index].isChecked;

  }

  ChangeListPermisos(key){
    this.permisosSelected=key;
  }



  async ngOnInit() {

    this.GetBancos();
    this.permisosSelected='admin';
    setTimeout(() => {
      this.GetPermisos();
      this.CheckPermiso();
    },3000);

    this.userDatos = await this.authService.GetCurrentUser();

    this.CheckIsLoggedIn();
    this.clienteSelect=true;
    this.filtroForm.controls.fechaInicio.setValue(this.today);
    this.filtroForm.controls.fechaFinal.setValue(this.today);
    this.tipoForm.controls.tipo.setValue(1);
    this.tipoSeleccionado = 1;
    console.log("date",this.today);


    this.observableCuentas = this.firebaseService.GetOrdenesCerradasByDate(this.today + " 00:00:00", this.today + " 23:59:59");
    await this.firebaseService.GetConfiguracion().subscribe(el=>{
      this.publicoGeneral=el[0]?.publicoGeneral;
      this.publicoExtranjero=el[0]?.publicoExtranjero;
      this.productoPropina=el[0]?.productoPropina;
      this.productoAgrupado=el[0]?.productoAgrupado;
      this.banco=el[0]?.banco;

      this.idConfig=el[0]?.id;
    console.log("publicoGeneral: ", this.publicoGeneral);
    console.log("publicoExtranjero: ", this.publicoExtranjero);


    });
  }

  async UpdatePermisos(){
    console.log('actualizar');

    let permisos:PermisosTipoUsuario;
    permisos={
      admin:this.listPermisos['admin'],
      mesero:this.listPermisos['mesero'],
      cajero:this.listPermisos['cajero']
    }as PermisosTipoUsuario;
    if (this.idPermisos) {
      permisos.id =this.idPermisos;
      console.log('actualizo ',permisos);

      await this.firebaseService.UpdatePermisosTipoUsuario(permisos);
    }else{
      console.log('creoooo ',permisos);
      await this.firebaseService.AddPermisosTipoUsuario(permisos);

    }
    //alert("Guardado exitosamente!");
    this.alert.presentToast('Guardado Exitoso')
  }
  DisplayData(date){
    return moment(date).format('YYYY-MM-DD')
  }
  async ClienteChange(e){console.log("CLICKED",e);
    let type = e.detail.value;
    let config = await this.firebaseService.GetConfiguracion();
    console.log(config);

    if(type=="cliente"){
      this.clienteSelect = true;
      this.encontrado=false;
      this.clienteSeleccionado="";
      this.useClienteNombre=false;
    }
    if(type=="general"){
      this.encontrado=true;
      this.clienteSelect = false;
      this.tipoSeleccionado=1;
      this.useClienteNombre=true;
      config.subscribe(data=>{
        console.log("GENERAL",data);
        this.clienteSeleccionado=data[0].publicoGeneral;
        this.dirSeleccionado = this.clienteSeleccionado.direcciones[0];
      });
    }
    if(type=="extranjero"){
      this.tipoSeleccionado=1;
      this.clienteSelect = false;
      this.encontrado=true;
      this.useClienteNombre=true;
      config.subscribe(data=>{
        console.log("GENERAL",data);
        this.clienteSeleccionado=data[0].publicoExtranjero;
        this.dirSeleccionado = this.clienteSeleccionado.direcciones[0];
      });
    }
  }

  async GetClientes(): Promise<any>{
    try{
      console.log(this.busquedaForm.controls.nombreCliente.value);
      const config: AxiosRequestConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data',
        },
    }
    //http://erp.konsultor.mx/get-clientes?token=${this.token}
    //http://erp.konsultor.mx/get-clientes?token=${this.token}

    const url = this.config.Api(`api/get-clientes?token=${this.config.tokenApi}&busqueda=${this.busquedaForm.controls.nombreCliente.value}`);
    const response = await axios.get(url, config);
    console.log(response.data.data);
    this.clientes = response.data.data;

    return response.data.data;

    }
    catch(err)
    {
      console.log(err);
    }
  }


  async GetPermisos(){
    console.log('Permisos config ',this.config.myListPermisos);
    console.log('currentUser config ',this.config.currentUser);


    if (this.config.myListPermisos?.id) {
      console.log('tienes ya guardados');

      this.listPermisos= this.config.myListPermisos;
      const myKeysAdmin= this.listPermisos['admin'].map(permiso=>permiso.key);
      const myKeysMesero= this.listPermisos['mesero'].map(permiso=>permiso.key);
      const myKeysCajero= this.listPermisos['cajero'].map(permiso=>permiso.key);
      this.config.newListPermisos.forEach(object =>{
        if(!myKeysAdmin.includes(object.key)){
          console.log('Se agrego admin ',object.key);
          this.listPermisos['admin'].push(object);
        }
        if(!myKeysMesero.includes(object.key)){
          console.log('Se agrego mesero ',object.key);
          this.listPermisos['mesero'].push(object);
        }
        if(!myKeysCajero.includes(object.key)){
          console.log('Se agrego a cajero ',object.key);
          this.listPermisos['cajero'].push(object);
        }
    });
      // const dontHaveAdmin=this.listPermisos['admin'].filter(item =>)
      this.idPermisos=this.config.myListPermisos.id;
    }else{

      this.listPermisos.admin= JSON.parse(JSON.stringify(this.config.newListPermisos));
      this.listPermisos.mesero=  JSON.parse(JSON.stringify(this.config.newListPermisos));
      this.listPermisos.cajero=  JSON.parse(JSON.stringify(this.config.newListPermisos));
      console.log('nuesooooooos');

    }

    // try {
    //   await this.firebaseService.GetPermisosTipoUsuarios().subscribe(data=>{
    //   const {admin,mesero,cajero,id}=data[0];
    //   this.permisosAdmin=admin;
    //   this.idPermisos=id;
    //   this.permisosMesero=mesero;
    //   this.permisosCajero=cajero;
    //   this.listPermisos={
    //     admin:admin,
    //     mesero:mesero,
    //     cajero:cajero
    //   }

    // });

    // } catch (error) {

    // }
  }
  async GetBancos(): Promise<any>{
    try{
      console.log(this.busquedaForm.controls.nombreBanco.value);
      const config: AxiosRequestConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data',
        },
    }
    //http://erp.konsultor.mx/get-clientes?token=${this.token}
    //http://erp.konsultor.mx/get-clientes?token=${this.token}

    const url = this.config.Api(`api/get-bancos?token=${this.config.tokenApi}&busqueda=${this.busquedaForm.controls.nombreBanco.value}`);
    const response = await axios.get(url, config);
    this.bancos = response.data;
    console.log('Mis bancos',this.bancos);

    return response.data;

    }
    catch(err)
    {
      console.log(err);
    }
  }


  async GetProductos(): Promise<any>{
    try{
      console.log(this.busquedaForm.controls.nombreProducto.value);
      const config: AxiosRequestConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data',
        },
      };
      //const url = this.config.Api(`api/get-productos?token=${this.config.tokenApi}&sucursal=1&sis_id=2&busqueda=${this.busquedaForm.controls.nombreProducto.value}`);
      const url = this.config.Api(`api/get-productos?token=${this.config.tokenApi}&sucursal=1&sis_id=${this.config.obtenerDatosSis}&busqueda=${this.busquedaForm.controls.nombreProducto.value}`);

      //const url = "http://erp.konsultor.mx/api/get-productos?token=Gr4ndPr1x$&sucursal=1&sis_id=2&busqueda=pro";
      const response = await axios.get(url, config);
      const llaves=Object.keys(response.data);
      let misProductos=[];
       llaves.forEach(element => {
      const almacen = response.data[element];
        almacen.productos.forEach(valores => {
            misProductos.push(valores);
        });

    })
       this.productos = misProductos;

      }
      catch(err)
      {
        console.log(err);
      }
  }

  async GetProductoAgrupado(): Promise<any>{
    try{
      console.log(this.busquedaForm.controls.productoAgrupado.value);
      const config: AxiosRequestConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data',
        },
      };
      //const url = this.config.Api(`api/get-productos?token=${this.config.tokenApi}&sucursal=1&sis_id=2&busqueda=${this.busquedaForm.controls.nombreProducto.value}`);
      const url = this.config.Api(`api/get-productos?token=${this.config.tokenApi}&sucursal=1&sis_id=${this.config.obtenerDatosSis}&busqueda=${this.busquedaForm.controls.productoAgrupado.value}`);

      //const url = "http://erp.konsultor.mx/api/get-productos?token=Gr4ndPr1x$&sucursal=1&sis_id=2&busqueda=pro";
      const response = await axios.get(url, config);
      const llaves=Object.keys(response.data);
      let misProductos=[];
       llaves.forEach(element => {
      const almacen = response.data[element];
        almacen.productos.forEach(valores => {
            misProductos.push(valores);
        });

    })
       this.productos = misProductos;

      }
      catch(err)
      {
        console.log(err);
      }
  }

  async changeNombreCliente(event)
  {
    const value = event.detail.value;
    this.busquedaForm.controls.nombreCliente.setValue(value);

  }
  async changeNombreBanco(event)
  {
    const value = event.detail.value;
    this.busquedaForm.controls.nombreBanco.setValue(value);

  }
  async changeNombreProductoAgrupado(event)
  {
    const value = event.detail.value;
    this.busquedaForm.controls.productoAgrupado.setValue(value);

  }

  async changeNombreProducto(event)
  {
    const value = event.detail.value;
    this.busquedaForm.controls.nombreProducto.setValue(value);

  }
  async changeNombre_cliente(event){
    const value = event.detail.value;
    this.cliente_nombre=value;
  }

  async changeFechaInicio(event)
  {
    const value = event.detail.value;
    console.log(event.detail.value)
    this.filtroForm.controls.fechaInicio.setValue(value);

  }

  async changeFechaFinal(event)
  {
    const value = event.detail.value;
    this.filtroForm.controls.fechaFinal.setValue(value);

  }
  CheckPermiso(){
    this.permisosConfigClientes=this.config.CheckPermiso(this.config.permisosConfigClientes);
    this.permisosConfigBancos=this.config.CheckPermiso(this.config.permisosConfigBancos);
    this.permisosConfigPropina=this.config.CheckPermiso(this.config.permisosConfigPropina);
    this.permisosConfigAgrupador=this.config.CheckPermiso(this.config.permisosConfigAgrupador);
    this.permisosConfigPermisos=this.config.CheckPermiso(this.config.permisosConfigPermisos);

  }
  async changeClienteGeneral(event)
  {
    // if (!this.config.CheckPermiso(this.config.permisosConfigClientes)) {
    //   return;
    // }
    const value = event.detail.value;

    let opt :Configuracion;
    opt =
    {
      id:this.idConfig,
      publicoGeneral:value,
      publicoExtranjero:this.publicoExtranjero,
      productoAgrupado:this.productoAgrupado,
      productoPropina:this.productoPropina
    } as Configuracion;

    await this.firebaseService.UpdateConfiguracion(opt);
    const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

    let optOriginal :Configuracion;
    optOriginal =
    {
      id:this.idConfig,
      publicoGeneral:this.publicoGeneral,
      publicoExtranjero:this.publicoExtranjero,
      productoAgrupado:this.productoAgrupado,
      productoPropina:this.productoPropina

    } as Configuracion;
    let ordeData: MovementUsers;
    ordeData = {
      tipo: "configuracion",
      id_objeto: this.idConfig,
      id_usuario: this.userDatos.uid,
      accion: "actualizar",
      movimiento: "Actualizar cliente general",
      original:optOriginal,
      nuevo: opt,
      fecha: timeStamp_cer
    }
    await this.firebaseService.AddMovementUsers(ordeData);
      alert("Guardado exitosamente!");


  }
  async changeBanco(event){
    // alert("Aun se esta trabajndo en este proceso");
    // return;
    // if (!this.config.CheckPermiso(this.config.permisosConfigBancos)) {
    //   return;
    // }
    const value = event.detail.value;


    let opt :Configuracion;
    opt =
    {
      id:this.idConfig,
      banco:value,
      publicoGeneral:this.publicoGeneral,
      publicoExtranjero:this.publicoExtranjero,
      productoAgrupado:this.productoAgrupado,
      productoPropina:this.productoPropina
    } as Configuracion;

    await this.firebaseService.UpdateConfiguracion(opt);

    let optOriginal :Configuracion;
    optOriginal =
    {
      banco:this.banco,
      id:this.idConfig,
      publicoGeneral:this.publicoGeneral,
      productoAgrupado:this.productoAgrupado,
      publicoExtranjero:this.publicoExtranjero,
      productoPropina:this.productoPropina

    } as Configuracion;
    let ordeData: MovementUsers;
    const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

    ordeData = {
      tipo: "configuracion",
      id_objeto: this.idConfig,
      id_usuario: this.userDatos.uid,
      accion: "actualizar",
      movimiento: "Actualizar banco",
      original:optOriginal,
      nuevo: opt,
      fecha: timeStamp_cer

    }
    await this.firebaseService.AddMovementUsers(ordeData);
      alert("Guardado exitosamente!");
  }


  async changeClienteExtrangero(event)
  {
    const value = event.detail.value;
    let opt :Configuracion;
    opt =
    {
      id:this.idConfig,
      publicoGeneral:this.publicoGeneral,
      publicoExtranjero:value,
      productoPropina:this.productoPropina,
      productoAgrupado:this.productoAgrupado,

    } as Configuracion;
    console.log("eselect Extranger ",opt);
    await this.firebaseService.UpdateConfiguracion(opt);


    let optOriginal :Configuracion;
    optOriginal =
    {
      id:this.idConfig,
      publicoGeneral:this.publicoGeneral,
      publicoExtranjero:this.publicoExtranjero,
      productoAgrupado:this.productoAgrupado,
      productoPropina:this.productoPropina

    } as Configuracion;
    let ordeData: MovementUsers;
    const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

    ordeData = {
      tipo: "configuracion",
      id_objeto: this.idConfig,
      id_usuario: this.userDatos.uid,
      accion: "actualizar",
      movimiento: "Actualizar cliente extranjero",
      original:optOriginal,
      nuevo: opt,
      fecha: timeStamp_cer

    }
    await this.firebaseService.AddMovementUsers(ordeData);





      alert("Guardado exitosamente!");


  }
  async changeClienteProducto(event)
  {
    // if (!this.config.CheckPermiso(this.config.permisosConfigPropina)) {
    //   return;
    // }
    const value = event.detail.value;
    const newProducto=this.productos.filter(p => p.descripcion === value.substring(8,value.length-1))[0];
    console.log("busco ",value.substring(8,value.length-1));
    console.log("mando ",newProducto);
    let opt :Configuracion;
    opt =
    {
      id:this.idConfig,
      publicoGeneral:this.publicoGeneral,
      publicoExtranjero:this.publicoExtranjero,
      productoAgrupado:this.productoAgrupado,
      productoPropina:newProducto

    } as Configuracion;
    console.log("changeClienteProducto productos ",this.productos);
    await this.firebaseService.UpdateConfiguracion(opt);
    let optOriginal :Configuracion;
    optOriginal =
    {
      id:this.idConfig,
      publicoGeneral:this.publicoGeneral,
      publicoExtranjero:this.publicoExtranjero,
      productoAgrupado:this.productoAgrupado,
      productoPropina:this.productoPropina

    } as Configuracion;
    let ordeData: MovementUsers;
    const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

    ordeData = {
      tipo: "configuracion",
      id_objeto: this.idConfig,
      id_usuario: this.userDatos.uid,
      accion: "actualizar",
      movimiento: "Actualizar propina",
      original:optOriginal,
      nuevo: opt,
      fecha: timeStamp_cer

    }
    await this.firebaseService.AddMovementUsers(ordeData);
      alert("Guardado exitosamente!");
  }
  async changeProductoAgrupado(event)
  {
    // if (!this.config.CheckPermiso(this.config.permisosConfigAgrupador)) {
    //   return;
    // }
    const value = event.detail.value;
    const newProducto=this.productos.filter(p => p.descripcion === value.substring(8,value.length-1))[0];
    console.log("busco ",value.substring(8,value.length-1));
    console.log("mando ",newProducto);

    let opt :Configuracion;
    opt =
    {
      id:this.idConfig,
      publicoGeneral:this.publicoGeneral,
      publicoExtranjero:this.publicoExtranjero,
      productoPropina:this.productoPropina,
      productoAgrupado:newProducto

    } as Configuracion;
    console.log("changeClienteProducto productos ",this.productos);
    await this.firebaseService.UpdateConfiguracion(opt);
    let optOriginal :Configuracion;
    optOriginal =
    {
      id:this.idConfig,
      publicoGeneral:this.publicoGeneral,
      publicoExtranjero:this.publicoExtranjero,
      productoPropina:this.productoPropina,
      productoAgrupado:this.productoAgrupado

    } as Configuracion;
    let ordeData: MovementUsers;
    const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

    ordeData = {
      tipo: "configuracion",
      id_objeto: this.idConfig,
      id_usuario: this.userDatos.uid,
      accion: "actualizar",
      movimiento: "Actualizar agregar producto agrupado",
      original:optOriginal,
      nuevo: opt,
      fecha: timeStamp_cer

    }
    await this.firebaseService.AddMovementUsers(ordeData);
      alert("Guardado exitosamente!");
  }





  async changeDir(event)
  {
    const value = event.detail.value;
    this.dirSeleccionado=value;
  }
  async changeTipoVenta(event)
  {
    const value = event.detail.value;
    this.tipoSeleccionado=value;
    console.log(value);
  }

  async filtrar()
  {
    console.log("FECHA INICIO", this.filtroForm.controls.fechaInicio.value, "FECHA FINAL", this.filtroForm.controls.fechaFinal.value)
    this.observableCuentas = this.firebaseService.GetOrdenesCerradasByDate(this.filtroForm.controls.fechaInicio.value + " 00:00:00", this.filtroForm.controls.fechaFinal.value + " 23:59:59");
  }
  async presentModal(orden) {
    const modal = await this.modalController.create({
      component: ModalVerOrdenComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"verOrdenClass",
      componentProps:{orden:orden}
    });
    return await modal.present();
  }

  FormatNumber(number){
    let parts = number.toString().split(".")

    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return num
  }
  showNombre(item){
    if(item.tipo_persona=="Física"){
      return item.nombre+' '+item.apellidos
    }else{
      return item.razon_social
    }
  }

}

