import { AuthService } from 'src/services/auth.service';
import { Subscription } from 'rxjs';
import { ModalDeleteProductoResumenComponent } from './../modal-delete-producto-resumen/modal-delete-producto-resumen.component';
import { ModalNotaProductoComponent } from '../modal-nota-producto/modal-nota-producto.component';
import { Pedido, productosArea } from './../../services/firebase-data.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden, comandasPreparacion, comandasPreparacionBarra, UserAdmin } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ConfigService } from 'src/services/config.service';

import { ModalController} from '@ionic/angular';
import { Observable } from 'rxjs';
import { expand, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-modal-resumen-orden',
  templateUrl: './modal-resumen-orden.component.html',
  styleUrls: ['./modal-resumen-orden.component.scss'],
})
export class ModalResumenOrdenComponent implements OnInit {

  @Input("productos") productos;
  @Input("orden") orden;

  ordenID: any;

  totalPedido: any;

  comensales: any;

  prodSub: Subscription;

  userUID:string;
  userNombre: any;
  mesasOrden = "";

  prods:any;
  isOnline: boolean;
  isMessageVisible = false;
  listo = true

  public observableProductoArea: Observable<productosArea[]>;

  constructor(
    private auth: AuthService,
    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController,
    private modalControl2: ModalController,
    private config: ConfigService
  ) { }

  async ngOnInit() {
    this.config.createOnline$().subscribe(async isOnline => {
      this.isOnline = isOnline;
      // console.log('internet is online: ', this.isOnline);

      // console.log('isMessageVisible: ', this.isMessageVisible);
      if (this.isOnline == false) {
        if (this.isMessageVisible == false) {
          this.isMessageVisible = true;

          // const timeStamp = moment().format('YYYY-MM-DD HH:mm:ss') + ":" + moment().millisecond();
          // let sys_log: Sys_log = {
          //   nombre: "Internet Offline",
          //   fecha: timeStamp,
          //   usu_id: '',
          //   datos_nuevos: [],
          //   datos_viejos: [],
          //   movimiento: "Internet Offline",
          //   idReservacion: ''
          // };
          // console.log('Sys log status internet: ', sys_log);
          console.log('Sin internet');
          // await this.firebaseService.AddSys_log(sys_log);
          // await this.alertService.presentLoadingInternet('Verifique su conexion a internet por favor', 10, 'I');
        }
      } else {
        // this.alertService.closeLoadingInternet('I');
      }
    });

    this.ordenID = this.orden.id
    this.prods = this.productos;
    this.comensales =this.orden.comensales;

    this.totalPedido = 0;
    this.comensales.forEach(element => {
      element.total = 0;
    });
    this.productos.forEach(element => {
        console.log("PRODUCTO ----",element,element.cantidad,element.precio,
        Number(element.cantidad),element.precio * Number(element.cantidad));

        var total = (element.precio * Number(element.cantidad));
        this.totalPedido += total;
        element.total = total;

        this.comensales.forEach(item => {

          if(element.comensal == item.nombre){

            item.total += total;
            console.log(item.total);
          }
        });
    });

    var data = await this.auth.GetCurrentUser();

    this.userUID = data.uid;
    this.userNombre = await this.WaitForUserData(this.userUID);

    for (let i = 0; i < this.orden.mesas.length; i++) {
      const mesa = this.orden.mesas[i];
      
      if(this.mesasOrden == ""){
        this.mesasOrden += mesa.nombre;
      }
      else{
        this.mesasOrden += ", " + mesa.nombre;
      }
    }

    console.log("PRODS",this.productos);
    console.log("COMENSALES",this.comensales);

    // this.prodSub = this.productos.subscribe((prods)=>{
    //   if(prods.length == 0){
    //     this.closeModal();
    //   }
    // })
  }

  WaitForUserData(uid): Promise<UserAdmin> {
    return new Promise((resolve, reject) => {
      this.firebaseService.GetUserAdmin_ByUid(uid).subscribe((user) => {
        if (user) {
          resolve(user[0]);
        }
        else {
          reject(false);
        }
      })
    });
  }

  FormatNumber(number){
    let parts = number.toString().split(".")
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return num
  }

  AwaitInternet(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      var interval = setInterval(() => {
        if (this.isOnline) {
          resolve(true);
          clearInterval(interval);
        }
      }, 600);
    });
  }

  async guardarPedido(){
    this.listo = false;
    this.totalPedido = 0;
    this.comensales.forEach(element => {
      element.total = 0;
    });
    this.productos.forEach(element => {
        var total = (element.precio * element.cantidad);
        this.totalPedido += total;
        element.total = total;

        this.comensales.forEach(item => {

          if(element.comensal == item.nombre){

            item.total += total;
            //console.log(item.total);
          }
        });
    });
    if(this.productos.length > 0){
      var cont = 0;
      try {
       let mensaje = await this.PresentLoading("Guardando Pedido", 0);

       var pedido : Pedido;

        //var date = new Date();
        const date = moment().format('YYYY-MM-DD HH:mm:ss')

       pedido = {
         estado: "Pedido",
         total_pedido: this.totalPedido,
         comensal_total: this.comensales,
         created_by: this.userUID,
         created_date: date,
         edit_date: date
       } as Pedido

       var pedidoID :any;
       var totalProds = 0;
       this.productos.forEach(element => {
          totalProds += element.precio * element.cantidad;
       });

       this.orden.totalOrden += totalProds;

       console.log("FINAL",this.orden);

      //  await this.firebaseService.updateOrden(this.orden);

       this.isOnline = await this.AwaitInternet();
       if (this.isOnline) {
         await this.firebaseService.guardarPedido(pedido,this.ordenID).then((docRef)=>{
          pedidoID = docRef.id;
  
          console.log(docRef);
          console.log("PEDIDO",pedidoID)
         });
       }

       this.productos.forEach(async element => {
           var item: ItemOrden;

           var taxValues = [];

           element.impuestos.forEach(element => {

             taxValues.push(element.value);

           });
          console.log("VALIDAR",element.nombre_producto,element.imagen_url);
           const url_img_base = 'https://firebasestorage.googleapis.com/v0/b/restaurant-test-35098.appspot.com/o/4_image-not-found.png?alt=media&token=9629b2a1-9a97-4e42-81b3-d98324595c9d'
           item ={
             id_producto:element.id_producto,
             cantidad:element.cantidad,
             nombre_producto: element.nombre_producto,
             imagen_url: typeof element.imagen_url ==='undefined'? url_img_base:element.imagen_url,
             precio: element.precio,
             comensal: element.comensal,
             estado: element.estado,
             impuestos:taxValues,
             facturable:element.facturable,
             unidad_medida:element.unidad_medida,
             anotacion:element.anotacion
           }as ItemOrden

          let info_prod = await this.AwaitProductoCocina(element.id_producto);
          console.log(info_prod);
          let estatus = "Espera";
          const date = moment().format('YYYY-MM-DD HH:mm:ss')
          let fechas = "";
          if(info_prod[0]["area_id"] == "2"){
            var productosPreparacionBarra: comandasPreparacionBarra;
             productosPreparacionBarra = {
              id_producto:element.id_producto,
              cantidad:element.cantidad,
              nombre_producto: element.nombre_producto,
              comensal: element.comensal,
              precio: element.precio,
              estado: estatus,
              anotacion:element.anotacion,
              fecha_inicio_preparacion: fechas,
              fecha_final_preparacion: fechas,
              fecha_agregado: date,
              folio_orden: this.orden.folio,
              orden_id: this.ordenID,
              pedido_id: pedidoID,
              created_by: this.userNombre.uid,
              edited_by: this.userNombre.uid,
              mesero_name: this.userNombre.name,
              mesas: this.mesasOrden
             } as comandasPreparacionBarra
          }
          else{
            var productosPreparacion: comandasPreparacion;
             productosPreparacion ={
              id_producto:element.id_producto,
              cantidad:element.cantidad,
              nombre_producto: element.nombre_producto,
              comensal: element.comensal,
              precio: element.precio,
              estado: estatus,
              anotacion:element.anotacion,
              fecha_inicio_preparacion: fechas,
              fecha_final_preparacion: fechas,
              fecha_agregado: date,
              folio_orden: this.orden.folio,
              orden_id: this.ordenID,
              pedido_id: pedidoID,
              created_by: this.userNombre.uid,
              edited_by: this.userNombre.uid,
              mesero_name: this.userNombre.name,
              mesas: this.mesasOrden
             } as comandasPreparacion
          }

           this.isOnline = await this.AwaitInternet();
           if (this.isOnline) {
             var upload = await this.firebaseService.addItemOrden(item, this.ordenID,pedidoID);
           }

           if(info_prod[0]["area_id"] == "2"){
              console.log("guardare: ", productosPreparacionBarra);
             this.isOnline = await this.AwaitInternet();
             if (this.isOnline) {
               var uproduct = await this.firebaseService.addcomandasPreparacionBarra(productosPreparacionBarra);
             }
           }
           else{
            this.isOnline = await this.AwaitInternet();
             if (this.isOnline) {
               var uproduct = await this.firebaseService.addcomandasPreparacion(productosPreparacion);
             }
           }

           if(cont >= this.productos.length-1){
             this.isOnline = await this.AwaitInternet();
             if (this.isOnline) {
               await this.firebaseService.updateOrden(this.orden);
             }

            setTimeout(()=>{
              this.modalControl.dismiss();
              mensaje.dismiss();

            this.alertService.actualizarListaAgregarProductosOrden.next();
            console.log("SUBMIT",this.productos);
             this.router.navigate(['/restaurant' ]).then(()=>{
               window.location.reload();
              });
            },500)

           }

           cont++;
       });
      } catch (error) {
        console.log(error);
         this.DismissLoading();
      }

    }else{
      this.closeModal();
    }

   }

   async showNota(prod, i){
    const modal = await this.modalControl2.create({
      component: ModalNotaProductoComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      componentProps:{producto: prod, proceso: "Confirmar", estatus: "Continuar", estado: "Continuar"},
      cssClass:"aceptarComandaModal"
    });
    
    await modal.present();

    let proceso;
    await modal.onDidDismiss().then((data) => {
      proceso = data;
    })

    if(proceso.role != "Cerrar" && proceso.role != "backdrop"){
      prod.anotacion = proceso.data;
    }
   }

   async deleteItem(prod,i){
    const modal = await this.modalControl2.create({
      component: ModalDeleteProductoResumenComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"my-custom-modal-css",
      componentProps:{productos:this.productos, indexProd:i, mensajeConfirmacion:"Desea borrar "+prod.nombre_producto+"?", data:{
        tipo:'none'
      }}
    });

      modal.onDidDismiss().then((data) => {

        if(this.productos.length == 0 ){
          console.log("DATA",this.productos);
          this.closeModal();
        }
      })
    return await modal.present();
   }

   closeModal(){
    this.modalControl.dismiss();
  }

  comensalChange(event,prod,i){
    var value = event.detail.value;
    console.log(value);

    var foundFlag = false;

    var counter = 0;

    this.productos.forEach(element => {
      if(i != counter){
        if(element.comensal == value && element.nombre_producto==prod.nombre_producto){
          element.cantidad = parseFloat(element.cantidad) + parseFloat(prod.cantidad);
          this.productos.splice(i,1);
          foundFlag = true;
        }
      }
      counter ++;
    });
    if(!foundFlag)
      prod.comensal= value;
  }

  cantidadChange(event,prod){
    var value = event.detail.value;
    prod.cantidad = value;

    prod.total = prod.cantidad * prod.precio;
    console.log(value);
  }

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }

  async AwaitProductoCocina(id_producto: string): Promise<productosArea[]> {
    return new Promise((resolve, reject) => {
      this.observableProductoArea= this.firebaseService.getProductoArea(id_producto);
  
      this.observableProductoArea
      .pipe(
        map(items =>
          items.filter(item =>
            item.estatus == "Activo"
          )
        )
      )
      .subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }
}
