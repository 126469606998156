import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ConfigService } from 'src/services/config.service';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Router } from '@angular/router';
import { AuthService } from 'src/services/auth.service';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss'],
})
export class ClientesComponent implements OnInit {

  token = "R3$taurAnt3";
  protocol: any;

  clienteForm = new FormGroup({
    tipoPersona: new FormControl(''),
    razonSocial: new FormControl(''),
    nombre : new FormControl(''),
    apellidos : new FormControl(''),
    rfc : new FormControl(''),
    telefono : new FormControl(''),
    email : new FormControl(''),
    calle : new FormControl(''),
    cp : new FormControl(''),
    fraccionamiento : new FormControl(''),
    numero : new FormControl(''),
    vialidadIzquierda : new FormControl(''),
    vialidadDerecha : new FormControl(''),
    vialidadPosterior : new FormControl(''),
    referencias : new FormControl(''),
  });

  colonias: any [];
  entidad: any [];
  clientes: any[];
  ent_id: string;
  mun_id: string;
  loc_id; sring;
  moral:boolean;
  constructor(
    
    private authService: AuthService,
    private router: Router,

    public config: ConfigService,
    private http: HttpClient
  ) {
    this.protocol = config.protocol;
    this.colonias= [

    ];
    this.entidad = [];
    this.clientes = [];
  }

  async CheckIsLoggedIn() {
    let userDatos = await this.authService.GetCurrentUser();
    if(userDatos==false){
      this.router.navigate(['/login']);
    }
  }


  ngOnInit() {
    
    this.CheckIsLoggedIn();
    this.GetClientes();
  }

  async GetClientes(): Promise<any>{
    try{
      const config: AxiosRequestConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data',
        },
    }
    //http://erp.konsultor.mx/get-clientes?token=${this.token}
    //http://prueba.konsultor.mx/get-clientes?token=${this.token}
    const url = this.config.Api('api/get-clientes?token='+this.config.tokenApi) 
    const response = await axios.get(url, config);
    console.log(response.data.data);
    this.clientes = response.data.data;
    return response.data.data;

    }
    catch(err)
    {
      console.log(err);
    }
  }
  async changeCp(event)
  {
    try{
      const config: AxiosRequestConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data'
        },
    }
    // const url= `${this.protocol}://erp.konsultor.mx/get-direccion-cp?token=${this.token}&cp=${this.clienteForm.controls.cp.value}`;
    const url= `${this.protocol}://erp.konsultor.com.mx/get-direccion-cp?token=${this.token}&cp=${this.clienteForm.controls.cp.value}`;
    const response = await axios.get(url, config);
    this.colonias = response.data;
    this.entidad = response.data.entidad;
    this.ent_id = response.data.entidad.ent_id;
    this.mun_id = response.data.entidad.mun_id;
    this.loc_id = response.data.entidad.loc_id;

    console.log(this.entidad)
    }
    catch(err)
    {
      console.log(err)
    }
  }

  async changeFraccionamiento(event)
  {
    const value = event.detail.value;

    this.clienteForm.controls.fraccionamiento.setValue(value);

    console.log(value);
  }

  async changeTipoPersona(event){
    const value = event.detail.value;
    this.clienteForm.controls.tipoPersona.setValue(value);
    if(value== "Moral")
    {
      this.moral=true;
    }
    if(value== "Física")
    {
      this.moral=false;
    }
  }
  async guardarCliente(): Promise<any>
  {
    //Enviar cliente si es moral
    if(this.moral==true)
    {
      try{
        const httpOptions = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin':'*',
            'Authorization':'authkey',
            'userid':'1'
          })
        };

        const url = `/posts/save-cliente?token=${this.token}`;
        const response = await this.http.post(url,
          {
            "rfc": ""+this.clienteForm.controls.rfc.value,
            "telefono": ""+this.clienteForm.controls.telefono.value,
            "email":""+ this.clienteForm.controls.email.value,
            "tipo_persona":""+"Moral",
            "razon_social":""+this.clienteForm.controls.razonSocial.value,
            "direcciones":[
              {
                "cli_id":""+1,
                "td_id":""+1,
                "dir_calle":""+this.clienteForm.controls.calle.value,
                "dir_no":""+this.clienteForm.controls.numero.value,
                "dir_cp":""+this.clienteForm.controls.cp.value,
                "col_id":""+this.clienteForm.controls.fraccionamiento.value,
                "ent_id":""+this.ent_id,
                "mun_id":""+this.mun_id,
                "loc_id":""+this.loc_id,
                "dir_latitud":0,
                "dir_longitud":0,
                "dir_vialidad_izq":""+this.clienteForm.controls.vialidadIzquierda.value,
                "dir_vialidad_der":""+this.clienteForm.controls.vialidadDerecha.value,
                "dir_vialidad_post":""+this.clienteForm.controls.vialidadPosterior.value,
                "dir_referencias":""+this.clienteForm.controls.referencias.value,
              }
            ]

          },httpOptions).subscribe(data => {console.log("HERE",data);
          })
        console.log("Guardado", response);
        return response;

      }
      catch(err)
      {console.log(err)}
    }
    else

    //Enviar si es fisica
    if(this.moral==false)
    {
      try{
        const httpOptions = {
          headers: new HttpHeaders({
            'Access-Control-Allow-Origin':'*',
            'Authorization':'authkey',
            'userid':'1'
          })
        };

        const url = `/posts/save-cliente?token=${this.token}`;
        const response = await this.http.post(url,
          {
            "nombre": ""+this.clienteForm.controls.nombre.value,
            "apellidos": ""+this.clienteForm.controls.apellidos.value,
            "rfc": ""+this.clienteForm.controls.rfc.value,
            "telefono": ""+this.clienteForm.controls.telefono.value,
            "tipo_persona":""+"Física",
            "email":""+ this.clienteForm.controls.email.value,
            "direcciones":[
              {
                "cli_id":""+1,
                "td_id":""+1,
                "dir_calle":""+this.clienteForm.controls.calle.value,
                "dir_no":""+this.clienteForm.controls.numero.value,
                "dir_cp":""+this.clienteForm.controls.cp.value,
                "col_id":""+this.clienteForm.controls.fraccionamiento.value,
                "ent_id":""+this.ent_id,
                "mun_id":""+this.mun_id,
                "loc_id":""+this.loc_id,
                "dir_latitud":0,
                "dir_longitud":0,
                "dir_vialidad_izq":""+this.clienteForm.controls.vialidadIzquierda.value,
                "dir_vialidad_der":""+this.clienteForm.controls.vialidadDerecha.value,
                "dir_vialidad_post":""+this.clienteForm.controls.vialidadPosterior.value,
                "dir_referencias":""+this.clienteForm.controls.referencias.value,
              }
            ]

          },httpOptions).subscribe(data => {console.log("HERE",data);
          })
        console.log("Guardado", response);
        return response;

      }
      catch(err)
      {console.log(err)}
    }


    this.clienteForm.controls.tipoPersona.setValue("");
    this.clienteForm.controls.nombre.setValue("");
    this.clienteForm.controls.apellidos.setValue("");
    this.clienteForm.controls.rfc.setValue("");
    this.clienteForm.controls.telefono.setValue("");
    this.clienteForm.controls.email.setValue("");
    this.clienteForm.controls.calle.setValue("");
    this.clienteForm.controls.cp.setValue("");
    this.clienteForm.controls.fraccionamiento.setValue("");
    this.clienteForm.controls.numero.setValue("");
    this.clienteForm.controls.vialidadIzquierda.setValue("");
    this.clienteForm.controls.vialidadDerecha.setValue("");
    this.clienteForm.controls.vialidadPosterior.setValue("");
    this.clienteForm.controls.referencias.setValue("");


  }


}
