import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthService } from 'src/services/auth.service';
import { ConfigService } from 'src/services/config.service';
import { FirebaseDataService, UserAdmin, Usuario } from 'src/services/firebase-data.service';
import { UserDataService } from 'src/services/user-data.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public Pages = [
    { title: 'Menu', url: 'menu', icon: 'mail',  allow:true},
    { title: 'Restaurant', url: 'restaurant', icon: 'restaurant', allow:true},
    { title: 'Cuentas', url: 'cuentas', icon: 'clipboard', allow:true },
    { title: 'Cuentas Enviadas', url:'enviadas', icon: 'clipboard', allow:true},
    { title: 'Reportes', url: 'reportes', icon: 'clipboard', allow:true },
    { title: 'Cocina', url: 'cocina', icon: 'restaurant', allow:true },
    { title: 'Barra', url: 'barra', icon: 'wine', allow:true },
    { title: 'Productos', url: 'productos', icon: 'fast-food', allow:true },
    { title: 'Registrar', url: 'register', icon: 'clipboard', allow:true },
    { title: 'Configuracion', url: 'configuracion', icon: 'person', allow:true},
    { title: 'Logs', url: 'logs', icon: 'person', allow:true},
    { title: 'Cambiar Contraseña', url: 'cambiar-contrasena', icon: 'person', allow:true},
    { title: 'Salir', url: 'logout', icon: 'person', allow:true}
  ];
  userName:string;
  userNombre:any;
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  userDatos: any;
  userUID: any;
  logeado:boolean;
  constructor(
    public config: ConfigService,
    private platform: Platform,
    private authService: AuthService,
    private firebaseService: FirebaseDataService,
    public userDataService: UserDataService,
    private router: Router,

  ) {

    config.LoadConfig();

  }

  async AuthPersistence() {
    await this.authService.AuthPersistence();
    const user = await this.authService.GetCurrentUser();

    if (user?.uid !== undefined) {
      this.logeado==false;
      const observable = await this.firebaseService.GetUser_ByUid(user.uid);
      const u = await this.WaitForData(observable) as Usuario;
      // console.log(u);
      if (u !== null && u !== undefined) {
        this.userDataService.SetUser(u);
      } else {
        console.log('no user found');
      }
    }
    else
    {this.logeado==true}
  }

  async Logout() {
    await this.authService.Logout();
    window.location.reload();
  }

  goToComponent(route:any){
    // if (this.config.CheckPermiso(this.GetKeyRoute(route))) {
      // }
    this.router.navigate(['/'+ route]);  // define your component where you want to go

  }

  GetKeyRoute(route: any): any {
    let res='';
    switch (route) {
      case 'menu':
        res=this.config.permisoInMenu;
        break;
      case 'restaurant':
        res=this.config.permisoInRestaurante;
        break;
      case 'cuentas':
        res=this.config.permisoInCuentas;
        break;
      case 'enviadas':
        res=this.config.permisoInCuentasEnviadas;
        break;
      case 'reportes':
        res=this.config.permisoInReportes;
        break;
      case 'cocina':
        res=this.config.permisoInCocina;
        break;
      case 'barra':
        res=this.config.permisoInBarra;
        break;
      case 'productos':
        res=this.config.permisoInProductos;
        break;
      case 'cambiar-contrasena':
        res=this.config.permisoInProductos;
        break;
      case 'register':
        res=this.config.permisoInRegistrar;
        break;
      case 'configuracion':
        res=this.config.permisoInConfiguracion;
        break;
      case 'logs':
        res=this.config.permisoInLogs;
        break;
      default:
        res=this.config.permisoInMenu;
        break;
    }
    return res;
  }


  async ngOnInit() {

    this.authService.getUser();

    //this.config.usuario=this.authService.getUser();
    console.log(this.Pages[0]);
    //TODO: Descomentar to start use permisos
    await this.firebaseService.GetPermisosTipoUsuarios()
      .subscribe(data=>{
        const {admin,mesero,cajero,id}=data[0];
        this.config.myListPermisos={
            id:id,
            admin:admin,
            mesero:mesero,
            cajero:cajero
          }

    });


    this.userDataService.appInstance = this;
    await this.AuthPersistence();
    // console.log(this.userDataService.appInstance);
    console.log('loading user');
    this.userDatos = await this.authService.GetCurrentUser();
    if(this.userDatos!=false){
      console.log("DATOS",this.userDatos);

      this.userUID = this.userDatos.uid;
      this.config.currentUser=await this.GetIdErp(this.userUID);
      // console.log('this.config.currentUser',this.config.currentUser);

      this.userNombre = await this.WaitForUserData(this.userUID);
      this.userName = this.userNombre.name;
    }else{
      this.userUID = 0;
      this.userNombre = undefined;
      this.userName = undefined;
    }

    console.log('user: ', this.userName);

    if(this.userName ==undefined)
    {
      this.Pages = [

        { title: 'Iniciar Sesion', url: 'login', icon: 'person', allow:true},

      ];
    }
    else
    if(this.userName!=undefined)
    {
      console.log("UsuarioOOO: ",this.userNombre);

      this.Pages = [
        { title: 'Menu', url: 'menu', icon: 'mail',  allow:true},
        { title: 'Restaurant', url: 'restaurant', icon: 'restaurant', allow:true},
        { title: 'Cuentas', url: 'cuentas', icon: 'clipboard', allow:true },
        { title: 'Cuentas Enviadas', url:'enviadas', icon: 'clipboard', allow:true},
        { title: 'Reportes', url: 'reportes', icon: 'clipboard', allow:true },
        { title: 'Cocina', url: 'cocina', icon: 'restaurant', allow:true },
        { title: 'Barra', url: 'barra', icon: 'wine', allow:true },
        { title: 'Productos', url: 'productos', icon: 'fast-food', allow:true },
        { title: 'Registrar', url: 'register', icon: 'clipboard', allow:true },
         { title: 'Configuracion', url: 'configuracion', icon: 'person', allow:true},
         { title: 'Logs', url: 'logs', icon: 'person', allow:true},
         { title: 'Cambiar Contraseña', url: 'cambiar-contrasena', icon: 'person', allow:true},
        { title: 'Salir', url: 'logout', icon: 'person', allow:true}

      ];
      this.Pages.forEach(item=>{
        item.allow=this.config.CheckPermiso(this.GetKeyRoute(item.url));
      });
    }

  }


  WaitForUserData(uid): Promise<UserAdmin> {
    return new Promise((resolve, reject) => {
      this.firebaseService.GetUserAdmin_ByUid(uid).subscribe((user) => {
        if (user) {
          resolve(user[0]);
        }
        else {
          reject(false);
        }
      })
    });
  }
  GetIdErp(uid): Promise<Usuario> {
    return new Promise((resolve, reject) => {
      this.firebaseService.GetUser_ByUid(uid).subscribe((user) => {
        if (user) {
          resolve(user[0]);
        }
        else {
          reject(false);
        }
      })
    });
  }
  WaitForData(observable: Observable<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      observable.subscribe(data => {
        if (data) {
          resolve(data[0]);
        } else {
          console.log('no data');
          resolve(null);
        }
      });
    });
  }
}
