import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';

import { ModalController} from '@ionic/angular';

@Component({
  selector: 'app-modal-confirm-delete',
  templateUrl: './modal-confirm-delete.component.html',
  styleUrls: ['./modal-confirm-delete.component.scss'],
})
export class ModalConfirmDeleteComponent implements OnInit {

  @Input("mensajeConfirmacion") mensajeConfirmacion;
  @Input("titulo") titulo;
  @Input("data") data;
  @Input("tipo") tipo;

  prods:any;

  constructor(
    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController
  ) { }

  ngOnInit() {

  }


  closeModal(){
    this.modalControl.dismiss();
  }

  deleteItem(){
    if(this.tipo == "Mesa"){
      this.deleteMesa();
    }else if(this.tipo == "Area"){
      this.deleteArea();
    }
    if(this.tipo == "Cobro"){
      this.deleteCobro();
    }
    if(this.tipo == "Comensal"){
      this.deleteComensal();
    }
  }

  deleteComensal(){
    this.data.comensales.removeAt(this.data.index)
    this.modalControl.dismiss();
  }

  deleteCobro(){
    this.data.cobros.splice(this.data.index,1);
    this.modalControl.dismiss();
  }

  async deleteMesa(){
    this.data.mesa.activa = false;
    await this.firebaseService.deleteMesa(this.data.mesa,this.data.area);
    this.modalControl.dismiss();
  }

  async deleteArea(){
    this.data.area.activa = false;
    await this.firebaseService.deleteArea(this.data.area);
    this.modalControl.dismiss();
}

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }
}
