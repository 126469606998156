import { toBase64String } from '@angular/compiler/src/output/source_map';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { AlertController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ConfigService } from './config.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(
    private toastController: ToastController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private config: ConfigService
  ) { }

  loading: any = null;

  // ? EXAMPLE:
  // var htmlBody = `<h1>Éste correo es para confirmarle su reservacion de X fecha</h1>`;
  // const res = await this.alertService.SendEmailPHP(htmlBody, 'Reservación Hotel GrandPrix', 'email@d.com');
  // console.log(res);
  // if (res.data == 1) {
  //   // se envio el correo
  // }

  /**
   * 
   * @param body boyd of html body
   * @param subject subject of email
   * @param email email to
   * @param img base 64 img (with image/png;base64, etc)
   * @returns 
   */
  async SendEmailPHP(body: string, subject: string, email: string, img: string = ''): Promise<AxiosResponse> {
    // console.log('axios: ', axios);

    const config: AxiosRequestConfig = {
      headers: {
        'Accept': 'application/json',
        'Content-type': 'multipart/form-data',
        // 'Access-Control-Allow-Origin': '*', 
        // 'Authorization': 'Basic ' + userPass,   // base 64 encoding
        // 'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers,Content-Type,Access-Control-Allow-Methods, Authorization, X-Requested-With'
      },
      auth: {
        username: 'y4U56ZaxvzT',
        password: 'AVB0m&npkLYFZU1lV$&8FciJiXw5^HjTZQRMgetuHP4gjtdd0gdfBeY%sp7jBUEz2Fihg@^jOYqnMeTcEMFVOBOUSxf5tsuy@kJ'
      },

    }
    console.log(config.headers);


    const formData = new FormData();
    formData.append('body', body);
    formData.append('email', email);
    formData.append('subject', subject);
    if (img !== '') {
      formData.append('img', img);
    }
    // formData.append('y4U56ZaxvzT', 'AVB0m&npkLYFZU1lV$&8FciJiXw5^HjTZQRMgetuHP4gjtdd0gdfBeY%sp7jBUEz2Fihg@^jOYqnMeTcEMFVOBOUSxf5tsuy@kJ');

    // const response = await axios.post('https://azularena.mx/reservaciones/php_service/test_img.php', formData, config);
    const response = await axios.post(this.config.emailUrl, formData, config);
    return response;
  }

  async presentToast(msg: string, time: number = 4000) {
    const toast = await this.toastController.create({
      message: msg,
      duration: time
    });
    toast.present();
  }

  // para que funcione el callback se usa de esta manera:
  // ShowConfirm() {
  //     const scope = this;
  //     this.alertService.presentAlertConfirm('Borrar?', 'Borar: ' + item.nombre, () => {
  //     scope.Delete(item);
  //    });
  // }


  async AlertConfirmTriple(headerMsg: string, msg: string): Promise<any> {
    return new Promise(async (resolve, reject) => {

      const alert = await this.alertController.create({
        header: headerMsg,
        message: msg,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('canceled');
              resolve({ accept: false, sendEmail: false });
            }
          },
          {
            text: 'Guardar y enviar Email',
            role: 'accept',
            cssClass: 'secondary',
            handler: () => {
              console.log('Accepted');
              // onConfirm();
              resolve({ accept: true, sendEmail: true });
            }
          },
          {
            text: 'Solo guardar',
            handler: () => {
              console.log('Accepted');
              // onConfirm();
              resolve({ accept: true, sendEmail: false });
            }
          }
        ],
        cssClass: 'email-preview'
      });

      await alert.present();
    });
  }

  async AlertConfirm(headerMsg: string, msg: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {

      let accept: boolean;
      const alert = await this.alertController.create({
        header: headerMsg,
        message: msg,
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              console.log('canceled');
              accept = false;
              resolve(accept);
            }
          }, {
            text: 'Aceptar',
            handler: () => {
              console.log('Accepted');
              // onConfirm();
              accept = true;
              resolve(accept);
            }
          }
        ],
        cssClass: 'email-preview'
      });

      await alert.present();
    });
  }

  async presentAlertConfirm(headerMsg: string, msg: string, onConfirm): Promise<void> {
    const alert = await this.alertController.create({
      header: headerMsg,
      message: msg,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('canceled');
          }
        }, {
          text: 'Aceptar',
          handler: () => {
            onConfirm();
          }
        }
      ]
    });

    await alert.present();
  }

  async presentLoading(msg: string, duration = 10000) {
    this.loading = await this.loadingController.create({
      message: msg,
      duration: duration,
      spinner: 'bubbles'
    });
    await this.loading.present();

  }
  
  async errorLoading(msg: string, duration = 10000) {
    const alert = await this.alertController.create({
      header: '¡Error!',
      message: msg,
      buttons: [
        // {
        //   text: 'Cancelar',
        //   role: 'cancel',
        //   cssClass: 'secondary',
        //   handler: () => {
        //     console.log('canceled');
        //   }
        // }, 
        {
          text: 'Aceptar',
          handler: () => {
            // onConfirm();
          }
        }
      ]
    });

    await alert.present();
  }

  async PDFLoading(msg: string, duration = 10000) {
    this.loading = await this.loadingController.create({
      message: msg,
      //duration: duration,
      spinner: 'bubbles'
    });
    await this.loading.present();

  }

  closeLoading() {
    try {
      if (this.loading != null) {
        this.loadingController.dismiss();
        this.loading = null;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async presentToastWithOptions() {
    const toast = await this.toastController.create({
      header: 'Toast header',
      message: 'Click to Close',
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'star',
          text: 'Favorite',
          handler: () => {
            // console.log('Favorite clicked');
          }
        }, {
          text: 'Done',
          role: 'cancel',
          handler: () => {
            // console.log('Cancel clicked');
          }
        }
      ]
    });
    toast.present();
  }

  actualizarListaCatalogos = new Subject();
  actualizarListaAgregarProductosOrden = new Subject();
}
