import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FirebaseDataService, Orden, Usuario } from 'src/services/firebase-data.service';
import { ModalController } from '@ionic/angular';
import { ModalVerOrdenComponent } from '../modal-ver-orden/modal-ver-orden.component';
import { ConfigService } from 'src/services/config.service';
import moment from 'moment';
import { FormBuilder, Validators } from '@angular/forms';
import axios, { AxiosRequestConfig } from 'axios';
import { AuthService } from 'src/services/auth.service';
import { Router } from '@angular/router';
import { ModalAgregarEnviosParcialesComponent } from '../modal-agregar-envios-parciales/modal-agregar-envios-parciales.component';



@Component({
  selector: 'app-cuentasenviadas',
  templateUrl: './cuentasenviadas.component.html',
  styleUrls: ['./cuentasenviadas.component.scss'],
})
export class CuentasenviadasComponent implements OnInit {
  today:any;
  cuentasFiltradas:any[];
  Rechazada: boolean  =false;
  public filtroForm= this.formBuilder.group({
    fechaInicio:['', [Validators.required]],
    fechaFinal: ['', [Validators.required]]
  });

  public observableCuentas: Observable<Orden[]>;
  isEnviada: boolean;
  permisoCuentasEnviadaVerOrden: boolean;
  permisoCuentasEnviadaVerParcial: boolean;


  constructor(
    private authService: AuthService,
    private router: Router,
    public config: ConfigService,
    public formBuilder: FormBuilder,
    public firebaseService: FirebaseDataService,
    private modalController : ModalController,


  ) {
    this.today = moment().format('YYYY-MM-DD');
    this.isEnviada=false;


  }

  async ngOnInit() {
    this.CheckIsLoggedIn();
    this.filtroForm.controls.fechaInicio.setValue(this.today);
    this.filtroForm.controls.fechaFinal.setValue(this.today);
    this.filtrar();
  }

  async ChangeOrderStatus(){
    this.isEnviada = !this.isEnviada;
    console.log('Ahora esta ', this.isEnviada);
    await this.filtrar();
  }
  FormatNumber(number){
    let parts = number.toString().split(".")

    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return num;
    // .toFixed(2);
  }
  showNombre(item){
    if(item.client_tipo=="Física"){
      return item.client_nombre
    }else{
      return item.client_razon_social
    }
  }
  DisplayData(date){
    return moment(date).format('YYYY-MM-DD')
  }
  changeFechaInicio(event)
  {
    const value = event.detail.value;
    console.log(event.detail.value)
    this.filtroForm.controls.fechaInicio.setValue(value);

  }

  async changeFechaFinal(event)
  {
    const value = event.detail.value;
    this.filtroForm.controls.fechaFinal.setValue(value);

  }
  async filtrar()
  {
    const statusType=this.isEnviada?this.config.StatusEnviada:this.config.StatusEnviadaPar;

    this.observableCuentas = await this.firebaseService.GetOrdenesEnviadasByDate(statusType,this.filtroForm.controls.fechaInicio.value + " 00:00:00", this.filtroForm.controls.fechaFinal.value + " 23:59:59");

    // const response = await this.firebaseService.GetOrdenesEnviadasByDate(this.filtroForm.controls.fechaInicio.value + " 00:00:00", this.filtroForm.controls.fechaFinal.value + " 23:59:59");

    this.observableCuentas.subscribe( async (el)=> {
        if (this.isEnviada) {
          this.cuentasFiltradas=await this.GetEnviadas(el);
        }else{
          this.cuentasFiltradas=el;
        }

      });



  }
  async presentModalEnviosParciales(orden) {
    // if (!this.config.CheckPermiso(this.config.permisoCuentasEnviadaVerParcial)) {
    //   return;
    // }
    const modal = await this.modalController.create({
      component: ModalAgregarEnviosParcialesComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"verOrdenClass",
      componentProps:{
        orden:orden,
        cliente:[],
        direccion:[],
        cliente_nombre:''
      }
    });
    modal.onDidDismiss().then((data:any) => {

      this.modalController.dismiss();
      // this.statusOrden =false;
      // this.filtrar();
    })
    return await modal.present();
  }
  async GetEnviadas(cuentas){
    const config: AxiosRequestConfig = {
      headers: {
          'Accept': 'application/json',
          'Content-type': 'multipart/form-data',
      },
    }

    // let ids='&IDs[]=341&IDs[]=333';
    let ids='';

    cuentas.forEach(el => {
      ids+='&IDs[]='+el.ven_id;
    });

    let arrayCuentasEnviadas=[];

    if (cuentas.length > 0) {
      const complement = 'api/get-venta?token='+this.config.tokenApi+ids;
      const url = this.config.Api(complement);
      const response = await axios.get(url, config);
      const cuentasEnvidas=response.data.valor.filter(x => x.ven_estatus === 1);
      const cuentasCerraradas=response.data.valor.filter(x => x.ven_estatus === 0);

      // cuentas[0].ven_id =341;
      // cuentas[1].ven_id =333;
      console.log('arrCuentas ',cuentas);
      console.log('cuentasEnvidas ',cuentasEnvidas);
      let newCuentasEnviadas=[];
      let cuentasEnviadasParaCerrar=[];

      for (let i = 0; i < cuentas.length; i++) {
        const cuenta = cuentas[i];
        if (cuentasEnvidas.some(c=>c.ven_id === cuenta.ven_id)) {
          newCuentasEnviadas.push(cuenta);
        }
        if (cuentasCerraradas.some(c=>c.ven_id === cuenta.ven_id)) {
          cuentasEnviadasParaCerrar.push(cuenta);
        }
      }


      this.CerrarCuentas(cuentasEnviadasParaCerrar);
      return newCuentasEnviadas;
      // console.log("response. observableCuentas ",this.observableCuentas);
      // console.log("response. cuentasParaCerrar ",cuentasParaCerrar);

    } else {
      return arrayCuentasEnviadas;

    }
  }

  CerrarCuentas(arr){
    arr.forEach(async cuenta=>{
      cuenta.status=this.config.StatusRechazada;
      console.log('Se actualizo la cuenta ',cuenta);
      await this.firebaseService.updateOrden(cuenta);
    })
  }
  async CheckIsLoggedIn() {
    let userDatos = await this.authService.GetCurrentUser();
    this.config.currentUser=await this.GetIdErp_uid(userDatos.uid);
    await this.CheckPermiso();
    if(userDatos==false){
      this.router.navigate(['/login']);
    }
  }
  GetIdErp_uid(uid): Promise<Usuario> {
    return new Promise((resolve, reject) => {
      this.firebaseService.GetUser_ByUid(uid).subscribe((user) => {
        if (user) {
          resolve(user[0]);
        }
        else {
          reject(false);
        }
      })
    });
  }

  CheckPermiso(){
    this.permisoCuentasEnviadaVerOrden=this.config.CheckPermiso(this.config.permisoCuentasEnviadaVerOrden);
    this.permisoCuentasEnviadaVerParcial=this.config.CheckPermiso(this.config.permisoCuentasEnviadaVerParcial);


  }

  async presentModal(orden) {
    // if (!this.config.CheckPermiso(this.config.permisoCuentasEnviadaVerOrden)) {
    //   return;
    // }
    const modal = await this.modalController.create({
      component: ModalVerOrdenComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"verOrdenClass",
      componentProps:{orden:orden}
    });
    return await modal.present();
  }
}
