import { AuthService } from 'src/services/auth.service';
import { Subscription } from 'rxjs';
import { ModalDeleteProductoResumenComponent } from './../modal-delete-producto-resumen/modal-delete-producto-resumen.component';
import { Pedido } from './../../services/firebase-data.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalController} from '@ionic/angular';

@Component({
  selector: 'app-modal-dividir-producto',
  templateUrl: './modal-dividir-producto.component.html',
  styleUrls: ['./modal-dividir-producto.component.scss'],
})
export class ModalDividirProductoComponent implements OnInit {

  @Input("producto") producto;
  @Input("pedido") pedido;
  @Input("orden") orden;

  ordenID: any;

  totalPedido: any;

  comensales: any;

  productos: any;

  userUID:string;

  constructor(
    private auth: AuthService,
    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController,
    private modalControl2: ModalController
  ) { }

  async ngOnInit() {
    this.ordenID = this.orden.id
    this.comensales =this.orden.comensales;

    this.totalPedido = 0;
    this.productos = [];
    this.comensales.forEach(element => {
      element.cantidad = 0;
    });

    for (let i = 0; i < this.producto.cantidad; i++) {

      this.productos.push( JSON.parse(JSON.stringify( this.producto)));

    }


    var data = await this.auth.GetCurrentUser();

    this.userUID = data.uid;

    console.log("PRODS",this.productos);
    console.log("COMENSALES",this.comensales);
    console.log("PROD",this.producto);
    console.log("PEDIDO",this.pedido);
    console.log("ORDEN",this.orden);

  }

  FormatNumber(number){
    let parts = number.toString().split(".")
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return num
  }


  async guardarPedido(){
    this.closeModal();
    this.PresentLoading("Dividiendo Productos",1000);

    await this.firebaseService.deleteItemOrden(this.producto,this.orden.id,this.pedido.id);

    var productosDivididos = [];

    this.productos.forEach(element => {
      this.comensales.forEach(comensal => {
        if(element.comensal == comensal.nombre){
          comensal.cantidad ++;
        }
      });
    });


    console.log("COMENSALES", this.comensales);

    var counter = 0;

    this.comensales.forEach(element => {
      console.log("COM",element);
      if(element.cantidad > 0){

        var prod = {
          cantidad: element.cantidad,
          comensal:element.nombre,
          estado: this.producto.estado,
          id : this.producto.id,
          id_producto: this.producto.id_producto,
          imagen_url : this.producto.imagen_url,
          nombre_producto: this.producto.nombre_producto,
          precio: this.producto.precio,
          facturable:this.producto.facturable,
          anotacion: this.producto.anotacion
        } as ItemOrden;

        console.log(prod);

        productosDivididos.push( JSON.parse(JSON.stringify(prod)));
      }

       counter++;
    });

    console.log("PRODUCTOS DIVIDIDOS",productosDivididos)

    productosDivididos.forEach(async element => {
      await this.firebaseService.addItemOrden(element,this.ordenID,this.pedido.id);
    });



   }


   closeModal(){
    this.modalControl.dismiss();
  }

  comensalChange(event,prod){
    var value = event.detail.value;
    prod.comensal= value;
    console.log(value);
  }

  cantidadChange(event,prod){
    var value = event.detail.value;
    prod.cantidad = value;

    prod.total = prod.cantidad * prod.precio;
    console.log(value);
  }

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }
}
