import { Mesa } from './../../services/firebase-data.service';
import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalController} from '@ionic/angular';

@Component({
  selector: 'app-modal-pedido-entregado',
  templateUrl: './modal-pedido-entregado.component.html',
  styleUrls: ['./modal-pedido-entregado.component.scss'],
})
export class ModalPedidoEntregadoComponent implements OnInit {

  @Input("orden") orden ;
  @Input("pedido") pedido;

  prods:any;

  constructor(
    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController
  ) { }

  ngOnInit() {

  }


  closeModal(){
    this.modalControl.dismiss();
  }


  async entregarPedido(){
    this.PresentLoading('Actualizando Estado',1000);
    this.pedido.estado = 'Entregada';
    await this.firebaseService.updatePedido(this.orden,this.pedido);
    this.closeModal();

  }


   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }

}
