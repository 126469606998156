import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import axios,{ AxiosRequestConfig } from 'axios';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/services/config.service';
import { Usuario, FirebaseDataService,  } from 'src/services/firebase-data.service';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.scss'],
})
export class EditarUsuarioComponent implements OnInit {
  @Input("titulo") titulo;
  @Input("item") item;
  usaurios:any;
  name_erp=''
  select_user_erp:any;
  Permisos:any;
  mostrarP:boolean = false;

  accesos = [];
  area = [];
  mesas = [];
  cuentas = [];
  resgitrar = [];
  configuracion = [];
  restaurante = [];
  productos = [];
  customSelectOptions: any = {
    header: 'Empleados del sistema',
    cssClass: 'select-custom-clientes',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Aceptar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };


  public observableUsuarios: Observable<Usuario[]>;


  nombre = new FormControl('Escribe aqui');
  editForm:FormGroup;
  usuario = new FormGroup({
    nombre: new FormControl(''),
    email: new FormControl(''),
  });
  listIdsERPs: any[];

  constructor( private formBuilder: FormBuilder,
    private firebaseService: FirebaseDataService,
    private modalControl: ModalController,
    public config: ConfigService,


    ) {

  }

  ChangeStatusPermiso(index,entry){
    //const key=this.permisosSelected;
    //this.listPermisos[key][index].isChecked=!this.listPermisos[key][index].isChecked;
    console.log("ENTRY",entry);
    entry.isChecked = !entry.isChecked;
    console.log("ENTRY",entry);


  }
  CheckAll(arr){
    console.log("cambiar",arr);
    for (const entry of arr) {
      entry.isChecked = !entry.isChecked;
    }
  }
  async getUsuarios(){
    try{
      console.log(this.name_erp);
      const config: AxiosRequestConfig = {
        headers: {
            'Accept': 'application/json',
            'Content-type': 'multipart/form-data',
        },
    }
    //https://erp.konsultor.mx/api/get-empleados?token=${this.token}&busqueda=${search};
    const url = this.config.Api('api/get-empleados?token='+this.config.tokenApi+`&busqueda=${this.name_erp}`);
    const response = await axios.get(url, config);
    this.usaurios = response.data.filter(e=> !this.listIdsERPs.includes(e.id));
    console.log(this.usaurios);

    return response.data;

    }
    catch(err)
    {
      console.log(err);
    }

  }
  async selectUsurio(e){
    console.log(e.detail.value);
    this.select_user_erp=e.detail.value;
  }
  MostrarPermiso(){
    console.log(this.mostrarP);

    this.mostrarP = !this.mostrarP;
  }

  async ngOnInit() {
    this.editForm = this.formBuilder.group({
      name:[this.item.name,Validators.required],
      tipoUsuario:[this.item.tipoUsuario,Validators.required],
    });
    this.select_user_erp = null;
    console.log('editForm', this.editForm);
    console.log("DATA ITEM",this.item);

    this.observableUsuarios =await  this.firebaseService.GetUsuarios();
    this.Permisos = this.item.permiso ? this.item.permiso : this.config.newListPermisos;

    for (let i = 0; i < this.Permisos.length; i++) {
      const permiso = this.Permisos[i];
      if(permiso.val.includes("Accede a")){
        this.accesos.push(permiso)
      }
      else if(permiso.val.includes("Area -")){
        this.area.push(permiso)
      }
      else if(permiso.val.includes("Mesas -")){
        this.mesas.push(permiso)
      }
      else if(permiso.val.includes("Cuentas -") || permiso.val.includes("Cuentas Enviadas -")){
        this.cuentas.push(permiso)
      }
      else if(permiso.val.includes("Registrar -")){
        this.resgitrar.push(permiso)
      }
      else if(permiso.val.includes("Configuracion -")){
        this.configuracion.push(permiso)
      }
      else if(permiso.val.includes("Restaurante -")){
        this.restaurante.push(permiso)
      }
      else if(permiso.val.includes("Productos -")){
        this.productos.push(permiso)
      }
    }

    this.observableUsuarios.forEach(element => {
      this.listIdsERPs=(element.map(e=>e.erp_data).filter(Boolean));
      this.listIdsERPs=this.listIdsERPs.map(element=>element.id)
      console.log("usuarios: " , element);
      console.log("listIdsERPs: " , this.listIdsERPs);
    })


  }

    async UpdateUsuario(){

      try {
        console.log(this.select_user_erp,this.item.erp_data);

        let usuario: Usuario;
        this.Permisos = this.accesos.concat(this.area,this.mesas,this.cuentas,this.resgitrar,this.configuracion,this.restaurante, this.productos);

        usuario = {
          uid: this.item.uid,
          id: this.item.id,
          email: this.item.email,
          name:  this.editForm.controls.name.value,
          tipoUsuario: this.editForm.controls.tipoUsuario.value,
          erp_data: this.select_user_erp != null ? this.select_user_erp:this.item.erp_data,
          permiso: this.Permisos,
        }as Usuario;
        console.log("Actualizar",usuario);
        let act = await this.firebaseService.UpdatedatosUsuario(usuario);
        console.log("ACT",act);
        this.modalControl.dismiss();
        /*setTimeout(()=>{
          this.modalControl.dismiss();
        },500)*/

      }catch (e){

      }



    }


}
