
import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, UserAdmin } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalController} from '@ionic/angular';
import { AuthService } from 'src/services/auth.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { UserDataService } from 'src/services/user-data.service';

@Component({
  selector: 'app-modal-ticket',
  templateUrl: './modal-ticket.component.html',
  styleUrls: ['./modal-ticket.component.scss'],
})
export class ModalTicketComponent implements OnInit {
  @Input() orden;
  pedidos:any;
  day;
  month;
  year;
  fecha : Date;
  comensales: any;
  numeros: Array<any>;
  totalcompra: any;
  user;
  nombreusuario;

  totalCuenta: any;

  constructor(
    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController,
    private authService: AuthService,
    private userDataService: UserDataService,
  ) { 
    this.user = this.authService.getUser();
    
  }

  async ngOnInit() {
    
      
      console.log("Usuario Actual",this.user);
      
    
    console.log(this.orden); 
    this.fecha = new Date();
    this.day = this.fecha.getDate();
    this.month = this.fecha.getMonth() + 1;
    this.year = this.fecha.getFullYear();
    
    console.log("Fecha: ",this.day)
    this.user = this.userDataService.ShowInfo;
    
    
  }

  

  downloadPDF()
  {
    
    const DATOS: any = document.getElementById('htmlDatos');
    const doc = new jsPDF('p', 'pt', 'a4')  ;
    const options = {
      background: 'white',
      scale: 3
    };

    html2canvas(DATOS, options)
    .then((canvas)=> {
      const img = canvas.toDataURL('image/PNG');
      const bufferX =150;
      const bufferY =150;
      const imgProps = (doc as any).getImageProperties(img);
      const pdfWidth = doc.internal.pageSize.getWidth() -2 * bufferX;
      const pdfHeight =  (imgProps.height * pdfWidth) / imgProps.width;
      doc.addImage(
        img,
        'PNG',
        bufferX,
        bufferY,
        pdfWidth,
        pdfHeight,
        undefined,
        'FAST'
      );
      return doc;
      
    })


    .then((docResult) => {
      docResult.save(`Ticket.pdf`)
      this.dismissModal();
    });
    
  }
      
  async dismissModal(){

    window.location.reload();
   }
  

}

