import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, isNotAnonymous } from '@angular/fire/auth-guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';

import { MenuComponent } from './menu/menu.component';
import { AreasComponent } from './areas/areas.component';
import { CuentasComponent } from './cuentas/cuentas.component';
import { LogsComponent } from './logs/logs.component';


import { RestaurantComponent } from './restaurant/restaurant.component';
import { MesasComponent } from './mesas/mesas.component';
import { MenuPedidoComponent } from './menu-pedido/menu-pedido.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { EditarUsuarioComponent } from './editar-usuario/editar-usuario.component';




import { ClientesComponent } from './clientes/clientes.component';


import { OrdenAgregarProductosComponent } from './orden-agregar-productos/orden-agregar-productos.component';
import { CuentasenviadasComponent } from './cuentasenviadas/cuentasenviadas.component';
import { CommonModule } from '@angular/common';
export const redirectAnonymousTo = (redirect: any[]) =>
  pipe(isNotAnonymous, map(loggedIn => loggedIn || redirect)
);

const redirectUnauthorizedToLogin = () => redirectAnonymousTo(['login']);
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule),
  },
  {
    path: 'old',
    component: MenuComponent,
  },
  {
    path: 'ordenAgregarProductos',
    component: OrdenAgregarProductosComponent,
  },
  {
    path: 'menu-pedido',
    component:MenuPedidoComponent,
  },
  {
    path: 'areas',
    component:AreasComponent
  },
  {
    path: 'editUser',
    component:EditarUsuarioComponent
  },
  {
    path: 'cuentas',
    component:CuentasComponent
  },
  {
    path: 'enviadas',
    component:CuentasenviadasComponent
  },
  {
    path: 'restaurant',
    component:RestaurantComponent
  },
  {
    path: 'mesas',
    component:MesasComponent
  },
  {
    path: 'clientes',
    component:ClientesComponent
  },

  {
    path: 'pedidos',
    component:PedidosComponent
  },

  {
    path: 'configuracion',
    component:ConfiguracionComponent
  },

  {
    path: 'logs',
    component:LogsComponent
  },

  {
    path: 'reportes',
    loadChildren: () => import('./reportes/reportes.module').then( m => m.ReportesPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./newmenu/newmenu.module').then( m => m.NewmenuPageModule)
  },
  {
    path: 'cocina',
    loadChildren: () => import('./cocina/cocina.module').then( m => m.CocinaPageModule)
  },
  {
    path: 'barra',
    loadChildren: () => import('./barra/barra.module').then( m => m.BarraPageModule)
  },
  {
    path: 'productos',
    loadChildren: () => import('./productos/productos.module').then( m => m.ProductosPageModule)
  },
  {
    path: 'cambiar-contrasena',
    loadChildren: () => import('./cambiar-contrasena/cambiar-contrasena.module').then( m => m.CambiarContrasenaPageModule)
  },





];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
