import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuController, LoadingController } from '@ionic/angular';
import { Area, FirebaseDataService, Mesa, Orden, UserAdmin, MesaOrden, MovementUsers, comandasPreparacion, comandasPreparacionBarra } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import moment from 'moment';
import { AuthService } from 'src/services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-cambiar-mesa',
  templateUrl: './modal-cambiar-mesa.component.html',
  styleUrls: ['./modal-cambiar-mesa.component.scss'],
})
export class ModalCambiarMesaComponent implements OnInit {
  @Input("orden") orden;
  ordenForm = new FormGroup({
    area: new FormControl(''),
    date: new FormControl(''),
    mesas: new FormControl(''),
    orden: new FormControl(''),
    comensales: new FormArray([
      new FormGroup({
        nombre: new FormControl('Comensal 1')
      })
    ])
  });

  mesas: MesaOrden[];
  areas: Area[];
  mesaEnabled: any;
  dateTime: any;
  userUID: any;
  public observableCocina: Observable<comandasPreparacion[]>;
  public observableBarra: Observable<comandasPreparacionBarra[]>;

  customSelectOptionsCambiarArea: any = {
    header: 'Seleccion de areas para orden',
    cssClass: 'cambiarMesasClass',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Aceptar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };

  customSelectOptionsCambiarMesas: any = {
    header: 'Seleccion de mesas para orden',
    cssClass: 'cambiarMesasClass',
    translucent: true,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          console.log('Confirm Cancel');
        }
      },
      {
        text: 'Aceptar',
        handler: () => {
          console.log('Confirm Ok');
        }
      }
    ],
  };

  constructor(
    private modalControl: ModalController,
    private loadingController: LoadingController,
    private firebaseService: FirebaseDataService,
    public alertService: AlertsService,
    private auth: AuthService,
  ) { }

  async ngOnInit() {
    var data = await this.auth.GetCurrentUser();
    this.userUID = data.uid;

    this.mesas = [];
    this.mesaEnabled = true;

    this.firebaseService.getAreas().subscribe(data => {
      this.areas = data.filter(e => { let eTemp: any; eTemp = e.payload.doc.data(); return eTemp.activa === null || typeof eTemp.activa === 'undefined' ? true : eTemp.activa }).map(e => {
        var tempArea: any;
        tempArea = e.payload.doc.data();
        return {
          id: e.payload.doc.id,
          nombre: tempArea.nombre,
          descripcion: tempArea.descripcion
        } as Area;
      });
      console.log("AREAS", this.areas);
    });
  }

  closeModal() {
    this.modalControl.dismiss();
  }

  async changeArea(event) {
    const value = event.detail.value;

    console.log("VALOR: ", value);

    if (document.getElementById("areaSelectorRestaurant").classList.contains('selectButton') && value.length > 0) {
      document.getElementById("areaSelectorRestaurant").classList.add('selectButtonSelected');
      document.getElementById("areaSelectorRestaurant").classList.remove('selectButton');
    } else
      if (document.getElementById("areaSelectorRestaurant").classList.contains('selectButtonSelected') && value.length == 0) {
        document.getElementById("areaSelectorRestaurant").classList.add('selectButton');
        document.getElementById("areaSelectorRestaurant").classList.remove('selectButtonSelected');
      }
    this.ordenForm.controls.area.setValue(value);

    await this.PresentLoading('Buscando Mesas', 4000);

    this.mesas = [];

    try {

      value.forEach(async element_a => {
        await this.firebaseService.getMesasLibres(element_a).then((e) => {
          e.forEach(async element => {
            var data = element.data() as Mesa;
            var newMesa: MesaOrden;

            newMesa = {
              id: element.id,
              nombre: data.nombre,
              estado: data.estado,
              id_mesa: element.id,
              cantidad_personas: data.cantidad_personas,
              area: data.area,
              created_date: data.created_date,

            } as MesaOrden
            if (data.activa === null || typeof data.activa === 'undefined' || data.activa === true) {
              this.mesas.push(newMesa);
            }
          });
        });
        this.mesas = this.mesas.sort((a, b) => (a.nombre < b.nombre) ? 1 : -1)
        console.log(this.mesas);
      });
    } catch (error) {
      console.log("ERROR", error)
    }
    finally {
      this.DismissLoading();
    }


    // console.log(value);
  }

  async PresentLoading(mensaje, duracion) {
    const loading = await this.loadingController.create({
      message: mensaje,
      duration: duracion,
      spinner: 'bubbles'
    });
    await loading.present();
    return loading;
  }

  DismissLoading() {
    this.loadingController.dismiss();
  }

  getAreaName(uid) {
    const ar = this.areas.find(a => a.id == uid)
    return ar.nombre
  }

  changeMesa(event) {
    const value = event.detail.value;

    this.ordenForm.controls.mesas.setValue(value);

    console.log(value);

    if (value == null) {
      if (document.getElementById("mesaSelectorRestaurant").classList.contains('selectButtonSelected')) {
        document.getElementById("mesaSelectorRestaurant").classList.add('selectButton');
        document.getElementById("mesaSelectorRestaurant").classList.remove('selectButtonSelected');
      }
    } else {
      if (document.getElementById("mesaSelectorRestaurant").classList.contains('selectButton') && value.length > 0) {
        document.getElementById("mesaSelectorRestaurant").classList.add('selectButtonSelected');
        document.getElementById("mesaSelectorRestaurant").classList.remove('selectButton');
      } else
        if (document.getElementById("mesaSelectorRestaurant").classList.contains('selectButtonSelected') && value.length == 0) {
          document.getElementById("mesaSelectorRestaurant").classList.add('selectButton');
          document.getElementById("mesaSelectorRestaurant").classList.remove('selectButtonSelected');
        }
    }

  }

  async save() {
    // console.log("Orden: ", this.orden);
    // console.log("Mesas: ", this.ordenForm);
    // return;

    if (this.ordenForm.controls.area.value == '' || this.ordenForm.controls.mesas.value == '') {
      await this.alertService.errorLoading('¡No se puede guardar! Revise si se selecciono por lo menos una mesa y intente de nuevo', 5000);
      return;
    }

    //Comienza actualizar mesas anteriores a libres
    var counter = 0;
    const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');
    this.orden.mesas.forEach(async element => {

      const itemOriginal = element;
      var mesa = {
        estado: "Libre",
        id: element.id_mesa,
        nombre: element.nombre,
        cantidad_personas: element.cantidad_personas,
        area: element.area,
        created_by: element?.created_by,
        created_date: element?.created_date,
        edit_date: timeStamp_cer,
        edited_by: this.userUID
      } as Mesa;

      const fecha = new Date();
      const hora = fecha.getHours();
      const minutos = fecha.getMinutes();
      let ordeData: MovementUsers;
      ordeData = {
        tipo: "cambio mesas",
        id_objeto: element.id_mesa,
        id_usuario: this.userUID,
        accion: "actualizar",
        movimiento: "Actualizar mesa al cambiar las mesas",
        original: itemOriginal,
        nuevo: mesa,
        fecha: timeStamp_cer
      }
      await this.firebaseService.AddMovementUsers(ordeData);

      // console.log("MESA", mesa);

      await this.firebaseService.updateMesa(mesa, mesa.area);

      if (counter >= this.orden.mesas.length - 1) {
        this.modalControl.dismiss();
      }

      counter++;
    });
    //Termina actualizar mesas anteriores a libres

    //Empieza a guardar nuevas mesas
    var tempMesas = this.ordenForm.get('mesas').value;
    var inputMesas = [];
    tempMesas.forEach(element => {
      var tempVal = element.toString() as string;
      var mesaTemp = tempVal.split(',');

      var mesaData = {
        id: mesaTemp[0],
        area: mesaTemp[1]
      }

      inputMesas.push(mesaData);
    });

    // console.log("MESA DATA", inputMesas);

    var selectedMesas: MesaOrden[];

    selectedMesas = [];

    // var index = 0;
    for (let b = 0; b < inputMesas.length; b++) {
      const element = inputMesas[b];

      // console.log("Element 1: ", element)
      let data = await this.getMesaAwait(element.area, element.id);
      // console.log("MESAS 2", data.data());

      var info: any;
      info = data.data();
      var mesa: MesaOrden;

      mesa = {
        id: data.id,
        id_mesa: data.id,
        nombre: info.nombre,
        cantidad_personas: info.cantidad_personas,
        estado: 'Ocupada',
        created_by: info.created_by,
        created_date: info.created_date,
        edit_date: info.edit_date,
        edited_by: info.edited_by,
        area: info.area
      } as MesaOrden

      selectedMesas.push(mesa);

      // console.log('MESAS Seleccionadas 3', selectedMesas);

      let ordeData: MovementUsers;
      ordeData = {
        tipo: "cambio mesas",
        id_objeto: element.id,
        id_usuario: this.userUID,
        accion: "actualizar",
        movimiento: "Actualizar mesa al cambiar las mesas",
        original: info,
        nuevo: mesa,
        fecha: timeStamp_cer
      }
      await this.firebaseService.AddMovementUsers(ordeData);

      await this.firebaseService.updateMesa(mesa, mesa.area);
    }
    //Termina de guardar nuevas mesas

    let ordeData: MovementUsers;
    ordeData = {
      tipo: "cambio mesas orden",
      id_objeto: this.orden.id,
      id_usuario: this.userUID,
      accion: "actualizar",
      movimiento: "Actualizar mesa al cambiar las mesas orden",
      original: this.orden.mesas,
      nuevo: selectedMesas,
      fecha: timeStamp_cer
    }
    await this.firebaseService.AddMovementUsers(ordeData);

    this.orden.mesas = selectedMesas;
    // console.log("mesas 4: ", selectedMesas)
    // console.log("orden mesas 5: ", this.orden.mesas)
    // console.log("orden: 6", this.orden)

    await this.firebaseService.updateOrden(this.orden);

    let mesasOrden = "";
    for (let b = 0; b < this.orden.mesas.length; b++) {
      const mesa = this.orden.mesas[b];
      
      if(mesasOrden == ""){
        mesasOrden += mesa.nombre;
      }
      else{
        mesasOrden += ", " + mesa.nombre;
      }
    }

    let ped = await this.AwaitCocinaByOrden(this.orden.id);

    for (let a = 0; a < ped.length; a++) {
      const cocina = ped[a];
      cocina["mesas_anteriores"] = cocina.mesas;
      cocina.mesas = mesasOrden;
      await this.firebaseService.UpdateComandasPreparacion(cocina);
    }

    let bar = await this.AwaitBarraByOrden(this.orden.id);

    for (let a = 0; a < bar.length; a++) {
      const barra = bar[a];
      barra["mesas_anteriores"] = barra.mesas;
      barra.mesas = mesasOrden;
      await this.firebaseService.UpdateComandasPreparacionBarra(barra);
    }

    location.reload();
    // return;
  }

  async AwaitCocinaByOrden(id_orden: string): Promise<comandasPreparacion[]> {
    return new Promise((resolve, reject) => {
      this.observableCocina = this.firebaseService.getAllPedidosCocinaByOrden(id_orden);
  
      this.observableCocina.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async AwaitBarraByOrden(id_orden: string): Promise<comandasPreparacion[]> {
    return new Promise((resolve, reject) => {
      this.observableBarra = this.firebaseService.getAllPedidosBarraByOrden(id_orden);
  
      this.observableBarra.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async getMesaAwait(area, id): Promise<any> {
    return new Promise((resolve, reject) => {
      let info = this.firebaseService.getMesa(area, id);

      info.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }
}
