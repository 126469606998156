import { ModalPedidoEntregadoComponent } from './../modal-pedido-entregado/modal-pedido-entregado.component';
import { ModalDividirProductoComponent } from './../modal-dividir-producto/modal-dividir-producto.component';
import { ComensalesOrdenModalComponent } from './../comensales-orden-modal/comensales-orden-modal.component';
import { ModalAgregarComensalComponent } from './../modal-agregar-comensal/modal-agregar-comensal.component';
import { ModalEditComandaComponent } from './../modal-edit-comanda/modal-edit-comanda.component';
import { ModalCobrarCuentaComponent } from './../modal-cobrar-cuenta/modal-cobrar-cuenta.component';
import { ModalAceptarCuentaComponent } from './../modal-aceptar-cuenta/modal-aceptar-cuenta.component';
import { ModalPropinaComponent } from './../modal-propina/modal-propina.component';
import { ModalVerOrdenComponent } from './../modal-ver-orden/modal-ver-orden.component';
import { ModalConfirmCerrarComponent } from './../modal-confirm-cerrar/modal-confirm-cerrar.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RestaurantComponent } from './restaurant.component';
import { FormsModule } from '@angular/forms';
import { ModalTicketComponent } from '../modal-ticket/modal-ticket.component';
import { ModalCambiarMesaComponent } from '../modal-cambiar-mesa/modal-cambiar-mesa.component';



@NgModule({
  declarations: [RestaurantComponent,ModalConfirmCerrarComponent,
    ModalVerOrdenComponent, ModalPropinaComponent, ModalAceptarCuentaComponent,
    ModalCobrarCuentaComponent, ModalEditComandaComponent,
  ModalAgregarComensalComponent,ModalDividirProductoComponent, ModalTicketComponent,
  ModalCambiarMesaComponent,
ComensalesOrdenModalComponent,ModalPedidoEntregadoComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class RestaurantModule { }
