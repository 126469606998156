import { AuthService } from 'src/services/auth.service';
import { Mesa, MovementUsers } from './../../services/firebase-data.service';
import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalController} from '@ionic/angular';
import moment from 'moment';
import { ModalNotaProductoComponent } from '../modal-nota-producto/modal-nota-producto.component';

@Component({
  selector: 'app-modal-aceptar-cuenta',
  templateUrl: './modal-aceptar-cuenta.component.html',
  styleUrls: ['./modal-aceptar-cuenta.component.scss'],
})
export class ModalAceptarCuentaComponent implements OnInit {

  @Input("orden") orden;

  pedidos:any;

  comensales: any;

  totalCuenta: any;

  userUID:string;

  prods:any;

  constructor(
    private auth:AuthService,
    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController 
  ) { }

  async ngOnInit() {
    console.log('Estas por aceptar cuenta',this.orden);
    
    this.totalCuenta = 0;

    this.comensales =this.orden.comensales;

    this.comensales.forEach(element => {
      element.total = 0;
      element.productos = [];

    });

    var subs = await this.firebaseService.getPedidos(this.orden.id).subscribe(sub =>{
      var counter =0;
      this.totalCuenta = 0;
      this.pedidos = sub.map( (e)=>{
        var data : any;
        data = e.payload.doc.data();

        var pedido_id = e.payload.doc.id;

        var productosOrden = [];

        console.log("DATA",data);

        this.totalCuenta += data.total_pedido;



         this.firebaseService.getOrdenItems(this.orden.id,pedido_id).subscribe(data => {
          var product = data.map( e =>{

           var tempData: any;
           tempData = e.payload.doc.data();


           return {
            id:e.payload.doc.id,
            id_producto: tempData.id_producto,
            cantidad: tempData.cantidad,
            nombre_producto: tempData.nombre_producto,
            imagen_url: tempData.imagen_url,
            precio: tempData.precio,
            comensal: tempData.comensal,
            estado : tempData.estado,
            facturable: tempData.facturable,
            anotacion: tempData.anotacion,
           } as ItemOrden;

          });
          productosOrden.push(product);


      });

      counter ++;


      return{
        estado : data.estado,
        productos : productosOrden
      }


      });

      console.log("ORDEN",this.orden);
      console.log("PEDIDOS",this.pedidos);

      setTimeout(()=>{

        this.comensales.forEach(element => {
          element.productos = [];
          element.total = 0;
        });
        this.pedidos.forEach(pedido => {


          pedido.productos[0].forEach(prod => {
            var tempData = prod;

            this.comensales.forEach(element => {
              var total = (tempData.cantidad * tempData.precio);

              if(element.nombre == tempData.comensal){
                //console.log("Item", tempData);
                element.total += total;
                element.productos.push(tempData);

              }
            });

          });
          counter ++;
        });

        subs.unsubscribe();

        console.log("COMENSALES",this.comensales);
      },500);



    });


    var data = await this.auth.GetCurrentUser();

    this.userUID = data.uid;

  }
  FormatNumber(number){
    let parts = number.toString().split(".")
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return num
  }

  toggleCat(categoria){
    var x = document.getElementById(categoria+this.orden.id+'2');

    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  toggleComensales(categoria){
    var x = document.getElementById(categoria+"comensales2");

    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  closeModal(){
    this.modalControl.dismiss();
  }

  async aceptarCuenta(){
    const itemOriginal=this.orden;
    this.orden.status = 'Aceptada';
    console.log("CUENTA UPDATE", this.orden);
    await this.firebaseService.updateOrden(this.orden);
    const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

    let ordeData: MovementUsers;
    ordeData = {
      tipo: "ordenes",
      id_objeto: this.orden.id,
      id_usuario:  this.userUID,
      accion: "actualizar",
      movimiento: "Actualizar al aceptar cuenta",
      original:itemOriginal,
      nuevo: this.orden,
      fecha: timeStamp_cer

    }
    await this.firebaseService.AddMovementUsers(ordeData);
    this.closeModal();
  }



   //this.modalControl.dismiss();

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }

  async showNota(prod, i){
    const modal = await this.modalControl.create({
      component: ModalNotaProductoComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      componentProps:{producto: prod, proceso: "Confirmar", estatus: "Ver"},
      cssClass:"aceptarComandaModal"
    });
    
    await modal.present();
  
    let proceso;
    await modal.onDidDismiss().then((data) => {
      proceso = data;
    })
  
    if(proceso.role != "Cerrar" && proceso.role != "backdrop"){
      prod.anotacion = proceso.data;
    }
  }
}
