import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import moment from 'moment';
import { AuthService } from 'src/services/auth.service';
import { FirebaseDataService, MovementUsers, Orden } from 'src/services/firebase-data.service';

@Component({
  selector: 'app-modal-agregar-anotaciones',
  templateUrl: './modal-agregar-anotaciones.component.html',
  styleUrls: ['./modal-agregar-anotaciones.component.scss'],
})
export class ModalAgregarAnotacionesComponent implements OnInit {
  anotacionesForm:FormGroup;
  @Input("orden") orden;
  comensales: any;
  userUID: any;
  

  constructor(
    private builder: FormBuilder,
    private modalControl: ModalController,
    private firebaseService:FirebaseDataService,
    private authService: AuthService,



  ) { }

  async ngOnInit() {
  

    console.log('Recibi orden ',this.orden);
    this.comensales = this.orden.comensales;
    try {
      let my_obj={
        general: [this.orden?.anotacion_general? this.orden?.anotacion_general:'',Validators.required]
      };
        this.comensales.forEach(com => {
          com.productos.forEach(el => {
            my_obj[com.nombre+'-prodId-'+el.id_producto] =  [el?.anotacion?el?.anotacion:'',Validators.required];
          });
        });


      this.anotacionesForm = this.builder.group(my_obj);
      
      const userDatos = await this.authService.GetCurrentUser();
      this.userUID = userDatos.uid;  
  
    } catch (error) {
      
    }
  }
  async aceptarCuenta(){
    
    const original = this.orden;
    const vals=this.anotacionesForm.controls;
    this.orden.anotacion_general=vals['general'].value;
    this.comensales.forEach(comen => {
      comen.productos.forEach(produ => {
        produ.anotacion=vals[comen.nombre+'-prodId-'+produ.id_producto].value
      });
    });
    await this.firebaseService.updateOrden(this.orden);
 
    let ordeData2: MovementUsers;
    const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

    ordeData2 = {
      tipo: "ordenes-anotaciones",
      id_objeto:this.orden.id,
      id_usuario: this.userUID,
      accion: "actualizar",
      movimiento: "Agragar anotacion a orden",
      original:original ,
      nuevo: this.orden,
      fecha: timeStamp_cer
    }
    console.log('Guarde',ordeData2);

     await this.firebaseService.AddMovementUsers(ordeData2);
     this.modalControl.dismiss();
    alert('Guardado exitosamente!');
 
   }
   
  closeModal(){
    this.modalControl.dismiss();
  }


}
