import { formatNumber } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { AuthService } from 'src/services/auth.service';
import { ConfigService } from 'src/services/config.service';
import { EnviosParciales, FirebaseDataService, MovementUsers, Orden } from 'src/services/firebase-data.service';

@Component({
  selector: 'app-modal-agregar-envios-parciales',
  templateUrl: './modal-agregar-envios-parciales.component.html',
  styleUrls: ['./modal-agregar-envios-parciales.component.scss'],
})
export class ModalAgregarEnviosParcialesComponent implements OnInit {
  envioForm:FormGroup;
  @Input("orden") orden;
  @Input("cliente") cliente;
  @Input("direccion") direccion;
  @Input("cliente_nombre") cliente_nombre;
  comensales: any;
  userUID: any;
  restante: any;
  productoAgrupado: any;
  propina: any;
  anotGeneral: string;
  anotEsp: string;
  cantidad: number;

  encontrado: boolean;
  type: string;
  dir_id: any;
  conf_propina: any;
  conf_agrupado: any;
  myList: any[];
  totalAbonado: number;
  // modalController: any;
  rechazada:boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private modalControl: ModalController,
    private firebaseService:FirebaseDataService,
    private authService: AuthService,
    public config: ConfigService,


    ) {
      this.myList=[];
    this.envioForm = formBuilder.group({
      anotEspecifica: ["", [Validators.required]],
      anotGeneral: ["", [Validators.required]],
      cantidad: ["", [Validators.required]],
    });
    this.totalAbonado=0;


   }
   ShowPropina(_item,index){
      console.log("ORDEN ___ ",_item, index);
      let show =_item.ven_total-Number(_item.cantidad);
      console.log("TOTAL",show);
      return this.FormatNumber(show,index)
      //return this.FormatNumber(show,index)
   }
   ShowTotal(_item,index){
     console.log("TOTTOO",_item.ven_total);

    return this.FormatNumber(_item.ven_total,index)
   }
   FormatNumber(number,index){
     console.log('-------index',index);

    if(typeof number === 'undefined')
    {return '00.00'}
    let parts = number.toString().split(".")

    //console.log(parts);
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return index==(this.myList?.length-1)?parseFloat(num)+0:num;
  }
  ShowCliente(cliente:any){
    console.log("CLEI",cliente);

    if(cliente.tipo_persona=="Física"){
      return cliente.nombre+' '+cliente.apellidos+': '+cliente.rfc;
    }else{
      return cliente.razon_social+': '+cliente.rfc;
    }
  }

  async ngOnInit() {

    await this.GetProductoAgrupado();
    await this.GetPropina();
    await this.GetList();
    // this.restante=0;


  }

  async aceptarCuenta(){
    if(this.orden.status == this.config.StatusEnviadaPar || this.orden.status == this.config.StatusEnviada || this.rechazada == true){
      alert('no puedes aceptar el envio favor de presionar cerrar');
      return;
    }
    if(this.envioForm.controls.cantidad.value>this.restante){
      alert('Asegurate de ingresar una cantidad valida');
      return;
    }
    const contactoId=this.cliente.contactos?.length>0?this.cliente.contactos[0].contacto_id:null;
    const opt={
      orden:this.orden,
      cliente:this.cliente,
      direccion:this.direccion,
      // productoAgrupado:this.conf_agrupado,
      contactoId:contactoId,
      propina:this.conf_propina,
      anotGeneral: this.envioForm.controls.anotGeneral.value,
      anotEsp:this.envioForm.controls.anotEspecifica.value,
      cantidad:this.envioForm.controls.cantidad.value,
      // dir_id:this.direccion.dir_id
    }
    console.log('ENVIAR',opt);

    this.EnvioParcial(opt);
    // this.EnvioParcial(this.orden,this.cliente,this.direccion,this.productoAgrupado,contactoId,this.propina,this.anotGeneral,this.anotEsp,this.cantidad);
    return;

    // let ordeData2: MovementUsers;
    // const today = moment().format('YYYY-MM-DD');
    // const fecha = new Date();
    // const hora =fecha.getHours();
    // const minutos=fecha.getMinutes();
    // ordeData2 = {
    //   tipo: "ordenes-anotaciones",
    //   id_objeto:this.orden.id,
    //   id_usuario: this.userUID,
    //   accion: "actualizar",
    //   movimiento: "Agragar anotacion a orden",
    //   original:original ,
    //   nuevo: this.orden,
    //   fecha: today+' '+hora+':'+minutos,
    // }
    // console.log('Guarde',ordeData2);

    // await this.firebaseService.AddMovementUsers(ordeData2);
    // this.modalControl.dismiss();
    // alert('Guardado exitosamente!');

  }
  CalculateRestante() {
    this.myList.forEach(enp => {
      this.totalAbonado+=enp?.cantidad?enp.cantidad*1:0;
    });
    console.log('myList',this.myList);
    console.log('totalAbonado',this.totalAbonado);
    return this.orden.totalOrden-this.totalAbonado;
  }

  closeModal(){
    this.modalControl.dismiss();
  }


  async EnvioParcial({
    orden,
    cliente,
    direccion,
    contactoId,
    propina,
    anotGeneral,
    anotEsp,
    cantidad}){
    let productos = [];
    let impuesto_precio = 0;

    let impuesto_ag =[];
    for (const key in this.conf_agrupado.impuestos) {
      const data = this.conf_agrupado.impuestos[key];
      impuesto_precio += Number(data.porcentaje_trasladado);
      const imp= {
          imp_id:data.id,
          pd_porc_imp:data.porcentaje_trasladado,
          pd_precio_imp:1*(Number(cantidad)*(Number(data.porcentaje_trasladado)/100)),
        }
        impuesto_ag.push(imp);

    }
    console.log(cantidad,impuesto_precio,(1+(impuesto_precio/100)));

    let precio_original = Number(cantidad)/(1+(impuesto_precio/100));
    let impuesto_ag_env =[];
    for (const key in this.conf_agrupado.impuestos) {
      const data = this.conf_agrupado.impuestos[key];
      const imp= {
          imp_id:data.id,
          pd_porc_imp:data.porcentaje_trasladado,
          pd_precio_imp:1*(Number(precio_original)*(Number(data.porcentaje_trasladado)/100)),
        }
        impuesto_ag_env.push(imp);

    }

    productos[0]=[];
    let propina_cantidad= 0;
    if( orden.propina !=0 && this.isFirst()){//agreagr que si no es el primer parcial no se agrega
      propina_cantidad = orden.propina;
      productos[0].push({
        prod_id:propina?propina.id:1,//test:20,prod:287
        cantidad:1,
        precio:orden.propina,
        direccion:direccion.direccion,
        con_id:contactoId,
        dir_id:direccion.dir_id,
        importe: orden.propina,
        unidad_medida:2,//obtenerlo del objeto de propina despues
        impuestos:[],
        facturable:propina?propina.facturable:2,
        anotacion:null
      });
    }
    productos[0].push({
          prod_id:this.conf_agrupado.id,
          cantidad:1,
          precio:precio_original,
          direccion:direccion.direccion,
          con_id:contactoId,
          dir_id:direccion.dir_id,
          importe: precio_original,
          unidad_medida:this.conf_agrupado.unidad_medida ,
          impuestos:impuesto_ag_env,
          facturable:this.conf_agrupado.facturable ? 1:0,
          anotacion:anotEsp? anotEsp:null
    });
    // orden.propina !=0 &&

    console.log('productos',productos);
    const anotacion_general=anotGeneral;
    const id_agrupacion=this.conf_agrupado.id;
    const id_propina=this.conf_propina.id;
    const totalPacial = Number(cantidad)+Number(propina_cantidad);
    const subParcial = Number(precio_original)+Number(propina_cantidad);

    const liquidar = (this.restante - Number(cantidad)) == 0 ? true:false;
    const parcial_first = this.isFirst();
    this.modalControl.dismiss({
      productos,
      anotacion_general,
      cliente,
      id_agrupacion,
      id_propina,
      cantidad,
      totalPacial,
      subParcial,
      liquidar,
      parcial_first
    });


  }
  isFirst(){
    // Check the list of enviosParciales
    return this.myList.length<=0;
  }
  async GetPropina(){
    await this.firebaseService.GetConfiguracion().subscribe(el=>{
      this.conf_propina=el[0]?.productoPropina;
      // console.log('productoPropina',this.conf_propina);

    });
  }
  async GetProductoAgrupado(){
    await this.firebaseService.GetConfiguracion().subscribe(el=>{
      this.conf_agrupado=el[0]?.productoAgrupado;
      // console.log('productoAgrupado',this.conf_agrupado);

    });
  }
  async GetList(){
    // this.myList
    const a= await this.firebaseService.GetEnviosParcialesByOrden(this.orden.id);
    let cuentas;
    a.subscribe(data=>{
      cuentas=data;
      console.log('envios data: ',data);

    });
    setTimeout(async () => {
      if (cuentas) {
        this.myList=await this.GetEnviadas(cuentas);
        console.log("LIST______",this.myList);

        const userDatos = await this.authService.GetCurrentUser();
        this.userUID = userDatos.uid;
        this.restante=this.CalculateRestante();
        this.envioForm.controls.cantidad.setValue(this.restante);
        console.log('restante',this.restante);
      }
    }, 500);

  }

  async GetEnviadas(cuentas){
    const config: AxiosRequestConfig = {
      headers: {
          'Accept': 'application/json',
          'Content-type': 'multipart/form-data',
      },
    }

    // let ids='&IDs[]=341&IDs[]=333';
    let ids='';
    console.log('arrCuentas ',cuentas);

    cuentas.forEach(el => {
      ids+='&IDs[]='+el.id_venta;
    });


    if (cuentas.length > 0) {
      const complement = 'api/get-venta?token='+this.config.tokenApi+ids;
      const url = this.config.Api(complement);
      const response = await axios.get(url, config);
      const cuentasEnvidas=response.data.valor.filter(x => x.ven_estatus === 1);
      const cuentasCerraradas=response.data.valor.filter(x => x.ven_estatus === 0);

      // cuentas[0].id_venta =341;
      // cuentas[1].id_venta =333;
      // console.log('arrCuentas ',cuentas);
      console.log('cuentasEnvidas ',cuentasEnvidas);
      console.log('cuentasCerraradas ',cuentasCerraradas);
      let newCuentasEnviadas=[];
      let cuentasEnviadasParaCerrar=[];
      cuentas.forEach(cuenta => {
          // const cuenta = cuentas[i];
        if (cuentasEnvidas.some(c=>c.ven_id == cuenta.id_venta)) {
          let cuentaE = cuentasEnvidas.filter(ce => ce.ven_id == cuenta.id_venta)
          cuenta.ven_total = cuentaE[0].ven_total;
          console.log("CIEN -----",cuentaE);
          console.log("CIEN -----",cuentaE[0].ven_total);
          newCuentasEnviadas.push(cuenta);
        }
        if (cuentasCerraradas.some(c=>c.ven_id == cuenta.id_venta)) {
          cuentasEnviadasParaCerrar.push(cuenta);
        }
      });

      console.log('newCuentasEnviadas ',newCuentasEnviadas);
      console.log('cuentasEnviadasParaCerrar ',cuentasEnviadasParaCerrar);
      this.CerrarCuentas(cuentasEnviadasParaCerrar);

      return newCuentasEnviadas;

      // console.log("response. observableCuentas ",this.observableCuentas);
      // console.log("response. cuentasParaCerrar ",cuentasParaCerrar);

    } else {
      return [];

    }
  }

  CerrarCuentas(arr){
    arr.forEach(async cuenta=>{
      const cuentaOriginal = cuenta;
      cuenta.status=this.config.StatusInactiva;
      console.log('Se actualizo la cuenta ',cuenta);
      await this.firebaseService.UpdateEnviosParciales(cuenta);
      //TODO: Add MOVUsers
    });
    if (arr.length > 0) {
      this.rechazada = true;
      this.CerrarOrden(this.orden);
    }
  }

  async CerrarOrden(orden){
      const ordenOriginal = orden;
      orden.status=this.config.StatusRechazada;
      console.log('Se actualizo la orden ',orden);
      await this.firebaseService.updateOrden(orden);
      //TODO: Add MOVUsers
  }


}
