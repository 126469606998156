import { ModalPedidoEntregadoComponent } from './../modal-pedido-entregado/modal-pedido-entregado.component';
import { ModalDividirProductoComponent } from './../modal-dividir-producto/modal-dividir-producto.component';
import { Pedido,DatosPago, MovementUsers, Usuario } from './../../services/firebase-data.service';
import { ModalEditComandaComponent } from './../modal-edit-comanda/modal-edit-comanda.component';
import { EditarDatosPagadosComponent } from '../editar-datos-pagados/editar-datos-pagados.component';

import { ModalAceptarCuentaComponent } from './../modal-aceptar-cuenta/modal-aceptar-cuenta.component';
import { promise } from 'protractor';
import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden, Ticket,UserAdmin, comandasPreparacion, comandasPreparacionBarra } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalController} from '@ionic/angular';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';

import { AuthService } from 'src/services/auth.service';
import moment from 'moment';
import { ModalCobrarCuentaComponent } from '../modal-cobrar-cuenta/modal-cobrar-cuenta.component';
import { ConfigService } from 'src/services/config.service';
import { ModalNotaProductoComponent } from '../modal-nota-producto/modal-nota-producto.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-ver-orden',
  templateUrl: './modal-ver-orden.component.html',
  styleUrls: ['./modal-ver-orden.component.scss'],
})

export class ModalVerOrdenComponent implements OnInit {

  @Input("orden") orden;
  pedidos:any;
  public observableCocina: Observable<comandasPreparacion[]>;
  public observableBarra: Observable<comandasPreparacionBarra[]>;
  mostrar: boolean;
  day;
  month;
  year;
  hora;
  minutos;
  fecha : Date;
  comensales: any;
  productosGenerales;
  listaCobros:any [];

  totalCuenta: any;
  totalAPagar:any;
  userDatos: any;
  userUID: any;
  userNombre: any;
  showPagos: boolean;
  showEditPropina: boolean;
  user_ticket: any;
  id_pedido_to_delete: any;


  ip: string = '';
  printerPort:string = '';
  status: boolean = false;
  newPropina: any;
  isSaldada: any;
  nuevaPropina;

  notaProducto;
  permisoModificarOrden: boolean;
  constructor(
    private router: Router,
    public alertService: AlertsService,
    public config: ConfigService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController,
    private authService: AuthService,
  ) {
    this.mostrar=false;
    this.showEditPropina=false;
  }

  mostrarTrue()
  {
    this.mostrar=true;
  }
  mostrarFalse()
  {
    this.mostrar=false;
  }
  getInicialesNombre(nameString){
    const fullName = nameString.split(' ');
    let initials = '';
    for (let index = 0; index < fullName.length; index++) {
      const element = fullName[index];
      console.log("MAME ",element);
      initials += element.charAt(0);
    }
    return initials.toUpperCase();
  }

  async downloadPDF() //revisar
  {
    try {


    this.mostrarTrue();
      setTimeout(() => {


      const DATOS: any = document.getElementById('htmlDatos');
      const doc2 = new jsPDF('p', 'pt', [140, 840]);
      const options = {
        allowTaint: true, useCORS: true,
        background: 'white',
        scale: 3
      };
      let element = document.getElementById("htmlDatos");
      domtoimage.toPng(element, options).then(async (dataUrl) => {
        //Initialize JSPDF
        let image = new Image()
        image.src = dataUrl;
        // const bufferX =190;
        // const bufferY = 190;
        const imgProps = (doc2 as any).getImageProperties(image);
        // const doc = new jsPDF('p', 'pt', [155, image.height]);
        const h =imgProps.height*0.16;
        const doc = new jsPDF('p', 'pt', [80, imgProps.height*0.22]);

        console.log('my img',imgProps);
        console.log('my img PDF w:',imgProps.width,"-h:",imgProps.height);

        // const pdfWidth = doc.internal.pageSize.getWidth()-2 * bufferX;
        // const pdfHeight =(doc.internal.pageSize.getHeight() -2 * bufferY);
        // const pdfHeight =  (imgProps.height * pdfWidth) / imgProps.width;
        // doc.format=[120,imgProps.height*0.21];
        console.log("IMAGE",image);

        this.PrintImage(image.src,80,imgProps.height*1.15)//*0.22)
        doc.addImage(
          image,
          'PNG',
          15, // bufferX
          5, // bufferY
          80, // imgWidth
          imgProps.height*0.20,
          // image.height,
          undefined,
          'FAST'
        );
        return doc;
      }).then((docResult) => {
        this.mostrarFalse();
        //docResult.autoPrint();
        //docResult.output('dataurlnewwindow');
        //docResult.save(`Ticket.pdf`)
        this.guardarTicket();
      });

    }, 1000);

  } catch (error) {

  }
}
 ImagetoPrint(source,w,y)
    {
      //w = w*0.2645833333;
      console.log(w,y);

      let he =Math.floor(y * 0.264583)
      let wd =Math.floor(w * 0.264583)
      console.log("height",he, wd);

      //y = y*0.2645833333;
        return "<html><head>"+
                `<style>
                body {
                      margin: 0;
                      padding: 0;
                  }
                  * {
                      box-sizing: border-box;
                      -moz-box-sizing: border-box;
                  }
                  .page {
                      width: ${w};
                      min-height: ${he}mm;
                      padding: 2mm;
                      margin: 1mm auto;
                      border: none;
                      background: #F4F3F1;
                  }
                  .page-inner{
                      padding: 1mm;
                      border: 2px solid black;
                      height: ${he}mm;
                      outline: 2mm #F4F3F1 solid;
                  }
                @page {
                    size: ${w} ${he}mm;
                    margin: 0;
                }
                @media print {
                    .page {
                        margin: 0;
                        border: initial;
                        border-radius: initial;
                        width: initial;
                        min-height: initial;
                        box-shadow: initial;
                        background: initial;
                        page-break-after: always;
                    }
                }
                </style>`+
                "<scri"+"pt>function step1(){\n" +
                "setTimeout('step2()', 10);}\n" +
                "function step2(){window.print();window.close()}\n" +
                "</scri" + "pt></head><body onload='step1()'>\n" +
                "<div class='page'><div class='page-inner'><img width=130% height=100% src='" + source + "' /></div> </div> </body></html>";
    }

    PrintImage(source,w,y)
    {
        var Pagelink = "about:blank";
        var pwa = window.open(Pagelink, "_new");
        pwa.document.open();
        pwa.document.write(this.ImagetoPrint(source,w,y));
        pwa.document.close();
    }

 async ngOnInit() {
   this.showPagos=this.orden.status==this.config.StatusCerrada;
   this.userDatos = await this.authService.GetCurrentUser();
   this.userUID = this.userDatos.uid;
   this.userNombre = await this.WaitForUserData(this.userUID);
   this.user_ticket= await this.GetIdErp(this.orden.created_by);
   this.newPropina= this.orden.propina;


  this.fecha = new Date(this.orden.created_date);
  this.day = this.fecha.getDate();
  this.month = this.fecha.getMonth() + 1;
  this.year = this.fecha.getFullYear();
  this.hora = this.fecha.getHours();
  this.minutos=this.fecha.getMinutes();




  this.totalCuenta = 0;
  this.listaCobros =[];
  this.GetDatosPagos();

    this.comensales =this.orden.comensales;

    this.comensales.forEach(element => {
      element.total = 0;
      element.productos = [];

    });

    var subs = await this.firebaseService.getPedidos(this.orden.id).subscribe(sub =>{
      var counter =0;
      this.totalCuenta = 0;
      this.pedidos = sub.map( (e)=>{
        var data : Pedido;
        data = e.payload.doc.data() as Pedido;

        var pedido_id = e.payload.doc.id;

        var productosOrden = [];

        console.log("DATA",data);

        this.totalCuenta += data.total_pedido;



         this.firebaseService.getOrdenItems(this.orden.id,pedido_id).subscribe(data => {
          var product = data.map( e =>{

           var tempData: any;
           tempData = e.payload.doc.data();
           return {
            id:e.payload.doc.id,
            id_producto: tempData.id_producto,
            cantidad: tempData.cantidad,
            nombre_producto: tempData.nombre_producto,
            imagen_url: tempData.imagen_url,
            precio: tempData.precio,
            comensal: tempData.comensal,
            estado : tempData.estado,
            facturable: tempData.facturable,
            impuestos:tempData.impuestos,
            unidad_medida:tempData.unidad_medida,
            anotacion:tempData.anotacion
           } as ItemOrden;
          });
          productosOrden.push(product);
      });

      return{
        estado : data.estado,
        productos : productosOrden,
        listaCobros:this.listaCobros,
        id : pedido_id,
        total_pedido: data.total_pedido,
        comensal_total: data.comensal_total,
        created_by: data.created_by,
        edit_date: data.edit_date,
      }

      });
      console.log("ORDEN MESAS: ", this.orden.mesas)
      console.log("ORDEN",this.orden);
      console.log("PEDIDOS",this.pedidos);

      setTimeout(()=>{

        this.comensales.forEach(element => {
          element.productos = [];
          element.total = 0;
        });
        let totalGeneral = 0;
        this.pedidos.forEach(pedido => {0


          pedido.productos[0].forEach(prod => {
            var tempData = prod;

            totalGeneral += (tempData.cantidad * tempData.precio);
            this.comensales.forEach(element => {
              var total = (tempData.cantidad * tempData.precio);

              if(element.nombre == tempData.comensal){
                //console.log("Item", tempData);
                element.total += total;
                element.productos.push(tempData);

              }
            });

          });
          counter ++;
        });

        subs.unsubscribe();

        console.log("COMENSALES",this.comensales);
        this.orden.totalOrden = totalGeneral;
        this.totalAPagar = this.orden.totalOrden+this.orden.propina;

        //Revisar
        let generalProd = [];
        for (let w = 0; w < this.comensales.length; w++) {
          const comen = this.comensales[w];
          console.log("comen", comen)
          for (let m = 0; m < comen.productos.length; m++) {
            const produc = comen.productos[m];
            console.log("produc", produc);
            generalProd.push(produc);
          }
        }
        this.productosGenerales = generalProd;
        console.log("productosGenerales", this.productosGenerales)
       this.isSaldada=this.CheckSaldo();

      },500);



    });


    this.permisoModificarOrden =this.config.CheckPermiso(this.config.permisoRestModificarOrden);
  }
  GetIdErp(uid): Promise<Usuario> {
    return new Promise((resolve, reject) => {
      console.log('buscando id',uid);

      this.firebaseService.GetUser_ByUid(uid).subscribe((user) => {
        if (user) {
          console.log('user encontrado',user);
          resolve(user[0]);
        }
        else {
          reject(false);
        }
      })
    });
  }
  async presentModalCobrar() {
    const modal = await this.modalControl.create({
      component: ModalCobrarCuentaComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"verOrdenClass",
      componentProps:{orden:this.orden}
    });
    return await modal.present();
  }
 async GetDatosPagos() {
        let misDatosPagos = [];
        await this.firebaseService.getDatosPago(this.orden.id).subscribe(data => {
        this.listaCobros = data.map( e =>{
          var tempData: any;
          tempData = e.payload.doc.data();
          return {
          id:e.payload.doc.id,
          nombre_pago:tempData.nombre_pago,
          forma_pago:tempData.forma_pago,
          cantidad_pagada:tempData.cantidad_pagada,
          comensal:tempData.comensal,
          cambio:tempData.cambio,
          descripcion:tempData.descripcion
          } as DatosPago;
        });
        // misDatosPagos.push(product);
    });

      this.listaCobros=misDatosPagos;
      console.log("listaCobros",this.listaCobros);
  }

  totalCuentaTicket(orden){
    let dato = "";

    if(orden.propina == 0){
      if(orden.status === this.config.StatusEnviadaPar || orden.status === this.config.StatusCerrada || orden.status === this.config.StatusEnviada || orden.status === this.config.StatusRechazada){
        dato = "$" + this.FormatNumber(orden.totalOrden+orden.propina);
      }
      else{
        dato = "$______________________________";
      }
    }
    else{
      dato = "$" + this.FormatNumber(orden.totalOrden+orden.propina);
    }

    return dato;
  }

  propinaCuentaTicket(orden){
    let dato = "";

    if(orden.propina == 0){
      if(orden.status === this.config.StatusEnviadaPar || orden.status === this.config.StatusCerrada || orden.status === this.config.StatusEnviada || orden.status === this.config.StatusRechazada){
        dato = "$" + this.FormatNumber(orden.propina);
      }
      else{
        dato = "$______________________________";
      }
    }
    else{
      dato = "$" + this.FormatNumber(orden.propina);
    }

    return dato;
  }

  FormatNumber(number){
    if(typeof number === 'undefined')
    {return '00.00'}
    // let parts = number.toString().split(".")
    // //console.log(parts);
    // var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return number.toFixed(2);
  }
  WaitForUserData(uid): Promise<UserAdmin> {
    return new Promise((resolve, reject) => {
      this.firebaseService.GetUserAdmin_ByUid(uid).subscribe((user) => {
        if (user) {
          resolve(user[0]);
        }
        else {
          reject(false);
        }
      })
    });
  }

async entregarPedidoModal(pedido){
  this.dismissModal();
  const modal = await this.modalControl.create({
    component: ModalPedidoEntregadoComponent,
    showBackdrop: true,
    backdropDismiss:true,
    animated: true,
    cssClass:"pedidoEntregadoModal",
    componentProps:{orden:this.orden,pedido:pedido}
  });

  return await modal.present();
}
async presentEditDatosPagoModal(item,index) {
  console.log('this.listaCobros',this.listaCobros);

  const modal = await this.modalControl.create({
    component: EditarDatosPagadosComponent,
    showBackdrop: true,
    backdropDismiss:true,
    animated: true,
    cssClass:"verOrdenClass",
    componentProps:{orden:this.orden, listaDatos:this.listaCobros, index:index}
  });

  modal.onDidDismiss().then((data) => {

      console.log("cerro modal");
      this.GetDatosPagos();
      this.isSaldada=this.CheckSaldo();

  })
return await modal.present();

}

async guardarTicket()
{
  this.user_ticket= await this.GetIdErp(this.orden.created_by);

  this.fecha = new Date();
  this.day = this.fecha.getDate();
  this.month = this.fecha.getMonth() + 1;
  this.year = this.fecha.getFullYear();
  this.hora = this.fecha.getHours();
  this.minutos=this.fecha.getMinutes();


  let ticket: Ticket;
  ticket = {
    id_usuario:this.userNombre.id,
    status_orden:this.orden.status,
    id_orden:this.orden.id,
    fecha:this.day+"/"+this.month+"/"+this.year,
    hora:this.hora+":"+this.minutos,

  }

       let respueta= await this.firebaseService.AddTicket(ticket);
       const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

       var idTicket =  (await respueta.get()).id;
       let movUser: MovementUsers;
       movUser = {
          tipo: "tickets",
          id_objeto:  idTicket,
          id_usuario: this.userUID,
          accion: "crear",
          movimiento: "Creando ticket",
          original:null ,
          nuevo: ticket,
          fecha:timeStamp_cer
        }
       await this.firebaseService.AddMovementUsers(movUser);






        //this.recargarPagina();

}
setNewPropina(event){
  console.log(event.target.value)
  if(isNaN(event.target.value) || event.target.value === ""){
    this.nuevaPropina=parseFloat("0");
  }
  else{
    this.nuevaPropina=parseFloat(event.target.value);
  }
}
startEditPropina(){
  this.showEditPropina=true;
  this.nuevaPropina = this.orden.propina
}
async saveEditPropina(){
  this.showEditPropina=false;
  this.orden.propina = this.nuevaPropina;
  console.table(this.orden);
  this.totalAPagar = this.orden.totalOrden+this.orden.propina;
  this.isSaldada=this.CheckSaldo();
  console.log('this.isSaldada',this.isSaldada);

  await this.firebaseService.updateOrden(this.orden);
}

CheckSaldo(){
  let totalPagado=0;
  this.listaCobros.forEach(el => {
    totalPagado+=parseFloat(el.cantidad_pagada);
  });
  console.log('this.totalAPagar',this.totalAPagar);
  console.log('totalPagado',totalPagado);


  return totalPagado>=this.totalAPagar;
}
async recargarPagina()
{
  window.location.reload();
}

async AwaitPedidosCocinaByPedido(id_pedido: string): Promise<comandasPreparacion[]> {
  return new Promise((resolve, reject) => {
    this.observableCocina = this.firebaseService.getAllPedidosByIdPedido(id_pedido);

    this.observableCocina.subscribe(data => {
      if (data) {
        resolve(data);
      } else {
        resolve(null);
      }
    })
  })
}

async AwaitPedidosBarraByPedido(id_pedido: string): Promise<comandasPreparacionBarra[]> {
  return new Promise((resolve, reject) => {
    this.observableBarra = this.firebaseService.getAllPedidosByIdPedidoBarra(id_pedido);

    this.observableBarra.subscribe(data => {
      if (data) {
        resolve(data);
      } else {
        resolve(null);
      }
    })
  })
}

async editModal(pedido){
  console.log('editModal ',pedido);

  this.dismissModal();
    const modal = await this.modalControl.create({
      component: ModalEditComandaComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"aceptarCuentaModal",
      componentProps:{orden:this.orden,pedido:pedido}
    });

    return await modal.present();
}
  async borrarComanda(pedido){
  let ped = await this.AwaitPedidosCocinaEstatus(pedido.id);
  let pedbarra = await this.AwaitPedidosBarraEstatus(pedido.id);
  if(ped.length != 0 && !this.permisoModificarOrden){
    await this.alertService.errorLoading('¡No se puede borrar la comanda porque hay productos en la cocina o ya se entregaron! Revise y intente de nuevo', 5000);
    return;
  }

  if(pedbarra.length != 0 && !this.permisoModificarOrden){
    await this.alertService.errorLoading('¡No se puede borrar la comanda porque hay productos en la barra o ya se entregaron! Revise y intente de nuevo', 5000);
    return;
  }
  const respuesta = await this.alertService.AlertConfirm("Orden","¿Desea eliminar la comanda?");
  if(respuesta==false){
    return;
  }
  this.id_pedido_to_delete=pedido.id;
  await this.PresentLoading("Guardando Pedido",2000);

  let cocina = await this.AwaitPedidosCocinaByPedido(pedido.id);

  for (let z = 0; z < cocina.length; z++) {
    const dato = cocina[z];
    dato["estado"] = "Borrado";
    await this.firebaseService.UpdateComandasPreparacion(dato);
  }

  let barra = await this.AwaitPedidosBarraByPedido(pedido.id);

  for (let z = 0; z < barra.length; z++) {
    const dato = barra[z];
    dato["estado"] = "Borrado";
    await this.firebaseService.UpdateComandasPreparacionBarra(dato);
  }

  // return;
  pedido?.productos[0].forEach(async prodcuto => {

    await this.firebaseService.deleteItemOrden(prodcuto,this.orden.id,pedido.id)
  });

  setTimeout(()=>{
    if (pedido?.productos[0].length>0) {
      this.guardarPedido(pedido);
      this.dismissModal();

    }
  },500)

}
async guardarPedido(pedido){
  const pedidoOrg=pedido;
  const ordenOrg=this.orden;
  let productos=pedido?.productos[0];


  let totalPedido = 0;
  let comensales=this.orden.comensales;
  comensales.forEach(element => {
    element.total = 0;
  });
  productos.forEach(element => {
      var total = (element.precio * element.cantidad);
      totalPedido += total;
      element.total = total;
      comensales.forEach(item => {
        if(element.comensal == item.nombre){
          item.total += total;
        }
      });
  });
  this.orden.totalOrden = parseFloat(this.orden.totalOrden) - (totalPedido);
   await this.firebaseService.updateOrden(this.orden);
   const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

   let ordeData: MovementUsers;

   ordeData = {
     tipo: "ordenes",
     id_objeto: ordenOrg.id,
     id_usuario: this.userDatos.uid,
     accion: "actualizar",
     movimiento: "Actualizar orden",
     original:ordenOrg,
     nuevo: this.orden,
     fecha: timeStamp_cer

   }
   await this.firebaseService.AddMovementUsers(ordeData);


   pedido.status = this.config.StatusInactiva;

   await this.firebaseService.updatePedido(this.orden,pedido);
   let ordeData2: MovementUsers;

  //  console.log("pedido---",pedidoOrg);

   ordeData2 = {
     tipo: "ordenes-pedidos",
     id_objeto:this.id_pedido_to_delete,
     id_usuario: this.userDatos.uid,
     accion: "actualizar",
     movimiento: "Actualizar pedido",
     original:pedidoOrg,
     nuevo: pedido,
     fecha: timeStamp_cer


   }
   await this.firebaseService.AddMovementUsers(ordeData2);
   this.DismissLoading();


 }

 async presentModalNotas(pedido, prod, estatus) {
  this.notaProducto = "";
  const modal = await this.modalControl.create({
    component: ModalNotaProductoComponent,
    showBackdrop: true,
    backdropDismiss:true,
    animated: true,
    componentProps:{producto: prod, proceso: "Confirmar", estatus: estatus},
    cssClass:"aceptarComandaModal"
  });
  
  await modal.present();

  return await modal.onDidDismiss().then((data) => {
    this.notaProducto = data;

    if(this.notaProducto.role != "Cerrar" && this.notaProducto.role != "backdrop"){
      prod.anotacion = this.notaProducto.data;
      this.firebaseService.updateItemOrden(prod, this.orden.id, pedido.id)
    }
  })
}

async showNota(prod, i){
  const modal = await this.modalControl.create({
    component: ModalNotaProductoComponent,
    showBackdrop: true,
    backdropDismiss:true,
    animated: true,
    componentProps:{producto: prod, proceso: "Confirmar", estatus: "Ver"},
    cssClass:"aceptarComandaModal"
  });
  
  await modal.present();

  let proceso;
  await modal.onDidDismiss().then((data) => {
    proceso = data;
  })

  if(proceso.role != "Cerrar" && proceso.role != "backdrop"){
    prod.anotacion = proceso.data;
  }
 }

async dividirModal(pedido,producto){//Cocina posiblemente
  this.dismissModal();
    const modal = await this.modalControl.create({
      component: ModalDividirProductoComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"aceptarCuentaModal",
      componentProps:{orden:this.orden,pedido:pedido,producto:producto}
    });

    return await modal.present();
}

async AwaitPedidosCocinaEstatus(id_orden: string): Promise<comandasPreparacion[]> {
  return new Promise((resolve, reject) => {
    this.observableCocina = this.firebaseService.getAllPedidosByIdPedidoEstatus(id_orden);

    this.observableCocina.subscribe(data => {
      if (data) {
        resolve(data);
      } else {
        resolve(null);
      }
    })
  })
}

async AwaitPedidosBarraEstatus(id_orden: string): Promise<comandasPreparacionBarra[]> {
  return new Promise((resolve, reject) => {
    this.observableBarra = this.firebaseService.getAllPedidosByIdPedidoEstatusBarra(id_orden);

    this.observableBarra.subscribe(data => {
      if (data) {
        resolve(data);
      } else {
        resolve(null);
      }
    })
  })
}

async AwaitPedidosCocina(id_orden: string): Promise<comandasPreparacion[]> {
  return new Promise((resolve, reject) => {
    this.observableCocina = this.firebaseService.getAllPedidosByIdOrden(id_orden);

    this.observableCocina.subscribe(data => {
      if (data) {
        resolve(data);
      } else {
        resolve(null);
      }
    })
  })
}

async AwaitPedidosBarra(id_orden: string): Promise<comandasPreparacionBarra[]> {
  return new Promise((resolve, reject) => {
    this.observableBarra = this.firebaseService.getAllPedidosByIdOrdenBarra(id_orden);

    this.observableBarra.subscribe(data => {
      if (data) {
        resolve(data);
      } else {
        resolve(null);
      }
    })
  })
}

async presentModalAceptarCuenta() {
  let cocina = await this.AwaitPedidosCocina(this.orden.id);
  let barra = await this.AwaitPedidosBarra(this.orden.id);
  if(cocina.length != 0){
    await this.alertService.errorLoading('¡No se puede aceptar la cuenta porque hay productos en la cocina! Revise y intente de nuevo', 5000);
    return;
  }

  if(barra.length != 0){
    await this.alertService.errorLoading('¡No se puede aceptar la cuenta porque hay productos en la barra! Revise y intente de nuevo', 5000);
    return;
  }

  this.dismissModal();
    const modal = await this.modalControl.create({
      component: ModalAceptarCuentaComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"aceptarCuentaModal",
      componentProps:{orden:this.orden}
    });

    return await modal.present();
  }

  displayTicketText(text, count, insertDots){
    return text.slice(0, count) + (((text.length > count) && insertDots) ? "..." : "");
  }
agregarPedido(){
  this.modalControl.dismiss();
  this.router.navigate(['/ordenAgregarProductos'],{queryParams:{id_orden:this.orden.id}});
}

toggleCat(categoria){
    var x = document.getElementById(categoria+this.orden.id);

    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  toggleComensales(categoria){
    var x = document.getElementById(categoria+"comensales");

    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }
   async dismissModal(){

    this.modalControl.dismiss();
   }

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }
}

interface PedidoData{
  estado:string,
  productos: any[]
}
