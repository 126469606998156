import { AuthService } from 'src/services/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, ItemOrden, Mesa, MovementUsers, Orden } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalController} from '@ionic/angular';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-modal-agregar-comensal',
  templateUrl: './modal-agregar-comensal.component.html',
  styleUrls: ['./modal-agregar-comensal.component.scss'],
})
export class ModalAgregarComensalComponent implements OnInit {

  @Input("titulo") titulo;
  @Input("orden") orden;

  prods:any;

  comensalForm:FormGroup;

  userUID: string;
  today: string;

  constructor(
    private auth:AuthService,
    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController,
    private builder: FormBuilder
  ) {


    this.today = moment().format('YYYY-MM-DD');

  }

 async ngOnInit() {
    console.log("DATA PARAMS",this.orden.comensales);

    this.comensalForm = this.builder.group({
      nombre: ['',Validators.required],
    });


    var data = await this.auth.GetCurrentUser();

    this.userUID = data.uid;

    console.log("USER ID", this.userUID);
  }


  closeModal(){
    this.modalControl.dismiss();
  }


 async agregarComensal(){
   await this.PresentLoading("Agregando Comensal",1000);
   const ordenOrg =this.orden;
    var comensal = {
      nombre: this.comensalForm.controls.nombre.value,
      total: 0,
      productos:[]
    }

    this.orden.comensales.push(comensal);

    await this.firebaseService.updateOrden(this.orden);

    let ordeData2: MovementUsers;
    const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

    ordeData2 = {
      tipo: "ordenes-comensales",
      id_objeto:this.orden.id,
      id_usuario: this.userUID,
      accion: "actualizar",
      movimiento: "Agragar comensal a orden",
      original:ordenOrg ,
      nuevo: this.orden,
      fecha: timeStamp_cer

    }
    await this.firebaseService.AddMovementUsers(ordeData2);
    this.modalControl.dismiss();

    this.DismissLoading();

  }

   //this.modalControl.dismiss();

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }

}
