import { ModalEditAreaComponent } from './../modal-edit-area/modal-edit-area.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AreasComponent } from './areas.component';



@NgModule({
  declarations: [AreasComponent, ModalEditAreaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class AreasModule { }
