import { ModalEditMesaComponent } from './../modal-edit-mesa/modal-edit-mesa.component';
import { ModalConfirmDeleteComponent } from './../modal-confirm-delete/modal-confirm-delete.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MesasComponent } from './mesas.component';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [MesasComponent, ModalConfirmDeleteComponent,ModalEditMesaComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ]
})
export class MesasModule { }
