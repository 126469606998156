import { Pedido } from './../../services/firebase-data.service';
import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalController} from '@ionic/angular';

@Component({
  selector: 'app-modal-propina',
  templateUrl: './modal-propina.component.html',
  styleUrls: ['./modal-propina.component.scss'],
})
export class ModalPropinaComponent implements OnInit {

  @Input("orden") orden;
  porcentajePropina: any;

  propinaTotal : any;

  disabledInput = false;

  totalOrden :any;

  modoPropina: boolean;
  constructor(
    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController
  ) {

  }

  ngOnInit() {
    this.modoPropina = true;
    this.porcentajePropina = 0;
    this.propinaTotal = 0;
    this.totalOrden = this.orden.totalOrden;
  }

  changePropina(event){

    const value = event.detail.value;
    if(value != 'otra'){
      this.disabledInput = false;
      this.porcentajePropina = value;
      this.propinaTotal = this.orden.totalOrden * (this.porcentajePropina / 100);
      this.totalOrden = this.propinaTotal +this.orden.totalOrden;
    }else{
      this.disabledInput = true;
    }


  }

  changeModoPropina(){
    if(!this.modoPropina){
      this.modoPropina = true;
      this.propinaTotal = parseFloat(this.porcentajePropina);
      this.totalOrden = parseFloat(this.totalOrden);
      this.totalOrden = (this.propinaTotal +this.orden.totalOrden);
      console.log(this.totalOrden);
    }else{
      this.modoPropina = false;
      this.propinaTotal = this.orden.totalOrden * (this.porcentajePropina / 100);
      this.totalOrden = this.propinaTotal +this.orden.totalOrden;
    }
  }

  FormatNumber(number){
    let parts = number.toString().split(".")
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return num
  }
  changePropinaPers(event){
    const value = event.detail.value;

    this.porcentajePropina = value;

    if(!this.modoPropina){

      this.propinaTotal = this.orden.totalOrden * (this.porcentajePropina / 100);
      this.totalOrden = this.propinaTotal +this.orden.totalOrden;
    }else{

      this.propinaTotal = parseFloat(this.porcentajePropina);
      this.totalOrden = parseFloat(this.totalOrden);
      this.totalOrden = (this.propinaTotal +this.orden.totalOrden);

      console.log(this.totalOrden);
    }


  }

 async aceptarPropina(){
    this.orden.propina = this.propinaTotal;
    await this.firebaseService.updateOrden(this.orden);
    this.dismissModal();
  }

  async PresentLoading(mensaje, duracion) {
    const loading = await this.loadingController.create({
      message: mensaje,
      duration: duracion,
      spinner: 'bubbles'
    });
    await loading.present();
    return loading;
  }

  DismissLoading() {
   this.loadingController.dismiss();
 }

 async dismissModal(){

  this.modalControl.dismiss();
 }

}
