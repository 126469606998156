import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
// import { firebase } from '@firebase/app';
// import * as auth from 'firebase/auth';
// import { auth } from 'firebase';
// import * as firebase from 'firebase/app';
//import * as firebase from 'firebase/app'; import 'firebase/auth';
import firebase from 'firebase';
import { UserDataService } from './user-data.service';
import { AlertsService } from './alerts.service';
import { Router } from '@angular/router';
import { environment } from "../environments/environment";
// import * as admin from 'firebase-admin';
// import  credential from './firebase_admin_credentials/hoteles-6d5d6-firebase-adminsdk-jqdno-3fadab63e0.json';

// var admin = require("firebase-admin");

// var serviceAccount = require("path/to/serviceAccountKey.json");

// admin.initializeApp({
//     credential: admin.credential.cert(serviceAccount),
//     databaseURL: "https://hoteles-6d5d6.firebaseio.com"
// });

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    secondaryAuthRef: any; // used as a secondary reference to register user and prevent auto login after register
    accountSuffix = "@hotelgrandprix.com";
    constructor(
        public fireAuth: AngularFireAuth,
        private userDataService: UserDataService,
        private alertService: AlertsService,
        private router: Router
    ) {
        this.secondaryAuthRef = firebase.initializeApp(environment.firebaseConfig, "secondary");

        //     fetch('./assets/data/datajson.json').then(res => res.json())
        //         .then(json => {
        //             this.data = json;
        //         });
        // console.log(this.credentialsJson);

    }


    async ngOnInit() {
        // await this.AuthPersistence();
        console.log(this.secondaryAuthRef);

    }


    async GenerateResetPasswordLink(username: string) {
        // const link = await admin.auth().generatePasswordResetLink(username);
        // console.log(link);
    }

    // ?
    async Login(form) {
        // const res = await firebase.auth().signInWithPopup(new firebase.auth.EmailAuthProvider);
        await this.AuthPersistence();
        const res = await firebase.auth().signInWithEmailAndPassword(form.email, form.password);
        return res;
    }

    async ResetPassword(username: string, oldPassword: string, newPassword: string) {
        const user = firebase.auth().currentUser;

        const credentials = await firebase.auth.EmailAuthProvider.credential(username + this.accountSuffix, oldPassword);
        const reautenticate = await firebase.auth().currentUser.reauthenticateWithCredential(credentials);
        console.log(reautenticate);

        const reset = await user.updatePassword(newPassword);
        return reset;
    }

    async UpdateEmail(newEmail: string) {
        const response = await firebase.auth().currentUser.updateEmail(newEmail);
        return response;
    }

    // ? called in app.component.ts
    async AuthPersistence() {
        // await firebase.auth().setPersistence('local');
        // await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        console.log("this-- local");
        await this.fireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        //await this.fireAuth.setPersistence('local');
        console.log('Persistance Auth to LOCAL');
    }

    async GetCurrentUser(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.fireAuth.authState.subscribe(data => {
                if (data) {
                    resolve(data)
                } else {
                    resolve(false);
                }
            })
        });

    }

    async getUser() : Promise<any>{
        return new Promise((resolve, reject) => {
            resolve(this.fireAuth.currentUser);
        });
        console.log(this.fireAuth.currentUser);

    }

    async Register(form) {
        const res = await this.secondaryAuthRef.auth().createUserWithEmailAndPassword(form.email, form.password);
        await this.secondaryAuthRef.auth().signOut();
        return res;
    }

    async Logout() {
        await this.fireAuth.signOut();
        this.userDataService.SignOut();
        this.alertService.presentToast('Cerraste Sesión');
        this.router.navigate(['/login']);
    }




    // ? Guard login prevent to go to pages if not logged in
    //     import { AngularFireAuthGuard } from '@angular/fire/auth-guard';

    // export const routes: Routes = [
    //     { path: '',      component: AppComponent },
    //     { path: 'items', component: ItemListComponent, canActivate: [AngularFireAuthGuard] },
    // ]

    async ResetPasswordGuest(email: string, oldPassword: string, newPassword: string) {
        const user = firebase.auth().currentUser;

        const credentials = await firebase.auth.EmailAuthProvider.credential(email, oldPassword);
        console.log(credentials)
        try {
            const reautenticate = await firebase.auth().currentUser.reauthenticateWithCredential(credentials);
            console.log(reautenticate);
        } catch (error) {
            return "Error";
        }

        const reset = await user.updatePassword(newPassword);
        return reset;
    }

}
