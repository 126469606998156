import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl,FormGroup, Validators  } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import axios, { AxiosRequestConfig } from 'axios';
import moment from 'moment';
import { AuthService } from 'src/services/auth.service';
import { ConfigService } from 'src/services/config.service';
import { DatosPago, FirebaseDataService, MovementUsers } from 'src/services/firebase-data.service';

@Component({
  selector: 'app-editar-datos-pagados',
  templateUrl: './editar-datos-pagados.component.html',
  styleUrls: ['./editar-datos-pagados.component.scss'],
})
export class EditarDatosPagadosComponent implements OnInit {
  @Input("listaDatos") listaDatos;
  @Input("index") index;
  @Input("orden") orden;

  titulo:any;
  newList:any [];
  editForm:FormGroup;
  datacobro: any;
  tipoPago: any;
  nombre_pago:any;
  userDatos: any;
  today:any;
  totalAPagar: any;



  constructor(
    private formBuilder: FormBuilder,
    private modalControl: ModalController,
    private firebaseService:FirebaseDataService,
    public config: ConfigService,
    private authService: AuthService,


    ) {
    this.titulo='Editar Datos de Pagos';
    this.today = moment().format('YYYY-MM-DD');

    this.editForm = this.formBuilder.group({
      cantidad:['', [Validators.required]],
      desc:['',Validators.required],
    });
   }

  async ngOnInit() {
    this.userDatos=await this.authService.GetCurrentUser();
    await this.GetFormasPago();
    this.newList=[...this.listaDatos];
    this.editForm = this.formBuilder.group({
      cantidad:[this.newList[this.index].cantidad_pagada, [Validators.required]],
      desc:[this.newList[this.index].descripcion,Validators.required],
    });
    //this.changeFormaPago({detail:{value:2}});
     this.tipoPago= this.datacobro[this.datacobro.findIndex(object =>{return object.id == this.newList[this.index].forma_pago})]; //this.newList[this.index].forma_pago;
    console.log("Pay",this.tipoPago);
    this.nombre_pago = this.tipoPago.Nombre;

  }
  ngAfterContentChecked(){

     //this.tipoPago= this.listaDatos[this.index].forma_pago;

  }


  async GetFormasPago(search: string = "") {
    try {
        const config: AxiosRequestConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'multipart/form-data',
            },
        }
        const url =this.config.Api('get-formas-pago?token='+this.config.tokenApi+`&busqueda=${search}`);
        //`${this.protocol}://erp.konsultor.mx/get-formas-pago?token=${this.token}&busqueda=${search}`;
        const response = await axios.get(url, config);
        this.datacobro = response.data;
        console.log("COBROS",this.datacobro);


        return response.data;
    } catch (error) {
        console.log(error);
    }
}
  async UpdateListaDatos(){
    try {
      let datosPago : DatosPago;
      const fecha = new Date();
      const hora =fecha.getHours();
      const minutos =fecha.getMinutes();
      const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');
      datosPago = {
        id: this.newList[this.index].id,
        forma_pago :this.tipoPago.id,
        nombre_pago: this.nombre_pago,
        cantidad_pagada:parseFloat(this.editForm.controls.cantidad.value),
        cambio: this.newList[this.index].cambio,
        comensal: this.newList[this.index].comensal,
        descripcion:this.editForm.controls.desc.value,
        created_by:this.newList[this.index]?.created_by?this.newList[this.index]?.created_by:'',
        created_date:this.newList[this.index]?.created_date?this.newList[this.index]?.created_date:'',
        edit_by:this.orden.close_by,
        edit_date:this.orden.close_date
      } as DatosPago;
      console.log("Actualizar2222",datosPago);
      this.newList[this.index]=datosPago;
      const {canSave,cambio} =this.CheckCambio();
      if (!canSave) {
        alert("No cubre el total a pagar, ingrese una cantidad correcta");
        return
      }
      datosPago.cambio=cambio;
      this.newList[this.index]=datosPago;
      this.newList.forEach(async el=>{
        await this.firebaseService.updateDatosPago(el,this.orden.id);
      });
      console.log("Actualizar2222",datosPago);


      let datosPagooriginal : DatosPago;

      datosPagooriginal = {
        id: this.listaDatos[this.index].id,
        forma_pago :this.listaDatos[this.index].forma_pago,
        nombre_pago: this.listaDatos[this.index].nombre_pago,
        cantidad_pagada:this.listaDatos[this.index].cantidad_pagada,
        cambio: this.listaDatos[this.index].cambio,
        comensal: this.listaDatos[this.index].comensal,
        descripcion:this.listaDatos[this.index].descripcion,
        created_by:this.listaDatos[this.index]?.created_by,
        created_date:this.listaDatos[this.index]?.created_date,
        edit_by:this.userDatos.uid,
        edit_date:timeStamp_cer
      } as DatosPago;

      let userData: MovementUsers;
      userData = {
        tipo: "datos-pagos",
        id_objeto:  this.newList[this.index].id,
        id_usuario: this.userDatos.uid,
        accion: "actualizar",
        movimiento: "Actualizar datos pagos",
        original: datosPagooriginal,
        nuevo: datosPago,
      fecha: timeStamp_cer

      }
      await this.firebaseService.AddMovementUsers(userData);

      setTimeout(()=>{
        this.closeModal();

      },500)
    } catch (e) {
      this.closeModal();
    }

  }
  async changeFormaPago(event){
    const value = event.detail.value;
    console.log("select",value);
    this.tipoPago = value;
    this.nombre_pago = this.tipoPago.Nombre;
  }



  closeModal(){
    this.modalControl.dismiss();
  }
  CheckCambio() {
    let totalPagado=0;
    this.totalAPagar = this.orden.totalOrden+this.orden.propina;
    this.newList=this.newList.map(pago =>{
      totalPagado+=pago.cantidad_pagada;
      pago.cambio=0;
      return pago;
    } );
    console.log('totalPagado',totalPagado);
    console.log('this.totalAPagar',this.totalAPagar);
    console.log('this.newList',this.newList);

    return {canSave:totalPagado>=this.totalAPagar,cambio:totalPagado-this.totalAPagar};
  }

}


