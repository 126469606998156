import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden, comandasPreparacion, comandasPreparacionBarra } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ModalController} from '@ionic/angular';

@Component({
  selector: 'app-modal-delete-producto-resumen',
  templateUrl: './modal-delete-producto-resumen.component.html',
  styleUrls: ['./modal-delete-producto-resumen.component.scss'],
})
export class ModalDeleteProductoResumenComponent implements OnInit {

  @Input("mensajeConfirmacion") mensajeConfirmacion;
  @Input("productos") productos;
  @Input("indexProd") indexProd;
  @Input("tipo") tipo;
  @Input("data") data;


  prods:any;
  public observableCocina: Observable<comandasPreparacion[]>;
  public observableBarra: Observable<comandasPreparacionBarra[]>;

  constructor(
    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController
  ) { }

  ngOnInit() {
    console.log("DATA PARAMS",this.productos);

  }


  closeModal(){
    this.modalControl.dismiss();
  }

  async AwaitPedidosCocinaByPedidoByProducto(id_pedido: string, id_producto: string): Promise<comandasPreparacion[]> {
    return new Promise((resolve, reject) => {
      this.observableCocina = this.firebaseService.getPedidosByIdPedidoAndProducto(id_pedido, id_producto);
  
      this.observableCocina.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async AwaitPedidosBarraByPedidoByProducto(id_pedido: string, id_producto: string): Promise<comandasPreparacionBarra[]> {
    return new Promise((resolve, reject) => {
      this.observableBarra = this.firebaseService.getPedidosByIdPedidoAndProductoBarra(id_pedido, id_producto);
  
      this.observableBarra.subscribe(data => {
        if (data) {
          resolve(data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async deleteItem(){

    this.productos.splice(this.indexProd,1);
    if(this.data.tipo == "edit"){
      let cocina = await this.AwaitPedidosCocinaByPedidoByProducto(this.data.pedidoID, this.data.producto.id_producto);
      let barra = await this.AwaitPedidosBarraByPedidoByProducto(this.data.pedidoID, this.data.producto.id_producto);
      if(cocina.length > 0){
        cocina[0]["estado"] = "Borrado";
        await this.firebaseService.UpdateComandasPreparacion(cocina[0]);
      }
      else{
        barra[0]["estado"] = "Borrado";
        await this.firebaseService.UpdateComandasPreparacionBarra(barra[0]);
      }
      this.firebaseService.deleteItemOrden(this.data.producto,this.data.ordenID,this.data.pedidoID)
    }
    this.closeModal();
 }

   //this.modalControl.dismiss();

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }

}
