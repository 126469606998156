import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UserAdmin, Usuario } from 'src/services/firebase-data.service';
import * as CryptoJS from 'crypto-js';
import { AppComponent } from 'src/app/app.component';


// import { User } from './firebase-users.service';

// ---------------------------------- ENCRYPT/DECRYPT -----------------------------------
@Injectable()
export class CryptoClass {
  private secretKey = 'Gur4Nd0Pr!x_Ho73ru';
  constructor() { }

  Encrypt(dataToEncrypt: any) {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(dataToEncrypt), this.secretKey).toString();
    // //console.log(encryptedData);
    return encryptedData;
  }
  /**
   *
   * @param encryptedData data must be string
   */
  Decrypt(encryptedData: string) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
    const obj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    // //console.log(obj);
    return obj;
  }
}



@Injectable()
export class UserDataService {
  public appInstance: AppComponent;
    currentuser: Usuario;
    isLogged: boolean = false;

    SetUser(user: Usuario) {
        this.currentuser = user;
        this.isLogged = true;
    }

    // ? called in app.component.ts
    // ? commentado donde se manda a llamar
    ShowInfo() {
        console.log('User Info:', this.currentuser);

        console.log('Is Logged:', this.isLogged);
    }

    ClearData_Logout(){
        this.currentuser = null;
        this.isLogged = false;
    }

    // ! true for testing only called in app.compontent.ts
    FillWithData() {
        let userData: Usuario;
        /*userData = {
            uid: '40EW4WsdLyMpMIr5Qa1MLDUeOXl2',
            active: true,
            email: 'pulasio_yxnnatu_maltato',
            name: 'pulasio maltato',
        }*/
        this.isLogged = true;
        this.currentuser = userData;
        return this.currentuser;
    }


  SignOut() {
    this.isLogged = false;
    this.currentuser = null;
    //this.permissions = undefined;
  }

    currentUser: Usuario;
    async SetUserAdminData(user: Usuario) {
      this.isLogged = true;
      this.currentUser = user;

      //this.permissions = this.EmptyPermissions();
      //this.SetPermission(this.currentUser.permisos);

      console.log('logged as: ', this.currentUser);
      //console.log('Permissions: ', this.permissions);
    }

  async WaitUserData() {
    return new Promise((resolve, reject) => {
      var tries = 5;
      var count = 0;
      var interval = setInterval(() => {
        if (this.isLogged) {
          clearInterval(interval);
          resolve(true);
        }
        count++;
        if (count >= tries) {
          clearInterval(interval);
          resolve(false);
        }
      }, 300);
    });

  }
}

@Injectable()
export class PasswordValidation {
    //
    static MatchPassword(AC: AbstractControl) {
        const password = AC.get('password').value; // to get value in input tag
        const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag
        if (password !== confirmPassword) {
            // console.log('false');
            AC.get('confirmPassword').setErrors({ MatchPassword: true });
        } else {
            // console.log('true');
            return null;
        }
    }
}

// @Injectable()
// export class Crypto {
//     private secretKey = 'formApp_key';
//     constructor() { }

//     Encrypt(dataToEncrypt: any) {
//         const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(dataToEncrypt), this.secretKey).toString();
//         // console.log(encryptedData);
//         return encryptedData;
//     }
//     /**
//      *
//      * @param encryptedData data must be string
//      */
//     Decrypt(encryptedData: string) {
//         const bytes = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
//         const obj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//         // console.log(obj);
//         return obj;
//     }
// }
