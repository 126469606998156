// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    //test
    // apiKey: "AIzaSyD4pl8Su6fp2t392Vc9Aw2TYBM1oZ4QlTM",

    // authDomain: "restaurant-test-35098.firebaseapp.com",

    // projectId: "restaurant-test-35098",

    // storageBucket: "restaurant-test-35098.appspot.com",

    // messagingSenderId: "748954445916",

    // appId: "1:748954445916:web:9dcafef418e408d7b2de99",

    // measurementId: "G-3M4FYNLZPT"
    //test

    //grand cafe
    // apiKey: "AIzaSyAtIMJzuReh0Kf7OCKQSM5Ku-fG_4TjcN8",

    // authDomain: "grand-cafe-d779a.firebaseapp.com",

    // projectId: "grand-cafe-d779a",

    // storageBucket: "grand-cafe-d779a.appspot.com",

    // messagingSenderId: "810265275243",

    // appId: "1:810265275243:web:1a825b0015ebec5fa8fa2e",
    
    // measurementId: "G-ZZL0126R7L"
    //grand cafe

    //monaco
    apiKey: "AIzaSyAUD9qkfPEZSk1vMHzPOeC_qaiiXRdyRvk",

    authDomain: "restaurant-monaco-01-prod.firebaseapp.com",

    projectId: "restaurant-monaco-01-prod",

    storageBucket: "restaurant-monaco-01-prod.appspot.com",

    messagingSenderId: "421949166322",

    appId: "1:421949166322:web:1d0fb078cde2a8e63af66b",

    measurementId: "G-KGCQD5EBS9"
    //monaco
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
