import { Injectable } from '@angular/core';
import { url } from 'inspector';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';

enum Proyecto {
    test = 0,
    grandPrix = 1,
    azulArena = 2,
    grandCafe = 3,
}
enum StatusOrden{
    cerrada="Cerrada",
    rechazada="Rechazada",
    enviada="Enviada",
    enviadaParcial="Enviada_Parcial",
    pagada = "Pagada"
}
enum StatusItem{
    activa="Activa",
    inactiva="Inactiva"
}
enum ListAllPermisos{
    inMenu = "inMenu",
    inClientes = "inClientes",
    inMesas= "inMesas",
    inAreas= "inAreas",
    inRestaurante= "inRestaurante",
    inCuentas= "inCuentas",
    inCuentasEnviadas= "inCuentasEnviadas",
    inReportes= "inReportes",
    inCocina= "inCocina",
    inBarra= "inBarra",
    inProductos= "inProductos",
    inRegistrar= "inRegistrar",
    inConfiguracion= "inConfiguracion",
    inLogs= "inLogs",

    //* Permisos Area
    areaGuardar= "areaGuardar",
    areaEditar= "areaEditar",
    areaBorrar= "areaBorrar",

    //* Permisos Mesas
    mesasGuardar= "mesasGuardar",
    mesasEditar= "mesasEditar",
    mesasBorrar= "mesasBorrar",

    //* Permisos Cuentas
    cuentasVerOrden= "cuentasVerOrden",
    cuentasEnviarOrden= "cuentasEnviarOrden",

    //* Permisos Cuentas Enviadas
    cuentasEnviadaVerOrden= "cuentasEnviadaVerOrden",
    cuentasEnviadaVerParcial= "cuentasEnviadaVerParcial",

    //* Permisos Registrar
    registrarGuardar= "registrarGuardar",
    registrarEditar= "registrarEditar",

    //* Permisos Configuracion
    configClientes= "configClientes",
    configBancos= "configBancos",
    configPropina= "configPropina",
    configAgrupador= "configAgrupador",
    configPermisos= "configPermisos",

    //* Permisos Restaurante
    restAddOrden= "restAddOrden",
    restAddComensal= "restAddComensal",
    restAddPropina= "restAddPropina",
    restVerOrden= "restVerOrden",
    restCambiarMesas= "restCambiarMesas",
    restCobrarOrden= "restCobrarOrden",
    restCerrarOrden= "restCerrarOrden",
    restBorrarOrden= "restBorrarOrden",
    restModificarOrden= "restModificarOrden",

    // addOrden="AddOrden",
    // cobrarOrden="AddOrden",

    //* Permisos productos
    prodVerProductosBarra= "prodVerProductosBarra",
    prodVerProductosCocina= "prodVerProductosCocina",
    prodAddProductos= "prodAddProductos",
    prodDeleteProductos= "prodDeleteProductos",
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    createOnline$() {
        return merge<boolean>(
            fromEvent(window, 'offline').pipe(map(() => false)),
            fromEvent(window, 'online').pipe(map(() => true)),
            new Observable((sub: Observer<boolean>) => {
                sub.next(navigator.onLine);
                sub.complete();
            }));
    }

    // ? CAMBIAR AQUI PARA SELECCIONAR UNO U OTRO PROYECTO
    // * -- environment.ts
    // * -- base URL en index.html
    // * -- title en index.html
    //token prod-> Gr4ndPr1x$
    //token test-> 123456
    //ambiente ->test-production
    protocol = "https"; // ? FOR REQUEST IN ERP SERVICE
    selectProyect: Proyecto = Proyecto.test;
    StatusEnviada:StatusOrden=StatusOrden.enviada;
    StatusRechazada:StatusOrden=StatusOrden.rechazada;
    StatusPagada:StatusOrden=StatusOrden.pagada;
    StatusCerrada:StatusOrden=StatusOrden.cerrada;
    StatusEnviadaPar:StatusOrden=StatusOrden.enviadaParcial;
    StatusActiva:StatusItem=StatusItem.activa;
    StatusInactiva:StatusItem=StatusItem.inactiva;

    /*--------PERMISOS-----------*/

    permisoInMenu:ListAllPermisos=ListAllPermisos.inMenu
    permisoInClientes:ListAllPermisos=ListAllPermisos.inClientes
    permisoInMesas:ListAllPermisos=ListAllPermisos.inMesas
    permisoInAreas:ListAllPermisos=ListAllPermisos.inAreas
    permisoInRestaurante:ListAllPermisos=ListAllPermisos.inRestaurante
    permisoInCuentas:ListAllPermisos=ListAllPermisos.inCuentas
    permisoInCuentasEnviadas:ListAllPermisos=ListAllPermisos.inCuentasEnviadas
    permisoInReportes:ListAllPermisos=ListAllPermisos.inReportes
    permisoInCocina:ListAllPermisos=ListAllPermisos.inCocina
    permisoInBarra:ListAllPermisos=ListAllPermisos.inBarra
    permisoInProductos:ListAllPermisos=ListAllPermisos.inProductos
    permisoInRegistrar:ListAllPermisos=ListAllPermisos.inRegistrar
    permisoInConfiguracion:ListAllPermisos=ListAllPermisos.inConfiguracion
    permisoInLogs:ListAllPermisos=ListAllPermisos.inLogs

    //* Permisos Area
    permisoAreaGuardar:ListAllPermisos=ListAllPermisos.areaGuardar
    permisoAreaEditar:ListAllPermisos=ListAllPermisos.areaEditar
    permisoAreaBorrar:ListAllPermisos=ListAllPermisos.areaBorrar

    //* Permisos Mesas
    permisoMesasGuardar:ListAllPermisos=ListAllPermisos.mesasGuardar
    permisoMesasEditar:ListAllPermisos=ListAllPermisos.mesasEditar
    permisoMesasBorrar:ListAllPermisos=ListAllPermisos.mesasBorrar

    //* Permisos Cuentas
    permisoCuentasVerOrden:ListAllPermisos=ListAllPermisos.cuentasVerOrden
    permisoCuentasEnviada:ListAllPermisos=ListAllPermisos.cuentasEnviarOrden

    //* Permisos Cuentas Enviadas
    permisoCuentasEnviadaVerOrden:ListAllPermisos=ListAllPermisos.cuentasEnviadaVerOrden
    permisoCuentasEnviadaVerParcial:ListAllPermisos=ListAllPermisos.cuentasEnviadaVerParcial

    //* Permisos Registrares
    permisoRegistrarGuardar:ListAllPermisos=ListAllPermisos.registrarGuardar
    permisoRegistrarEditar:ListAllPermisos=ListAllPermisos.registrarEditar

    //* Permisos Configuracion
    permisosConfigClientes:ListAllPermisos=ListAllPermisos.configClientes
    permisosConfigBancos:ListAllPermisos=ListAllPermisos.configBancos
    permisosConfigPropina:ListAllPermisos=ListAllPermisos.configPropina
    permisosConfigAgrupador:ListAllPermisos=ListAllPermisos.configAgrupador
    permisosConfigPermisos:ListAllPermisos=ListAllPermisos.configPermisos

    //* Permisos Restaurante
    permisoRestAddOrden:ListAllPermisos=ListAllPermisos.restAddOrden
    permisoRestAddComensal:ListAllPermisos=ListAllPermisos.restAddComensal
    permisoRestAddPropina:ListAllPermisos=ListAllPermisos.restAddPropina
    permisoRestVerOrden:ListAllPermisos=ListAllPermisos.restVerOrden
    permisoRestCambiarMesas:ListAllPermisos=ListAllPermisos.restCambiarMesas
    permisoRestCobrarOrden:ListAllPermisos=ListAllPermisos.restCobrarOrden
    permisoRestCerrarOrden:ListAllPermisos=ListAllPermisos.restCerrarOrden
    permisoRestBorrarOrden:ListAllPermisos=ListAllPermisos.restBorrarOrden
    permisoRestModificarOrden:ListAllPermisos=ListAllPermisos.restModificarOrden

    //* Permisos Productos
    permisoProdVerProductosCocina:ListAllPermisos=ListAllPermisos.prodVerProductosCocina
    permisoProdVerProductosBarra:ListAllPermisos=ListAllPermisos.prodVerProductosBarra
    permisoProdAddProductos:ListAllPermisos=ListAllPermisos.prodAddProductos
    permisoProdDeleteProductos:ListAllPermisos=ListAllPermisos.prodDeleteProductos

    //Conectar a APIS
    proyectoSis = Proyecto.test;
    obtenerDatosSis = 2;
    origen = 6;
    appName = "";
    baseApiTest= "https://pruebaserp.konsultor.com.mx/";
    baseApiProd= "https://erp.konsultor.com.mx/";
    ambienteDesarrollo='production';//test - production
    tokenApi= "Gr4ndPr1x$"//123456 - Gr4ndPr1x$
    appUrl = "";
    logo = "";
    logoWhite = "";
    token = ""; // token para conectarse al ERP
    emailUrl = ""; // token para conectarse al ERP
    hotelName = "";
    IVA = 0;
    hospedaje = 0;
    tablaSuite = false;
    margenHeight = 0;
    margenHeightEstadoDetalles = 0;
    fitLogo = [];
    currentUser:any;
    environment = {};
    firebaseService: any;
    myListPermisos: any;
    newListPermisos: any;
    usuarioListPermisos: any;
    constructor(){
        this.myListPermisos={};
        this.usuarioListPermisos={};
        this.newListPermisos=[
            {key:this.permisoInMenu,val:'Accede a Menu',isChecked:false},
            {key:this.permisoInClientes,val:'Accede a Clientes',isChecked:false},
            {key:this.permisoInMesas,val:'Accede a Mesas',isChecked:false},
            {key:this.permisoInAreas,val:'Accede a Areas',isChecked:false},
            {key:this.permisoInRestaurante,val:'Accede a Restaurante',isChecked:false},
            {key:this.permisoInCuentas,val:'Accede a Cuentas',isChecked:false},
            {key:this.permisoInCuentasEnviadas,val:'Accede a Cuentas Enviadas',isChecked:false},
            {key:this.permisoInReportes,val:'Accede a Reportes',isChecked:false},
            {key:this.permisoInCocina,val:'Accede a Cocina',isChecked:false},
            {key:this.permisoInBarra,val:'Accede a Barra',isChecked:false},
            {key:this.permisoInProductos,val:'Accede a Productos',isChecked:false},
            {key:this.permisoInRegistrar,val:'Accede a Registrar',isChecked:false},
            {key:this.permisoInConfiguracion,val:'Accede a Configuracion',isChecked:false},
            {key:this.permisoInLogs,val:'Accede a Logs',isChecked:false},

            //* Permisos Area
            {key:this.permisoAreaGuardar,val:'Area - Guardar',isChecked:false},
            {key:this.permisoAreaEditar,val:'Area - Editar',isChecked:false},
            {key:this.permisoAreaBorrar,val:'Area - Borrar',isChecked:false},

            //* Permisos Mesas
            {key:this.permisoMesasGuardar,val:'Mesas - Guardar',isChecked:false},
            {key:this.permisoMesasEditar,val:'Mesas - Editar',isChecked:false},
            {key:this.permisoMesasBorrar,val:'Mesas - Borrar',isChecked:false},

            //* Permisos Cuentas
            {key:this.permisoCuentasVerOrden,val:'Cuentas - Ver Orden',isChecked:false},
            {key:this.permisoCuentasEnviada,val:'Cuentas - Enviar Orden',isChecked:false},

            //* Permisos Cuentas Enviadas
            {key:this.permisoCuentasEnviadaVerOrden,val:'Cuentas Enviadas - Ver Orden',isChecked:false},
            {key:this.permisoCuentasEnviadaVerParcial,val:'Cuentas Enviadas - Enviar Orden',isChecked:false},

            //* Permisos Registro
            {key:this.permisoRegistrarGuardar,val:'Registrar - Guardar',isChecked:false},
            {key:this.permisoRegistrarEditar,val:'Registrar - Editar',isChecked:false},

            //* Permisos Configuraciones
            {key:this.permisosConfigClientes,val:'Configuracion - Enviar Cliente',isChecked:false},
            {key:this.permisosConfigBancos,val:'Configuracion - Enviar Banco',isChecked:false},
            {key:this.permisosConfigPropina,val:'Configuracion - Enviar Propina',isChecked:false},
            {key:this.permisosConfigAgrupador,val:'Configuracion - Enviar Agrupador',isChecked:false},
            {key:this.permisosConfigPermisos,val:'Configuracion - Cambiar Permisos',isChecked:false},

            //* Permisos Restaurante
            {key:this.permisoRestAddOrden,val:'Restaurante - Agregar Orden',isChecked:false},
            {key:this.permisoRestAddComensal,val:'Restaurante - Agragar Comensal',isChecked:false},
            {key:this.permisoRestAddPropina,val:'Restaurante - Agregar propina',isChecked:false},
            {key:this.permisoRestCambiarMesas,val:'Restaurante - Cambiar Mesa(s)',isChecked:false},
            {key:this.permisoRestVerOrden,val:'Restaurante - Ver Orden',isChecked:false},
            {key:this.permisoRestCobrarOrden,val:'Restaurante - Cobrar Orden',isChecked:false},
            {key:this.permisoRestCerrarOrden,val:'Restaurante - Cerrar Orden',isChecked:false},
            {key:this.permisoRestBorrarOrden,val:'Restaurante - Borrar Orden',isChecked:false},
            {key:this.permisoRestModificarOrden,val:'Restaurante - Modificar Orden',isChecked:false},


            //* Permisos Productos
            {key:this.permisoProdVerProductosCocina,val:'Productos - Ver Productos Cocina',isChecked:false},
            {key:this.permisoProdVerProductosBarra,val:'Productos - Ver Productos Barra',isChecked:false},
            {key:this.permisoProdAddProductos,val:'Productos - Agregar Productos',isChecked:false},
            {key:this.permisoProdDeleteProductos,val:'Productos - Borrar Productos',isChecked:false},//preguntar que mas y si este va
        ];

    }
    /**
     * Funcion retorno api consulta datos
     *
     * Config mantiene la variable de el ambiente de desarrollo para saber si consulta test o production.
     * @return {string} URL completa de consulta.
     */
    Api(data: string): string{
        if(this.ambienteDesarrollo === "test"){
            return  this.baseApiTest + data;
        }
        if(this.ambienteDesarrollo === "production"){
            return  this.baseApiProd + data;
        }
    }

    CheckPermiso(route){
        console.log("Usuario: ",this.currentUser);
        console.log('Quieres viajar a ',route);
        const myListByUser= this.currentUser.permiso ? this.currentUser.permiso : this.GetList();
        // const myListByUser= this.currentUser.permiso ? this.currentUser.permiso : false;
        console.log('myListByUser',myListByUser);
        // if(myListByUser === false){
        //     return false;
        // }
        const permiso = myListByUser.find(item =>item.key ==route)
        if (permiso?.isChecked) {
            return true;
        }else{
            //alert("Su perfil no cuenta con el acceso a esta accion");
            return false;
        }
    }
    GetList() {
        let myTipe='';

        if (this.currentUser.tipoUsuario=='Administrador') {
          myTipe='admin';
        }
        else if (this.currentUser.tipoUsuario=='Mesero') {
          myTipe='mesero';
        }
        else if (this.currentUser.tipoUsuario=='Cajero') {
          myTipe='cajero';
        }
        return this.myListPermisos[myTipe];
    }

    /**
     * called in app.component.ts
     */
    LoadConfig() {


        if (this.selectProyect == Proyecto.test) {
            this.appName = "HOTEL TEST <-";
            this.hotelName = "Hotel Test";
            this.appUrl = "localhost:8100/";
            // this.logo = "assets/img/logo_grand_prix_colorida.png";
            this.logo = "assets/img/logo_restaurante_200.jpg";
            this.logoWhite = "assets/img/logo_grand_prix.png";
            this.token = "Gr4ndPr1x$";
            this.emailUrl = "https://correo.konsultor.com.mx/prueba/sendEmail.php";
            this.IVA = 0.16;
            this.hospedaje = 0.035;
            this.tablaSuite = false;
            this.margenHeight = 200;
            this.margenHeightEstadoDetalles = 120;
            this.fitLogo = [210, 200];
        } else if (this.selectProyect == Proyecto.azulArena) {
            this.appName = "HOTEL AZUL ARENA";
            this.hotelName = "Hotel Azul Arena";
            this.appUrl = "http://www.azularena.mx/reservaciones/sistema/admin/";
            this.logo = "assets/img/logo_azul_arena_colorida.png";
            this.logoWhite = "assets/img/logo_azul_arena.png";
            this.token = "4zuLaR3na$";
            this.emailUrl = "https://correo.konsultor.com.mx/azul_arena/sendEmail.php";
            this.IVA = 0.16;
            this.hospedaje = 0.03;
            this.tablaSuite = false;
            this.margenHeight = 210;
            this.margenHeightEstadoDetalles = 140;
            this.fitLogo = [100, 100];
        }
        else if (this.selectProyect == Proyecto.grandPrix) {
            this.appName = "HOTEL GRAND PRIX";
            this.hotelName = "Hotel Grand Prix";
            // this.appUrl = "http://grandprix.konsultor.mx/";// https://grandprix.konsultor.mx/#/
            this.appUrl = "http://grandprixh.konsultor.com.mx/"; // https://grandprixh.konsultor.mx/#/
            //this.logo = "assets/img/logo_grand_prix_colorida.png";
            this.logo = "assets/img/logo_restaurante_200.jpg";
            this.logoWhite = "assets/img/logo_grand_prix.png";
            this.token = "Gr4ndPr1x$";
            // TODO: cambiar correo para grand prix con su propia configuracion en su servidor grandprix.konsulto.mx
            this.emailUrl = "https://correo.konsultor.com.mx/grandprix/sendEmail.php";
            this.IVA = 0.16;
            this.hospedaje = 0.035;
            this.tablaSuite = true;
            this.margenHeight = 200;
            this.margenHeightEstadoDetalles = 120;
            this.fitLogo = [210, 200];
        }

        if(this.proyectoSis == Proyecto.grandPrix) {
            this.logo = "assets/img/logo_restaurante_200.jpg";
        }
        else if(this.proyectoSis == Proyecto.grandCafe){
            this.logo = "assets/img/LogoTGC.jpg";
            this.obtenerDatosSis = 4;
            this.origen = 7;
        }
    }



}


