import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { AngularFireModule } from '@angular/fire';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from '../environments/environment';

import { CryptoClass,UserDataService } from 'src/services/user-data.service';

import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from "@fortawesome/free-regular-svg-icons";

import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MenuPedidoModule } from './menu-pedido/menu-pedido.module';
import { MenuModule } from './menu/menu.module';
import { CuentasModule } from './cuentas/cuentas.module';
import { LogsModule } from './logs/logs.module';

import { ConfiguracionModule } from './configuracion/configuracion.module';
import { CuentasenviadasModule } from './cuentasenviadas/cuentasenviadas.module';
import { EditarUsuarioModule } from './editar-usuario/editar-usuario.module';
import { ModalAgregarAnotacionesModule } from './modal-agregar-anotaciones/modal-agregar-anotaciones.module';
import { ClientesModule } from './clientes/clientes.module';


import { AreasModule } from './areas/areas.module';
import { EditarDatosPagadosModule } from './editar-datos-pagados/editar-datos-pagados.module';
import { MesasModule } from './mesas/mesas.module';
import { PedidosModule } from './pedidos/pedidos.module';
import { RestaurantModule } from './restaurant/restaurant.module';
import { OrdenAgregarProductosModule } from './orden-agregar-productos/orden-agregar-productos.module';
import { ModalAgregarEnviosParcialesModule } from './modal-agregar-envios-parciales/modal-agregar-envios-parciales.module';


registerLocaleData(es);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule.enablePersistence(),
    AngularFireAuthGuardModule,
    AngularFireAuthModule,
    // NgxQRCodeModule,
    FontAwesomeModule,
    ModalAgregarAnotacionesModule,
    ModalAgregarEnviosParcialesModule,
    MenuPedidoModule,
    MenuModule,
    CuentasModule,
    LogsModule,
    CuentasenviadasModule,
    EditarUsuarioModule,
    ClientesModule,
    AreasModule,
    EditarDatosPagadosModule,
    MesasModule,
    PedidosModule,
    RestaurantModule,
    OrdenAgregarProductosModule,
    CommonModule,
    ConfiguracionModule,
  ],
  providers: [

    AngularFirestore,
    CryptoClass,
    UserDataService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIconPacks(fas, far);
  }
}

