import { ModalConfirmDeleteComponent } from './../modal-confirm-delete/modal-confirm-delete.component';
import { DatosPago, Mesa, MovementUsers } from './../../services/firebase-data.service';
import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden , Pago} from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalController} from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import axios, { AxiosRequestConfig } from 'axios';
import { ConfigService } from 'src/services/config.service';
import { formatNumber } from '@angular/common';
import { isEmpty } from 'rxjs/operators';
import { AuthService } from 'src/services/auth.service';
import moment from 'moment';

@Component({
  selector: 'app-modal-cobrar-cuenta',
  templateUrl: './modal-cobrar-cuenta.component.html',
  styleUrls: ['./modal-cobrar-cuenta.component.scss'],
})
export class ModalCobrarCuentaComponent implements OnInit {

  @Input("orden") orden;

  pedidos:any;
  datacobro: any;

  token = "Gr4ndPr1x$";
  comensales: any;

  totalCuenta: any;

  prods:any;

  tipoPago: any;

  descripcion: any;

  cantidadPagada: any;

  cambio:any;

  pagoCompleto: boolean;

  totalAPagar:any;

  listaCobros: any [];

  selectedComensal:any;

  pagado:boolean;


  totalRecibido:any;
  cambioTotal:any;
  protocol: any;

  userDatos: any;
  today: string;
  isOnline: boolean;
  isMessageVisible = false;

  constructor(
    public config: ConfigService,
    private router: Router,
    public alertService: AlertsService,
    private authService: AuthService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController
  ) {

    this.cambio = 0;
    this.protocol = config.protocol;
    this.today = moment().format('YYYY-MM-DD');

   }
  //  async ngAfterContentChecked() {
  //    }
  async ngOnInit() {
    this.config.createOnline$().subscribe(async isOnline => {
      this.isOnline = isOnline;
      if (this.isOnline == false) {
        if (this.isMessageVisible == false) {
          this.isMessageVisible = true;
          console.log('Sin internet');
        }
      } else {
        // this.alertService.closeLoadingInternet('I');
      }
    });

    this.comensales =this.orden.comensales;
    this.userDatos = await this.authService.GetCurrentUser();

    await this.GetFormasPago();
    this.selectedComensal = 0;
    this.listaCobros = [];
    this.pagoCompleto = false;



    this.tipoPago = 0;

    this.cantidadPagada = null;
    this.descripcion = "";
    this.totalCuenta = 0;

    this.cambio = 0;
    this.pagado=true;



    this.comensales.forEach(element => {
      element.total = 0;
      element.productos = [];

    });

    var subs = await this.firebaseService.getPedidos(this.orden.id).subscribe(sub =>{
      var counter =0;
      this.totalCuenta = 0;
      this.pedidos = sub.map( (e)=>{
        var data : any;
        data = e.payload.doc.data();

        var pedido_id = e.payload.doc.id;

        var productosOrden = [];

        console.log("DATA",data);

        this.totalCuenta += data.total_pedido;



         this.firebaseService.getOrdenItems(this.orden.id,pedido_id).subscribe(data => {
          var product = data.map( e =>{

           var tempData: any;
           tempData = e.payload.doc.data();


           return {
            id:e.payload.doc.id,
            id_producto: tempData.id_producto,
            cantidad: tempData.cantidad,
            nombre_producto: tempData.nombre_producto,
            imagen_url: tempData.imagen_url,
            precio: tempData.precio,
            comensal: tempData.comensal,
            estado : tempData.estado,
            facturable:tempData.facturable,
            anotacion:tempData.anotacion
           } as ItemOrden;

          });
          productosOrden.push(product);


      });

      counter ++;


      return{
        estado : data.estado,
        productos : productosOrden
      }


      });

      console.log("ORDEN",this.orden);
      console.log("comensales",this.comensales);
      console.log("PEDIDOS",this.pedidos);

      setTimeout(()=>{

        this.comensales.forEach(element => {
          element.productos = [];
          element.total = 0;
        });
        this.pedidos.forEach(pedido => {


          pedido.productos[0].forEach(prod => {
            var tempData = prod;
            console.log("producto-",prod);

            this.comensales.forEach(element => {
              var total = (tempData.cantidad * tempData.precio);

              if(element.nombre == tempData.comensal){
                //console.log("Item", tempData);
                element.total += total;
                element.productos.push(tempData);

              }
            });

          });
          counter ++;
        });

        subs.unsubscribe();

        console.log("COMENSALES",this.comensales);
        console.log("cuenta--",this.totalCuenta,this.orden.propina);

        this.totalAPagar = this.orden.totalOrden+this.orden.propina;//this.totalCuenta + this.orden.propina;
      },500);



    });


  }

  async GetFormasPago(search: string = "") {
    try {
        const config: AxiosRequestConfig = {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'multipart/form-data',
            },
        }
        const url =this.config.Api('get-formas-pago?token='+this.config.tokenApi+`&busqueda=${search}`);
        //`${this.protocol}://erp.konsultor.mx/get-formas-pago?token=${this.token}&busqueda=${search}`;
        const response = await axios.get(url, config);
        this.datacobro = response.data;
        console.log("COBROS",this.datacobro);


        return response.data;
    } catch (error) {
        console.log(error);
    }
}
FormatNumber(number){
  if(typeof number === 'undefined')
    {return '00.00'}
    // let parts = number.toString().split(".")
    // console.log(parts);
    // var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    // return num;
    return number.toFixed(2);
}

  async deleteModal(cobro,index){
    console.log("BORRAR",cobro,index);
    this.totalRecibido -= cobro.cantidad_pagada
    this.totalAPagar += cobro.cantidad_pagada;
    this.listaCobros.splice(index,1);
    this.CheckPagado();
    /*const modal = await this.modalControl.create({
      component: ModalConfirmDeleteComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"my-custom-modal-css",
      componentProps:{
        mensajeConfirmacion:"Desea borrar un pago por $ "+ cobro.cantidad_pagada.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }) +" MXN"+ "?",
        titulo: "Borrar Pago",
        tipo: "Cobro",
        data: { index:index, cobros:this.listaCobros },
      }

    });*/
    //this.pagado= true;
    //console.log("DATA -borrar",this.pagado,modal.componentProps.data.cobros)
    //console.log(modal.present());
    //return await modal.present();
  }
  async CheckPagado(){
    this.pagado= await this.permitirCobro();
    console.log("EJECUTAR",this.pagado);

  }



  async cobrarCuenta(){
      let estatusAnterior = this.orden.status;
      if(estatusAnterior !== this.config.StatusEnviadaPar && estatusAnterior !== this.config.StatusCerrada && estatusAnterior !== this.config.StatusEnviada && estatusAnterior !== this.config.StatusRechazada){
        this.orden.status = this.config.StatusPagada;
      }

      for (let i = 0; i < this.orden.comensales.length; i++) {
        const comensal = this.orden.comensales[i];
        
        for (let a = 0; a < comensal.productos.length; a++) {
          const producto = comensal.productos[a];
          
          if(producto.anotacion === undefined || producto.anotacion === null){
            producto.anotacion = "";
          }
        }
      }

      this.isOnline = await this.AwaitInternet();
      if (this.isOnline) {
        await this.firebaseService.updateOrden(this.orden);
      }

      this.listaCobros.forEach(async element => {
        const response = await this.firebaseService.guardarDatosPago(this.orden,element);
        const id_objeto=(await response.get()).id
        const timeStamp_cer = moment().format('YYYY-MM-DD HH:mm:ss');

        let ordeData2: MovementUsers;
        ordeData2 = {
          tipo: "ordenes-pedidos",
          id_objeto: id_objeto,
          id_usuario: this.userDatos.uid,
          accion: "crear",
          movimiento: "Creando cobro de cuenta",
          original:null,
          nuevo: element,
          fecha: timeStamp_cer

        }

        this.isOnline = await this.AwaitInternet();
        if (this.isOnline) {
          await this.firebaseService.AddMovementUsers(ordeData2);
        }



      });
      this.closeModal();
  }

  async permitirCobro(): Promise<boolean>{
    if(this.totalAPagar <=0){
      return false;
    }
    return true;
  }

  AwaitInternet(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      var interval = setInterval(() => {
        if (this.isOnline) {
          resolve(true);
          clearInterval(interval);
        }
      }, 600);
    });
  }

  async agregarCobro(){

    var datosPago : DatosPago;

    var comensal = this.comensales[this.selectedComensal];
    console.log(parseFloat(this.cantidadPagada),this.tipoPago);
    if(isNaN(parseFloat(this.cantidadPagada))){
      this.alertService.AlertConfirm('Datos de cobro incompletos','Favor de verificar los datos.')
      return;
    }
    var timeStamp = moment().format('YYYY-MM-DD HH:mm:ss');
    datosPago = {
      forma_pago : this.datacobro[this.tipoPago].id,
      nombre_pago: this.datacobro[this.tipoPago].Nombre,
      cantidad_pagada: parseFloat(this.cantidadPagada),
      cambio: this.cambio,
      comensal: comensal.nombre,
      descripcion:this.descripcion,
      edit_by: this.userDatos.uid,
      edit_date:timeStamp,
      created_by:this.userDatos.uid,
      created_date:timeStamp
    } as DatosPago;
    console.log(datosPago);


    this.listaCobros.push(datosPago);
    this.totalAPagar = this.FormatNumber(parseFloat(this.totalAPagar)) - this.FormatNumber(parseFloat(this.cantidadPagada));

    if(this.totalAPagar < 0){
      this.totalAPagar = 0;
    }
    console.log(this.totalAPagar);

    this.pagado= await this.permitirCobro();
      var cambio = 0;
      var totRecibido = 0;
      this.listaCobros.forEach(element => {
        cambio += element.cambio;
        totRecibido += parseFloat(element.cantidad_pagada);
      });

      this.totalRecibido = totRecibido;
      this.cambioTotal = cambio;


    this.cantidadPagada = null;

    console.log(this.listaCobros);
  }

  changeSelectedComensal(event){
    const value = event.detail.value;
    this.selectedComensal = value;
  }

  async changeFormaPago(event){
    const value = event.detail.value;

    this.tipoPago = value;
  }
  async changeCantidadPagada(event){
    const value = event.detail.value;
    this.cantidadPagada = value;
    this.cambio = this.cantidadPagada - this.totalAPagar;

    if(this.cambio < 0){
      this.cambio = 0;
    }
  }
  async changeDesc(event){
    const value = event.detail.value;
    this.descripcion=value;
  }
  closeModal(){
    this.modalControl.dismiss();
  }
  Cobrar(){
    let valorAdos = this.FormatNumber(this.totalAPagar)
    return Number(valorAdos) <= 0
  }

  async aceptarCuenta(){
    this.orden.status = 'Aceptada';
    console.log("CUENTA UPDATE", this.orden);
    await this.firebaseService.updateOrden(this.orden);
    this.closeModal();
  }



   //this.modalControl.dismiss();

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }


}
