import { AuthService } from 'src/services/auth.service';
import { ModalEditAreaComponent } from './../modal-edit-area/modal-edit-area.component';
import { AlertController, ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';
import { Area, FirebaseDataService, Usuario } from 'src/services/firebase-data.service';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import { ModalConfirmDeleteComponent } from '../modal-confirm-delete/modal-confirm-delete.component';
import * as moment from 'moment';
import { isEmpty } from 'rxjs/operators';
import { AlertsService } from 'src/services/alerts.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/services/config.service';

@Component({
  selector: 'app-areas',
  templateUrl: './areas.component.html',
  styleUrls: ['./areas.component.scss'],
})
export class AreasComponent implements OnInit {

  areas: Area[];


  newArea: Area;

  areaForm = this.builder.group({
      nombre: ['',Validators.required],
      descripcion:['', Validators.required]
  });

  userUID:string;
  permisoAreaBorrar: boolean;
  permisoAreaEditar: boolean;
  permisoAreaGuardar: boolean;

  constructor(private firebaseService:FirebaseDataService,
    private builder:FormBuilder,

    private authService: AuthService,
    private router: Router,
    public config: ConfigService,
    public alert : AlertsService,
    private modalControler:ModalController,
    private auth:AuthService) { }

    async CheckIsLoggedIn() {
      let userDatos = await this.authService.GetCurrentUser();
      this.config.currentUser=await this.GetIdErp_uid(userDatos.uid);
      await this.CheckPermiso();
      if(userDatos==false){
        this.router.navigate(['/login']);
      }
    }
    GetIdErp_uid(uid): Promise<Usuario> {
      return new Promise((resolve, reject) => {
        this.firebaseService.GetUser_ByUid(uid).subscribe((user) => {
          if (user) {
            resolve(user[0]);
          }
          else {
            reject(false);
          }
        })
      });
    }


 async ngOnInit() {

  this.CheckIsLoggedIn();


    await this.firebaseService.getAreas().subscribe(data => {

      console.log(data);
      this.areas = data.filter(e=>{let eTemp:any; eTemp= e.payload.doc.data(); return eTemp.activa === null || typeof eTemp.activa === 'undefined' ? true: eTemp.activa }).map(e => {
        var tempArea: any;
        tempArea = e.payload.doc.data();
        return {
          id: e.payload.doc.id,
          nombre:tempArea.nombre,
          descripcion:tempArea.descripcion,
          created_by: tempArea.created_by,
          edit_date: tempArea.edit_date,
          edited_by: tempArea.edited_by,
          created_date: tempArea.edit_date,
          activa:tempArea.activa
        } as Area;
      });
      console.log("AREAS",this.areas);
    });

    var data = await this.auth.GetCurrentUser();

    this.userUID = data.uid;

  }
  CheckPermiso(){
    this.permisoAreaBorrar=this.config.CheckPermiso(this.config.permisoAreaBorrar);
    this.permisoAreaEditar=this.config.CheckPermiso(this.config.permisoAreaEditar);
    this.permisoAreaGuardar=this.config.CheckPermiso(this.config.permisoAreaGuardar);
  }

  async deleteModal(area){
    // if (!this.config.CheckPermiso(this.config.permisoAreaBorrar)) {
    //   return;
    // }
    const modal = await this.modalControler.create({
      component: ModalConfirmDeleteComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"my-custom-modal-css",
      componentProps:{
        mensajeConfirmacion:"¿Desea eliminar el Area "+ "'"+ area.nombre +"' "+ "?",
        titulo: "Eliminar Area",
        tipo: "Area",
        data: { area:area },
      }
    });
    return await modal.present();
  }

  async editModal(area){
    console.log('edit area',area);
    // if (!this.config.CheckPermiso(this.config.permisoAreaEditar)) {
    //   return;
    // }
    const modal = await this.modalControler.create({
      component: ModalEditAreaComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"editMesaModal",
      componentProps:{
        titulo: "Editar Area",
        data: { area:area },
      }
    });
    return await modal.present();
  }

  public async createArea(){
    // if (!this.config.CheckPermiso(this.config.permisoAreaGuardar)) {
    //   return;
    // }
    console.log("FORM",this.areaForm);
    //var date = new Date();
    if(this.areaForm.get('nombre').value==''){
      this.alert.AlertConfirm("Area","Favor de llenar los datos necesarios.")
      return
    }
    const date = moment().format('YYYY-MM-DD HH:mm:ss')
    this.newArea = {
      nombre:this.areaForm.get('nombre').value,
      descripcion: this.areaForm.get('descripcion').value,
      created_by: this.userUID,
      created_date: date,
      edit_date: date,
      edited_by:this.userUID,
      activa:true
    }

    console.log("DATA TO SAVE",this.newArea);

    await this.firebaseService.createArea(this.newArea);
    this.alert.AlertConfirm("Area","Area creada con exito")
    this.areaForm = this.builder.group({
      nombre: ['',Validators.required],
      descripcion:['', Validators.required]
    });

  }

}
