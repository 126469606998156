import { ModalAgregarComensalComponent } from './../modal-agregar-comensal/modal-agregar-comensal.component';
import { Pedido } from './../../services/firebase-data.service';
import { ModalEditComandaComponent } from './../modal-edit-comanda/modal-edit-comanda.component';
import { ModalAceptarCuentaComponent } from './../modal-aceptar-cuenta/modal-aceptar-cuenta.component';
import { promise } from 'protractor';
import { Component, OnInit, Input } from '@angular/core';
import { FirebaseDataService, ItemOrden, Orden } from 'src/services/firebase-data.service';
import { AlertsService } from 'src/services/alerts.service';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { ModalController} from '@ionic/angular';

@Component({
  selector: 'app-comensales-orden-modal',
  templateUrl: './comensales-orden-modal.component.html',
  styleUrls: ['./comensales-orden-modal.component.scss'],
})
export class ComensalesOrdenModalComponent implements OnInit {

  @Input("orden") orden;
  pedidos:any;

  comensales: any;

  totalCuenta: any;


  constructor(

    private router: Router,
    public alertService: AlertsService,
    private firebaseService:FirebaseDataService,
    private loadingController: LoadingController,
    private modalControl: ModalController
  ) {

  }

 async ngOnInit() {

  this.totalCuenta = 0;

    this.comensales =this.orden.comensales;

    this.comensales.forEach(element => {
      element.total = 0;
      element.productos = [];

    });

    var subs = await this.firebaseService.getPedidos(this.orden.id).subscribe(sub =>{
      var counter =0;
      this.totalCuenta = 0;
      this.pedidos = sub.map( (e)=>{
        var data : Pedido;
        data = e.payload.doc.data() as Pedido;

        var pedido_id = e.payload.doc.id;

        var productosOrden = [];

        console.log("DATA",data);

        this.totalCuenta += data.total_pedido;



         this.firebaseService.getOrdenItems(this.orden.id,pedido_id).subscribe(data => {
          var product = data.map( e =>{

           var tempData: any;
           tempData = e.payload.doc.data();


           return {
            id:e.payload.doc.id,
            id_producto: tempData.id_producto,
            cantidad: tempData.cantidad,
            nombre_producto: tempData.nombre_producto,
            imagen_url: tempData.imagen_url,
            precio: tempData.precio,
            comensal: tempData.comensal,
            estado : tempData.estado,
            facturable:tempData.facturable,
            anotacion: tempData.anotacion
           } as ItemOrden;

          });
          productosOrden.push(product);


      });

      counter ++;


      return{
        estado : data.estado,
        productos : productosOrden,
        id : pedido_id,
        total_pedido: data.total_pedido,
        comensal_total: data.comensal_total,
        created_by: data.created_by,
        edit_date: data.edit_date,
      }


      });
      console.log("ORDEN MESAS: ", this.orden.mesas)
      console.log("ORDEN",this.orden);
      console.log("PEDIDOS",this.pedidos);

      setTimeout(()=>{

        this.comensales.forEach(element => {
          element.productos = [];
          element.total = 0;
        });
        this.pedidos.forEach(pedido => {

          pedido.productos[0].forEach(prod => {
            var tempData = prod;

            this.comensales.forEach(element => {
              var total = (tempData.cantidad * tempData.precio);

              if(element.nombre == tempData.comensal){
                //console.log("Item", tempData);
                element.total += total;
                element.productos.push(tempData);

              }
            });

          });
          counter ++;
        });

        subs.unsubscribe();

        console.log("COMENSALES",this.comensales);
      },500);



    });


  }

  FormatNumber(number){
    let parts = number.toString().split(".")
    var num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1].substring(0,2) : ".00");
    return num
  }

  async agregarComensal(){
    const modal = await this.modalControl.create({
      component: ModalAgregarComensalComponent,
      showBackdrop: true,
      backdropDismiss:true,
      animated: true,
      cssClass:"editMesaModal",
      componentProps:{
        titulo: "Agregar Comensal",
        orden:this.orden ,
      }
    });
    return await modal.present();
  }


   async dismissModal(){

    this.modalControl.dismiss();
   }

   async PresentLoading(mensaje, duracion) {
     const loading = await this.loadingController.create({
       message: mensaje,
       duration: duracion,
       spinner: 'bubbles'
     });
     await loading.present();
     return loading;
   }

   DismissLoading() {
    this.loadingController.dismiss();
  }

}
